import React from 'react'
import './lost_dog_2022.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvData = [
    {
      "A": "Pos",
      "B": "Plate Number",
      "C": "Category",
      "D": "Surname",
      "E": "First Name",
      "F": "Country",
      "G": "Team",
      "H": "EWS Number",
      "I": "Time",
      "J": "Gap",
      "K": "Penalties",
      "L": "Stage 1 - Trans Drop In!  Time",
      "M": "Stage 1 - Trans Drop In!  Pos",
      "N": "Stage 2 - Tin Mine Time",
      "O": "Stage 2 - Tin Mine Pos",
      "P": "Stage 3 - Granola Bowl Time",
      "Q": "Stage 3 - Granola Bowl Pos",
      "R": "Stage 4 - Little Moab  Time",
      "S": "Stage 4 - Little Moab  Pos",
      "T": "Stage 5 - La Espina Ledge Time",
      "U": "Stage 5 - La Espina Ledge Pos",
      "V": "Stage 6 - La Espina HID-DROPS [Void] Time",
      "W": "Stage 6 - La Espina HID-DROPS [Void] Pos"
    },
    {
      "A": "1",
      "B": "54",
      "C": "Men 50+",
      "D": "Gonzalez",
      "E": "Octavio",
      "F": "",
      "G": "Embrace the Suck",
      "H": "",
      "I": "00:10:44.87",
      "J": "",
      "K": "",
      "L": "3:00.63",
      "M": "1",
      "N": "1:03.40",
      "O": "1",
      "P": "1:17.73",
      "Q": "1",
      "R": "3:59.03",
      "S": "1",
      "T": "1:24.08",
      "U": "1",
      "V": "",
      "W": ""
    },
    {
      "A": "1",
      "B": "52",
      "C": "Men Beginner Open",
      "D": "Guerra",
      "E": "Max",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:10:17.47",
      "J": "",
      "K": "",
      "L": "2:41.93",
      "M": "1",
      "N": "0:59.52",
      "O": "1",
      "P": "1:15.18",
      "Q": "1",
      "R": "3:59.22",
      "S": "2",
      "T": "1:21.62",
      "U": "1",
      "V": "",
      "W": ""
    },
    {
      "A": "2",
      "B": "80",
      "C": "Men Beginner Open",
      "D": "Ivey",
      "E": "Wyatt",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:10:32.39",
      "J": "+00:00:14.92",
      "K": "",
      "L": "2:52.65",
      "M": "3",
      "N": "1:03.38",
      "O": "2",
      "P": "1:21.64",
      "Q": "2",
      "R": "3:47.75",
      "S": "1",
      "T": "1:26.97",
      "U": "2",
      "V": "",
      "W": ""
    },
    {
      "A": "3",
      "B": "60",
      "C": "Men Beginner Open",
      "D": "O",
      "E": "Rene De la",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:11:38.14",
      "J": "+00:01:20.67",
      "K": "",
      "L": "3:06.00",
      "M": "4",
      "N": "1:09.95",
      "O": "3",
      "P": "1:34.07",
      "Q": "4",
      "R": "4:06.75",
      "S": "4",
      "T": "1:41.37",
      "U": "3",
      "V": "",
      "W": ""
    },
    {
      "A": "4",
      "B": "82",
      "C": "Men Beginner Open",
      "D": "Corral",
      "E": "Jesus",
      "F": "",
      "G": "Green Team / Smoothie King",
      "H": "",
      "I": "00:12:00.03",
      "J": "+00:01:42.56",
      "K": "",
      "L": "2:45.53",
      "M": "2",
      "N": "1:27.91",
      "O": "5",
      "P": "1:31.97",
      "Q": "3",
      "R": "4:01.90",
      "S": "3",
      "T": "2:12.72",
      "U": "5",
      "V": "",
      "W": ""
    },
    {
      "A": "5",
      "B": "55",
      "C": "Men Beginner Open",
      "D": "Reyes",
      "E": "Izzy",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:13:02.66",
      "J": "+00:02:45.19",
      "K": "",
      "L": "3:13.52",
      "M": "5",
      "N": "1:25.85",
      "O": "4",
      "P": "1:49.59",
      "Q": "5",
      "R": "4:38.25",
      "S": "5",
      "T": "1:55.45",
      "U": "4",
      "V": "",
      "W": ""
    },
    {
      "A": "6",
      "B": "56",
      "C": "Men Beginner Open",
      "D": "Garcia",
      "E": "Lalo",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:14:48.72",
      "J": "+00:04:31.25",
      "K": "",
      "L": "3:39.13",
      "M": "6",
      "N": "1:54.97",
      "O": "6",
      "P": "1:58.66",
      "Q": "6",
      "R": "4:49.55",
      "S": "6",
      "T": "2:26.41",
      "U": "6",
      "V": "",
      "W": ""
    },
    {
      "A": "1",
      "B": "77",
      "C": "Men Expert Open",
      "D": "Sotomayor",
      "E": "Eric",
      "F": "",
      "G": "The Shock Lab / NorthCrew",
      "H": "",
      "I": "00:08:42.32",
      "J": "",
      "K": "",
      "L": "2:32.70",
      "M": "3",
      "N": "0:51.60",
      "O": "3",
      "P": "1:06.55",
      "Q": "3",
      "R": "3:03.72",
      "S": "1",
      "T": "1:07.75",
      "U": "1",
      "V": "",
      "W": ""
    },
    {
      "A": "2",
      "B": "61",
      "C": "Men Expert Open",
      "D": "Gutierrez",
      "E": "Alexander",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:08:49.41",
      "J": "+00:00:07.09",
      "K": "",
      "L": "2:18.88",
      "M": "1",
      "N": "0:49.32",
      "O": "2",
      "P": "1:06.41",
      "Q": "2",
      "R": "3:22.40",
      "S": "4",
      "T": "1:12.40",
      "U": "3",
      "V": "",
      "W": ""
    },
    {
      "A": "3",
      "B": "73",
      "C": "Men Expert Open",
      "D": "Cutburth",
      "E": "Luke",
      "F": "",
      "G": "Rad Dog Bikes / LDCB",
      "H": "",
      "I": "00:09:07.11",
      "J": "+00:00:24.79",
      "K": "",
      "L": "2:35.58",
      "M": "4",
      "N": "0:53.40",
      "O": "4",
      "P": "1:07.56",
      "Q": "4",
      "R": "3:12.56",
      "S": "2",
      "T": "1:18.01",
      "U": "7",
      "V": "",
      "W": ""
    },
    {
      "A": "4",
      "B": "65",
      "C": "Men Expert Open",
      "D": "Lujan",
      "E": "Joel",
      "F": "",
      "G": "NorthCrew / The Shock Lab",
      "H": "575385",
      "I": "00:09:10.61",
      "J": "+00:00:28.29",
      "K": "",
      "L": "2:36.75",
      "M": "5",
      "N": "0:53.84",
      "O": "5",
      "P": "1:09.60",
      "Q": "6",
      "R": "3:19.76",
      "S": "3",
      "T": "1:10.66",
      "U": "2",
      "V": "",
      "W": ""
    },
    {
      "A": "5",
      "B": "24",
      "C": "Men Expert Open",
      "D": "Shaffer",
      "E": "Wayne",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:09:26.64",
      "J": "+00:00:44.32",
      "K": "",
      "L": "2:39.19",
      "M": "6",
      "N": "0:57.81",
      "O": "8",
      "P": "1:10.50",
      "Q": "7",
      "R": "3:25.87",
      "S": "5",
      "T": "1:13.27",
      "U": "4",
      "V": "",
      "W": ""
    },
    {
      "A": "6",
      "B": "68",
      "C": "Men Expert Open",
      "D": "",
      "E": "Clint Nelms",
      "F": "",
      "G": "Old Fools ",
      "H": "",
      "I": "00:09:33.27",
      "J": "+00:00:50.95",
      "K": "",
      "L": "2:46.19",
      "M": "9",
      "N": "0:54.47",
      "O": "6",
      "P": "1:08.30",
      "Q": "5",
      "R": "3:26.48",
      "S": "6",
      "T": "1:17.83",
      "U": "6",
      "V": "",
      "W": ""
    },
    {
      "A": "7",
      "B": "63",
      "C": "Men Expert Open",
      "D": "Burdick",
      "E": "Hunt",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:10:04.70",
      "J": "+00:01:22.38",
      "K": "",
      "L": "2:46.27",
      "M": "10",
      "N": "0:56.98",
      "O": "7",
      "P": "1:20.37",
      "Q": "10",
      "R": "3:44.75",
      "S": "8",
      "T": "1:16.33",
      "U": "5",
      "V": "",
      "W": ""
    },
    {
      "A": "8",
      "B": "89",
      "C": "Men Expert Open",
      "D": "Abruzzi",
      "E": "Brian",
      "F": "",
      "G": "SNMTB",
      "H": "",
      "I": "00:10:08.08",
      "J": "+00:01:25.76",
      "K": "",
      "L": "2:39.28",
      "M": "7",
      "N": "1:02.01",
      "O": "9",
      "P": "1:16.23",
      "Q": "8",
      "R": "3:45.25",
      "S": "9",
      "T": "1:25.31",
      "U": "9",
      "V": "",
      "W": ""
    },
    {
      "A": "9",
      "B": "62",
      "C": "Men Expert Open",
      "D": "",
      "E": "Ricardo Granados",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:10:30.96",
      "J": "+00:01:48.64",
      "K": "",
      "L": "2:45.88",
      "M": "8",
      "N": "1:25.35",
      "O": "10",
      "P": "1:18.46",
      "Q": "9",
      "R": "3:38.08",
      "S": "7",
      "T": "1:23.19",
      "U": "8",
      "V": "",
      "W": ""
    },
    {
      "A": "DNF",
      "B": "64",
      "C": "Men Expert Open",
      "D": "Ramirez",
      "E": "Edgar",
      "F": "",
      "G": "The Shock Lab",
      "H": "",
      "I": "",
      "J": "",
      "K": "",
      "L": "2:26.80",
      "M": "2",
      "N": "0:47.67",
      "O": "1",
      "P": "1:04.48",
      "Q": "1",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": ""
    },
    {
      "A": "1",
      "B": "72",
      "C": "Men Intermediate Open",
      "D": "Rose",
      "E": "Michael",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:08:39.64",
      "J": "",
      "K": "",
      "L": "2:23.15",
      "M": "1",
      "N": "0:49.13",
      "O": "1",
      "P": "1:07.90",
      "Q": "1",
      "R": "3:03.92",
      "S": "1",
      "T": "1:15.54",
      "U": "1",
      "V": "",
      "W": ""
    },
    {
      "A": "2",
      "B": "57",
      "C": "Men Intermediate Open",
      "D": "Chavez",
      "E": "Isaac",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:09:41.63",
      "J": "+00:01:01.99",
      "K": "",
      "L": "2:33.78",
      "M": "3",
      "N": "1:00.88",
      "O": "2",
      "P": "1:13.82",
      "Q": "2",
      "R": "3:24.07",
      "S": "3",
      "T": "1:29.08",
      "U": "6",
      "V": "",
      "W": ""
    },
    {
      "A": "3",
      "B": "90",
      "C": "Men Intermediate Open",
      "D": "Palafox",
      "E": "Will",
      "F": "",
      "G": "Merol Racing / Dogspeed",
      "H": "",
      "I": "00:09:50.52",
      "J": "+00:01:10.88",
      "K": "",
      "L": "2:50.98",
      "M": "7",
      "N": "1:00.89",
      "O": "3",
      "P": "1:17.05",
      "Q": "4",
      "R": "3:20.68",
      "S": "2",
      "T": "1:20.92",
      "U": "4",
      "V": "",
      "W": ""
    },
    {
      "A": "4",
      "B": "74",
      "C": "Men Intermediate Open",
      "D": "Carranza",
      "E": "Efren",
      "F": "",
      "G": "The Shock Lab / NorthCrew",
      "H": "",
      "I": "00:10:02.87",
      "J": "+00:01:23.23",
      "K": "",
      "L": "2:50.00",
      "M": "6",
      "N": "1:01.16",
      "O": "5",
      "P": "1:18.23",
      "Q": "5",
      "R": "3:32.82",
      "S": "4",
      "T": "1:20.66",
      "U": "3",
      "V": "",
      "W": ""
    },
    {
      "A": "5",
      "B": "78",
      "C": "Men Intermediate Open",
      "D": "Rose",
      "E": "Justin",
      "F": "",
      "G": "Rose Racing",
      "H": "",
      "I": "00:10:11.01",
      "J": "+00:01:31.37",
      "K": "",
      "L": "2:33.75",
      "M": "2",
      "N": "1:02.25",
      "O": "7",
      "P": "1:15.88",
      "Q": "3",
      "R": "3:59.13",
      "S": "11",
      "T": "1:20.00",
      "U": "2",
      "V": "",
      "W": ""
    },
    {
      "A": "6",
      "B": "88",
      "C": "Men Intermediate Open",
      "D": "Oineza",
      "E": "Rene",
      "F": "",
      "G": "SNMTB",
      "H": "",
      "I": "00:10:32.38",
      "J": "+00:01:52.74",
      "K": "",
      "L": "2:49.52",
      "M": "5",
      "N": "1:00.92",
      "O": "4",
      "P": "1:21.83",
      "Q": "7",
      "R": "3:50.40",
      "S": "7",
      "T": "1:29.71",
      "U": "7",
      "V": "",
      "W": ""
    },
    {
      "A": "7",
      "B": "79",
      "C": "Men Intermediate Open",
      "D": "Becerra",
      "E": "Adam",
      "F": "",
      "G": "Dogspeed",
      "H": "",
      "I": "00:10:38.43",
      "J": "+00:01:58.79",
      "K": "",
      "L": "2:51.08",
      "M": "8",
      "N": "1:04.76",
      "O": "8",
      "P": "1:21.22",
      "Q": "6",
      "R": "3:47.15",
      "S": "5",
      "T": "1:34.22",
      "U": "10",
      "V": "",
      "W": ""
    },
    {
      "A": "8",
      "B": "69",
      "C": "Men Intermediate Open",
      "D": "Bell",
      "E": "Josh",
      "F": "",
      "G": "Podium Finish",
      "H": "",
      "I": "00:10:44.00",
      "J": "+00:02:04.36",
      "K": "",
      "L": "2:57.80",
      "M": "10",
      "N": "1:05.18",
      "O": "9",
      "P": "1:24.70",
      "Q": "8",
      "R": "3:53.20",
      "S": "9",
      "T": "1:23.12",
      "U": "5",
      "V": "",
      "W": ""
    },
    {
      "A": "9",
      "B": "71",
      "C": "Men Intermediate Open",
      "D": "Harkey",
      "E": "Clinton",
      "F": "",
      "G": "Wreckless Sending",
      "H": "",
      "I": "00:10:56.08",
      "J": "+00:02:16.44",
      "K": "",
      "L": "3:03.72",
      "M": "11",
      "N": "1:05.62",
      "O": "10",
      "P": "1:27.92",
      "Q": "10",
      "R": "3:47.45",
      "S": "6",
      "T": "1:31.37",
      "U": "9",
      "V": "",
      "W": ""
    },
    {
      "A": "10",
      "B": "58",
      "C": "Men Intermediate Open",
      "D": "Gustin",
      "E": "Jake",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:10:58.29",
      "J": "+00:02:18.65",
      "K": "",
      "L": "2:52.70",
      "M": "9",
      "N": "1:06.70",
      "O": "11",
      "P": "1:28.60",
      "Q": "11",
      "R": "3:53.05",
      "S": "8",
      "T": "1:37.24",
      "U": "11",
      "V": "",
      "W": ""
    },
    {
      "A": "11",
      "B": "75",
      "C": "Men Intermediate Open",
      "D": "Reyes",
      "E": "Julio",
      "F": "",
      "G": "Mozketeroz",
      "H": "",
      "I": "00:11:17.55",
      "J": "+00:02:37.91",
      "K": "",
      "L": "3:11.43",
      "M": "12",
      "N": "1:11.57",
      "O": "12",
      "P": "1:26.07",
      "Q": "9",
      "R": "3:57.53",
      "S": "10",
      "T": "1:30.95",
      "U": "8",
      "V": "",
      "W": ""
    },
    {
      "A": "12",
      "B": "76",
      "C": "Men Intermediate Open",
      "D": "Ruiz",
      "E": "Diego",
      "F": "",
      "G": "The Riviera",
      "H": "",
      "I": "00:12:58.57",
      "J": "+00:04:18.93",
      "K": "",
      "L": "3:12.93",
      "M": "13",
      "N": "1:35.93",
      "O": "13",
      "P": "1:50.90",
      "Q": "12",
      "R": "4:20.88",
      "S": "12",
      "T": "1:57.93",
      "U": "12",
      "V": "",
      "W": ""
    },
    {
      "A": "DNF",
      "B": "2",
      "C": "Men Intermediate Open",
      "D": "Nevarez",
      "E": "Mark",
      "F": "",
      "G": "NorthCrew",
      "H": "",
      "I": "",
      "J": "",
      "K": "",
      "L": "2:39.63",
      "M": "4",
      "N": "1:01.88",
      "O": "6",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": ""
    },
    {
      "A": "1",
      "B": "81",
      "C": "Women Open",
      "D": "Samsel",
      "E": "Kara",
      "F": "",
      "G": "Podium Finish",
      "H": "",
      "I": "00:13:38.51",
      "J": "",
      "K": "",
      "L": "3:19.70",
      "M": "1",
      "N": "1:49.68",
      "O": "1",
      "P": "1:47.46",
      "Q": "1",
      "R": "4:31.99",
      "S": "1",
      "T": "2:09.68",
      "U": "1",
      "V": "",
      "W": ""
    },
    {
      "A": "2",
      "B": "83",
      "C": "Women Open",
      "D": "Hernandez",
      "E": "Corina",
      "F": "",
      "G": "",
      "H": "",
      "I": "00:17:45.22",
      "J": "+00:04:06.71",
      "K": "",
      "L": "3:55.73",
      "M": "2",
      "N": "2:14.12",
      "O": "2",
      "P": "3:12.68",
      "Q": "3",
      "R": "4:50.43",
      "S": "2",
      "T": "3:32.26",
      "U": "3",
      "V": "",
      "W": ""
    },
    {
      "A": "3",
      "B": "33",
      "C": "Women Open",
      "D": "Nelson",
      "E": "Oceana",
      "F": "",
      "G": "Podium Finish",
      "H": "",
      "I": "00:18:12.18",
      "J": "+00:04:33.67",
      "K": "",
      "L": "4:22.20",
      "M": "3",
      "N": "4:39.42",
      "O": "3",
      "P": "1:50.25",
      "Q": "2",
      "R": "5:01.78",
      "S": "3",
      "T": "2:18.53",
      "U": "2",
      "V": "",
      "W": ""
    }
   ]

export default function lost_dog_2022() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>Lost Dog 2022</h1>
         
            <table class="tableizer-table">
                <thead><tr class="tableizer-firstrow"><th>Pos</th><th>Plate Number</th><th>Category</th><th>Surname</th><th>First Name</th><th>Team</th><th>EWS Number</th><th>Time</th><th>Gap</th><th>Stage 1 - Trans Drop In!  Time</th><th>Stage 1 - Trans Drop In!  Pos</th><th>Stage 2 - Tin Mine Time</th><th>Stage 2 - Tin Mine Pos</th><th>Stage 3 - Granola Bowl Time</th><th>Stage 3 - Granola Bowl Pos</th><th>Stage 4 - Little Moab  Time</th><th>Stage 4 - Little Moab  Pos</th><th>Stage 5 - La Espina Ledge Time</th><th>Stage 5 - La Espina Ledge Pos</th></tr></thead><tbody>
                <tr><td>1</td><td>54</td><td>Men 50+</td><td>Gonzalez</td><td>Octavio</td><td>Embrace the Suck</td><td>&nbsp;</td><td>00:10:44.87</td><td>&nbsp;</td><td>3:00.63</td><td>1</td><td>1:03.40</td><td>1</td><td>1:17.73</td><td>1</td><td>3:59.03</td><td>1</td><td>1:24.08</td><td>1</td></tr>
                <tr><td>1</td><td>52</td><td>Men Beginner Open</td><td>Guerra</td><td>Max</td><td>&nbsp;</td><td>&nbsp;</td><td>00:10:17.47</td><td>&nbsp;</td><td>2:41.93</td><td>1</td><td>0:59.52</td><td>1</td><td>1:15.18</td><td>1</td><td>3:59.22</td><td>2</td><td>1:21.62</td><td>1</td></tr>
                <tr><td>2</td><td>80</td><td>Men Beginner Open</td><td>Ivey</td><td>Wyatt</td><td>&nbsp;</td><td>&nbsp;</td><td>00:10:32.39</td><td>+00:00:14.92</td><td>2:52.65</td><td>3</td><td>1:03.38</td><td>2</td><td>1:21.64</td><td>2</td><td>3:47.75</td><td>1</td><td>1:26.97</td><td>2</td></tr>
                <tr><td>3</td><td>60</td><td>Men Beginner Open</td><td>O</td><td>Rene De la</td><td>&nbsp;</td><td>&nbsp;</td><td>00:11:38.14</td><td>+00:01:20.67</td><td>3:06.00</td><td>4</td><td>1:09.95</td><td>3</td><td>1:34.07</td><td>4</td><td>4:06.75</td><td>4</td><td>1:41.37</td><td>3</td></tr>
                <tr><td>4</td><td>82</td><td>Men Beginner Open</td><td>Corral</td><td>Jesus</td><td>Green Team / Smoothie King</td><td>&nbsp;</td><td>00:12:00.03</td><td>+00:01:42.56</td><td>2:45.53</td><td>2</td><td>1:27.91</td><td>5</td><td>1:31.97</td><td>3</td><td>4:01.90</td><td>3</td><td>2:12.72</td><td>5</td></tr>
                <tr><td>5</td><td>55</td><td>Men Beginner Open</td><td>Reyes</td><td>Izzy</td><td>&nbsp;</td><td>&nbsp;</td><td>00:13:02.66</td><td>+00:02:45.19</td><td>3:13.52</td><td>5</td><td>1:25.85</td><td>4</td><td>1:49.59</td><td>5</td><td>4:38.25</td><td>5</td><td>1:55.45</td><td>4</td></tr>
                <tr><td>6</td><td>56</td><td>Men Beginner Open</td><td>Garcia</td><td>Lalo</td><td>&nbsp;</td><td>&nbsp;</td><td>00:14:48.72</td><td>+00:04:31.25</td><td>3:39.13</td><td>6</td><td>1:54.97</td><td>6</td><td>1:58.66</td><td>6</td><td>4:49.55</td><td>6</td><td>2:26.41</td><td>6</td></tr>
                <tr><td>1</td><td>77</td><td>Men Expert Open</td><td>Sotomayor</td><td>Eric</td><td>The Shock Lab / NorthCrew</td><td>&nbsp;</td><td>00:08:42.32</td><td>&nbsp;</td><td>2:32.70</td><td>3</td><td>0:51.60</td><td>3</td><td>1:06.55</td><td>3</td><td>3:03.72</td><td>1</td><td>1:07.75</td><td>1</td></tr>
                <tr><td>2</td><td>61</td><td>Men Expert Open</td><td>Gutierrez</td><td>Alexander</td><td>&nbsp;</td><td>&nbsp;</td><td>00:08:49.41</td><td>+00:00:07.09</td><td>2:18.88</td><td>1</td><td>0:49.32</td><td>2</td><td>1:06.41</td><td>2</td><td>3:22.40</td><td>4</td><td>1:12.40</td><td>3</td></tr>
                <tr><td>3</td><td>73</td><td>Men Expert Open</td><td>Cutburth</td><td>Luke</td><td>Rad Dog Bikes / LDCB</td><td>&nbsp;</td><td>00:09:07.11</td><td>+00:00:24.79</td><td>2:35.58</td><td>4</td><td>0:53.40</td><td>4</td><td>1:07.56</td><td>4</td><td>3:12.56</td><td>2</td><td>1:18.01</td><td>7</td></tr>
                <tr><td>4</td><td>65</td><td>Men Expert Open</td><td>Lujan</td><td>Joel</td><td>NorthCrew / The Shock Lab</td><td>575385</td><td>00:09:10.61</td><td>+00:00:28.29</td><td>2:36.75</td><td>5</td><td>0:53.84</td><td>5</td><td>1:09.60</td><td>6</td><td>3:19.76</td><td>3</td><td>1:10.66</td><td>2</td></tr>
                <tr><td>5</td><td>24</td><td>Men Expert Open</td><td>Shaffer</td><td>Wayne</td><td>&nbsp;</td><td>&nbsp;</td><td>00:09:26.64</td><td>+00:00:44.32</td><td>2:39.19</td><td>6</td><td>0:57.81</td><td>8</td><td>1:10.50</td><td>7</td><td>3:25.87</td><td>5</td><td>1:13.27</td><td>4</td></tr>
                <tr><td>6</td><td>68</td><td>Men Expert Open</td><td>&nbsp;</td><td>Clint Nelms</td><td>Old Fools </td><td>&nbsp;</td><td>00:09:33.27</td><td>+00:00:50.95</td><td>2:46.19</td><td>9</td><td>0:54.47</td><td>6</td><td>1:08.30</td><td>5</td><td>3:26.48</td><td>6</td><td>1:17.83</td><td>6</td></tr>
                <tr><td>7</td><td>63</td><td>Men Expert Open</td><td>Burdick</td><td>Hunt</td><td>&nbsp;</td><td>&nbsp;</td><td>00:10:04.70</td><td>+00:01:22.38</td><td>2:46.27</td><td>10</td><td>0:56.98</td><td>7</td><td>1:20.37</td><td>10</td><td>3:44.75</td><td>8</td><td>1:16.33</td><td>5</td></tr>
                <tr><td>8</td><td>89</td><td>Men Expert Open</td><td>Abruzzi</td><td>Brian</td><td>SNMTB</td><td>&nbsp;</td><td>00:10:08.08</td><td>+00:01:25.76</td><td>2:39.28</td><td>7</td><td>1:02.01</td><td>9</td><td>1:16.23</td><td>8</td><td>3:45.25</td><td>9</td><td>1:25.31</td><td>9</td></tr>
                <tr><td>9</td><td>62</td><td>Men Expert Open</td><td>&nbsp;</td><td>Ricardo Granados</td><td>&nbsp;</td><td>&nbsp;</td><td>00:10:30.96</td><td>+00:01:48.64</td><td>2:45.88</td><td>8</td><td>1:25.35</td><td>10</td><td>1:18.46</td><td>9</td><td>3:38.08</td><td>7</td><td>1:23.19</td><td>8</td></tr>
                <tr><td>DNF</td><td>64</td><td>Men Expert Open</td><td>Ramirez</td><td>Edgar</td><td>The Shock Lab</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>2:26.80</td><td>2</td><td>0:47.67</td><td>1</td><td>1:04.48</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>1</td><td>72</td><td>Men Intermediate Open</td><td>Rose</td><td>Michael</td><td>&nbsp;</td><td>&nbsp;</td><td>00:08:39.64</td><td>&nbsp;</td><td>2:23.15</td><td>1</td><td>0:49.13</td><td>1</td><td>1:07.90</td><td>1</td><td>3:03.92</td><td>1</td><td>1:15.54</td><td>1</td></tr>
                <tr><td>2</td><td>57</td><td>Men Intermediate Open</td><td>Chavez</td><td>Isaac</td><td>&nbsp;</td><td>&nbsp;</td><td>00:09:41.63</td><td>+00:01:01.99</td><td>2:33.78</td><td>3</td><td>1:00.88</td><td>2</td><td>1:13.82</td><td>2</td><td>3:24.07</td><td>3</td><td>1:29.08</td><td>6</td></tr>
                <tr><td>3</td><td>90</td><td>Men Intermediate Open</td><td>Palafox</td><td>Will</td><td>Merol Racing / Dogspeed</td><td>&nbsp;</td><td>00:09:50.52</td><td>+00:01:10.88</td><td>2:50.98</td><td>7</td><td>1:00.89</td><td>3</td><td>1:17.05</td><td>4</td><td>3:20.68</td><td>2</td><td>1:20.92</td><td>4</td></tr>
                <tr><td>4</td><td>74</td><td>Men Intermediate Open</td><td>Carranza</td><td>Efren</td><td>The Shock Lab / NorthCrew</td><td>&nbsp;</td><td>00:10:02.87</td><td>+00:01:23.23</td><td>2:50.00</td><td>6</td><td>1:01.16</td><td>5</td><td>1:18.23</td><td>5</td><td>3:32.82</td><td>4</td><td>1:20.66</td><td>3</td></tr>
                <tr><td>5</td><td>78</td><td>Men Intermediate Open</td><td>Rose</td><td>Justin</td><td>Rose Racing</td><td>&nbsp;</td><td>00:10:11.01</td><td>+00:01:31.37</td><td>2:33.75</td><td>2</td><td>1:02.25</td><td>7</td><td>1:15.88</td><td>3</td><td>3:59.13</td><td>11</td><td>1:20.00</td><td>2</td></tr>
                <tr><td>6</td><td>88</td><td>Men Intermediate Open</td><td>Oineza</td><td>Rene</td><td>SNMTB</td><td>&nbsp;</td><td>00:10:32.38</td><td>+00:01:52.74</td><td>2:49.52</td><td>5</td><td>1:00.92</td><td>4</td><td>1:21.83</td><td>7</td><td>3:50.40</td><td>7</td><td>1:29.71</td><td>7</td></tr>
                <tr><td>7</td><td>79</td><td>Men Intermediate Open</td><td>Becerra</td><td>Adam</td><td>Dogspeed</td><td>&nbsp;</td><td>00:10:38.43</td><td>+00:01:58.79</td><td>2:51.08</td><td>8</td><td>1:04.76</td><td>8</td><td>1:21.22</td><td>6</td><td>3:47.15</td><td>5</td><td>1:34.22</td><td>10</td></tr>
                <tr><td>8</td><td>69</td><td>Men Intermediate Open</td><td>Bell</td><td>Josh</td><td>Podium Finish</td><td>&nbsp;</td><td>00:10:44.00</td><td>+00:02:04.36</td><td>2:57.80</td><td>10</td><td>1:05.18</td><td>9</td><td>1:24.70</td><td>8</td><td>3:53.20</td><td>9</td><td>1:23.12</td><td>5</td></tr>
                <tr><td>9</td><td>71</td><td>Men Intermediate Open</td><td>Harkey</td><td>Clinton</td><td>Wreckless Sending</td><td>&nbsp;</td><td>00:10:56.08</td><td>+00:02:16.44</td><td>3:03.72</td><td>11</td><td>1:05.62</td><td>10</td><td>1:27.92</td><td>10</td><td>3:47.45</td><td>6</td><td>1:31.37</td><td>9</td></tr>
                <tr><td>10</td><td>58</td><td>Men Intermediate Open</td><td>Gustin</td><td>Jake</td><td>&nbsp;</td><td>&nbsp;</td><td>00:10:58.29</td><td>+00:02:18.65</td><td>2:52.70</td><td>9</td><td>1:06.70</td><td>11</td><td>1:28.60</td><td>11</td><td>3:53.05</td><td>8</td><td>1:37.24</td><td>11</td></tr>
                <tr><td>11</td><td>75</td><td>Men Intermediate Open</td><td>Reyes</td><td>Julio</td><td>Mozketeroz</td><td>&nbsp;</td><td>00:11:17.55</td><td>+00:02:37.91</td><td>3:11.43</td><td>12</td><td>1:11.57</td><td>12</td><td>1:26.07</td><td>9</td><td>3:57.53</td><td>10</td><td>1:30.95</td><td>8</td></tr>
                <tr><td>12</td><td>76</td><td>Men Intermediate Open</td><td>Ruiz</td><td>Diego</td><td>The Riviera</td><td>&nbsp;</td><td>00:12:58.57</td><td>+00:04:18.93</td><td>3:12.93</td><td>13</td><td>1:35.93</td><td>13</td><td>1:50.90</td><td>12</td><td>4:20.88</td><td>12</td><td>1:57.93</td><td>12</td></tr>
                <tr><td>DNF</td><td>2</td><td>Men Intermediate Open</td><td>Nevarez</td><td>Mark</td><td>NorthCrew</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>2:39.63</td><td>4</td><td>1:01.88</td><td>6</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>1</td><td>81</td><td>Women Open</td><td>Samsel</td><td>Kara</td><td>Podium Finish</td><td>&nbsp;</td><td>00:13:38.51</td><td>&nbsp;</td><td>3:19.70</td><td>1</td><td>1:49.68</td><td>1</td><td>1:47.46</td><td>1</td><td>4:31.99</td><td>1</td><td>2:09.68</td><td>1</td></tr>
                <tr><td>2</td><td>83</td><td>Women Open</td><td>Hernandez</td><td>Corina</td><td>&nbsp;</td><td>&nbsp;</td><td>00:17:45.22</td><td>+00:04:06.71</td><td>3:55.73</td><td>2</td><td>2:14.12</td><td>2</td><td>3:12.68</td><td>3</td><td>4:50.43</td><td>2</td><td>3:32.26</td><td>3</td></tr>
                <tr><td>3</td><td>33</td><td>Women Open</td><td>Nelson</td><td>Oceana</td><td>Podium Finish</td><td>&nbsp;</td><td>00:18:12.18</td><td>+00:04:33.67</td><td>4:22.20</td><td>3</td><td>4:39.42</td><td>3</td><td>1:50.25</td><td>2</td><td>5:01.78</td><td>3</td><td>2:18.53</td><td>2</td></tr>
            </tbody></table>
            
            <CSVLink className="downloadLink" data={csvData}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
