import React from 'react'
import './ld_night_2023.css'
import { CSVLink, CSVDownload } from "react-csv";

const DataDownload =[
  {
    "RaceNumber": 53,
    "Name": "Duncan Medlock",
    "Club": "Crazy Cat / Merol Racing",
    "CourseClass": "Men 50+",
    "RaceTime": "12:27.2",
    "Position": 1,
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:56.7",
    "Stage 1 - Trans Drop in Pos": 1,
    "Stage 2 - Moab wrong way Time": "03:10.8",
    "Stage 2 - Moab wrong way Pos": 1,
    "Stage 3 - G.I, JOE/JANE? Time": "00:58.7",
    "Stage 3 - G.I, JOE/JANE? Pos": 1,
    "Stage 4 - THE FATHER GUIDO  Time": "01:18.1",
    "Stage 4 - THE FATHER GUIDO  Pos": 1,
    "Stage 5 - Espina Legde  Time": "01:25.5",
    "Stage 5 - Espina Legde  Pos": 1,
    "Stage 6 - Hidden Espina drops  Time": "02:37.3",
    "Stage 6 - Hidden Espina drops  Pos": 1
  },
  {
    "RaceNumber": 10,
    "Name": "John Conrad",
    "Club": "OutLaw MTBer/Merol Racing",
    "CourseClass": "Men 50+",
    "RaceTime": "17:20.4",
    "Position": 2,
    "BehindTime": "+4:53.21",
    "Stage 1 - Trans Drop in Time": "03:41.0",
    "Stage 1 - Trans Drop in Pos": 2,
    "Stage 2 - Moab wrong way Time": "03:44.6",
    "Stage 2 - Moab wrong way Pos": 2,
    "Stage 3 - G.I, JOE/JANE? Time": "01:18.3",
    "Stage 3 - G.I, JOE/JANE? Pos": 2,
    "Stage 4 - THE FATHER GUIDO  Time": "02:15.2",
    "Stage 4 - THE FATHER GUIDO  Pos": 2,
    "Stage 5 - Espina Legde  Time": "02:21.7",
    "Stage 5 - Espina Legde  Pos": 2,
    "Stage 6 - Hidden Espina drops  Time": "03:59.7",
    "Stage 6 - Hidden Espina drops  Pos": 2
  },
  {
    "RaceNumber": 11,
    "Name": "Charlie Simpson",
    "Club": "Crazy Cat Cyclery",
    "CourseClass": "Men Beginner Open",
    "RaceTime": "12:52.4",
    "Position": 1,
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:51.1",
    "Stage 1 - Trans Drop in Pos": 1,
    "Stage 2 - Moab wrong way Time": "03:29.8",
    "Stage 2 - Moab wrong way Pos": 1,
    "Stage 3 - G.I, JOE/JANE? Time": "01:04.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 1,
    "Stage 4 - THE FATHER GUIDO  Time": "01:11.5",
    "Stage 4 - THE FATHER GUIDO  Pos": 1,
    "Stage 5 - Espina Legde  Time": "01:22.3",
    "Stage 5 - Espina Legde  Pos": 1,
    "Stage 6 - Hidden Espina drops  Time": "02:53.5",
    "Stage 6 - Hidden Espina drops  Pos": 2
  },
  {
    "RaceNumber": 14,
    "Name": "Vaughn Ryden",
    "Club": "Crazy Cat Cyclery",
    "CourseClass": "Men Beginner Open",
    "RaceTime": "13:32.6",
    "Position": 2,
    "BehindTime": "+0:40.23",
    "Stage 1 - Trans Drop in Time": "03:14.9",
    "Stage 1 - Trans Drop in Pos": 3,
    "Stage 2 - Moab wrong way Time": "03:50.6",
    "Stage 2 - Moab wrong way Pos": 4,
    "Stage 3 - G.I, JOE/JANE? Time": "01:10.5",
    "Stage 3 - G.I, JOE/JANE? Pos": 4,
    "Stage 4 - THE FATHER GUIDO  Time": "01:17.5",
    "Stage 4 - THE FATHER GUIDO  Pos": 2,
    "Stage 5 - Espina Legde  Time": "01:27.9",
    "Stage 5 - Espina Legde  Pos": 2,
    "Stage 6 - Hidden Espina drops  Time": "02:31.2",
    "Stage 6 - Hidden Espina drops  Pos": 1
  },
  {
    "RaceNumber": 15,
    "Name": "Maks Bevins",
    "Club": "Crazy Cat Cyclery",
    "CourseClass": "Men Beginner Open",
    "RaceTime": "13:46.6",
    "Position": 3,
    "BehindTime": "+0:54.14",
    "Stage 1 - Trans Drop in Time": "03:00.0",
    "Stage 1 - Trans Drop in Pos": 2,
    "Stage 2 - Moab wrong way Time": "03:32.6",
    "Stage 2 - Moab wrong way Pos": 2,
    "Stage 3 - G.I, JOE/JANE? Time": "01:07.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 3,
    "Stage 4 - THE FATHER GUIDO  Time": "01:33.5",
    "Stage 4 - THE FATHER GUIDO  Pos": 4,
    "Stage 5 - Espina Legde  Time": "01:36.0",
    "Stage 5 - Espina Legde  Pos": 3,
    "Stage 6 - Hidden Espina drops  Time": "02:57.2",
    "Stage 6 - Hidden Espina drops  Pos": 3
  },
  {
    "RaceNumber": 13,
    "Name": "Jake Rath",
    "Club": "Trek El Paso",
    "CourseClass": "Men Beginner Open",
    "RaceTime": "15:59.8",
    "Position": 4,
    "BehindTime": "+3:07.41",
    "Stage 1 - Trans Drop in Time": "03:39.6",
    "Stage 1 - Trans Drop in Pos": 4,
    "Stage 2 - Moab wrong way Time": "03:45.1",
    "Stage 2 - Moab wrong way Pos": 3,
    "Stage 3 - G.I, JOE/JANE? Time": "01:07.1",
    "Stage 3 - G.I, JOE/JANE? Pos": 2,
    "Stage 4 - THE FATHER GUIDO  Time": "01:52.3",
    "Stage 4 - THE FATHER GUIDO  Pos": 5,
    "Stage 5 - Espina Legde  Time": "02:02.0",
    "Stage 5 - Espina Legde  Pos": 5,
    "Stage 6 - Hidden Espina drops  Time": "03:33.8",
    "Stage 6 - Hidden Espina drops  Pos": 5
  },
  {
    "RaceNumber": 12,
    "Name": "Aziel Estrada",
    "Club": "Crazy Cat Cyclery",
    "CourseClass": "Men Beginner Open",
    "RaceTime": "16:02.1",
    "Position": 5,
    "BehindTime": "+3:09.64",
    "Stage 1 - Trans Drop in Time": "03:39.6",
    "Stage 1 - Trans Drop in Pos": 5,
    "Stage 2 - Moab wrong way Time": "04:15.2",
    "Stage 2 - Moab wrong way Pos": 5,
    "Stage 3 - G.I, JOE/JANE? Time": "01:16.0",
    "Stage 3 - G.I, JOE/JANE? Pos": 5,
    "Stage 4 - THE FATHER GUIDO  Time": "01:32.4",
    "Stage 4 - THE FATHER GUIDO  Pos": 3,
    "Stage 5 - Espina Legde  Time": "01:46.0",
    "Stage 5 - Espina Legde  Pos": 4,
    "Stage 6 - Hidden Espina drops  Time": "03:32.8",
    "Stage 6 - Hidden Espina drops  Pos": 4
  },
  {
    "RaceNumber": 25,
    "Name": "Eric Sotomayor",
    "Club": "The Shock Lab",
    "CourseClass": "Men Expert Open",
    "RaceTime": "09:48.9",
    "Position": 1,
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:24.1",
    "Stage 1 - Trans Drop in Pos": 2,
    "Stage 2 - Moab wrong way Time": "02:47.1",
    "Stage 2 - Moab wrong way Pos": 2,
    "Stage 3 - G.I, JOE/JANE? Time": "00:48.4",
    "Stage 3 - G.I, JOE/JANE? Pos": 3,
    "Stage 4 - THE FATHER GUIDO  Time": "00:53.6",
    "Stage 4 - THE FATHER GUIDO  Pos": 1,
    "Stage 5 - Espina Legde  Time": "01:03.9",
    "Stage 5 - Espina Legde  Pos": 1,
    "Stage 6 - Hidden Espina drops  Time": "01:51.9",
    "Stage 6 - Hidden Espina drops  Pos": 2
  },
  {
    "RaceNumber": 17,
    "Name": "Gino Alfrido",
    "Club": "SHREDNSTEEZE, TEJAS RAMPAGE, TEAM TRAIL PARTY SHOC",
    "CourseClass": "Men Expert Open",
    "RaceTime": "10:09.4",
    "Position": 2,
    "BehindTime": "+0:20.47",
    "Stage 1 - Trans Drop in Time": "02:34.6",
    "Stage 1 - Trans Drop in Pos": 7,
    "Stage 2 - Moab wrong way Time": "02:50.7",
    "Stage 2 - Moab wrong way Pos": 4,
    "Stage 3 - G.I, JOE/JANE? Time": "00:48.1",
    "Stage 3 - G.I, JOE/JANE? Pos": 2,
    "Stage 4 - THE FATHER GUIDO  Time": "00:57.3",
    "Stage 4 - THE FATHER GUIDO  Pos": 3,
    "Stage 5 - Espina Legde  Time": "01:07.3",
    "Stage 5 - Espina Legde  Pos": 2,
    "Stage 6 - Hidden Espina drops  Time": "01:51.3",
    "Stage 6 - Hidden Espina drops  Pos": 1
  },
  {
    "RaceNumber": 16,
    "Name": "Clint Nelms",
    "Club": "Big Knobby Squad",
    "CourseClass": "Men Expert Open",
    "RaceTime": "10:31.3",
    "Position": 3,
    "BehindTime": "+0:42.39",
    "Stage 1 - Trans Drop in Time": "02:32.9",
    "Stage 1 - Trans Drop in Pos": 6,
    "Stage 2 - Moab wrong way Time": "02:58.1",
    "Stage 2 - Moab wrong way Pos": 5,
    "Stage 3 - G.I, JOE/JANE? Time": "00:49.6",
    "Stage 3 - G.I, JOE/JANE? Pos": 5,
    "Stage 4 - THE FATHER GUIDO  Time": "00:57.5",
    "Stage 4 - THE FATHER GUIDO  Pos": 4,
    "Stage 5 - Espina Legde  Time": "01:12.8",
    "Stage 5 - Espina Legde  Pos": 4,
    "Stage 6 - Hidden Espina drops  Time": "02:00.4",
    "Stage 6 - Hidden Espina drops  Pos": 3
  },
  {
    "RaceNumber": 19,
    "Name": "Alexander Gutierrez",
    "Club": "",
    "CourseClass": "Men Expert Open",
    "RaceTime": "10:47.6",
    "Position": 4,
    "BehindTime": "+0:58.66",
    "Stage 1 - Trans Drop in Time": "02:23.4",
    "Stage 1 - Trans Drop in Pos": 1,
    "Stage 2 - Moab wrong way Time": "02:46.9",
    "Stage 2 - Moab wrong way Pos": 1,
    "Stage 3 - G.I, JOE/JANE? Time": "00:48.8",
    "Stage 3 - G.I, JOE/JANE? Pos": 4,
    "Stage 4 - THE FATHER GUIDO  Time": "00:55.9",
    "Stage 4 - THE FATHER GUIDO  Pos": 2,
    "Stage 5 - Espina Legde  Time": "01:11.5",
    "Stage 5 - Espina Legde  Pos": 3,
    "Stage 6 - Hidden Espina drops  Time": "02:41.1",
    "Stage 6 - Hidden Espina drops  Pos": 9
  },
  {
    "RaceNumber": 22,
    "Name": "Wyatt Ivey",
    "Club": "Merol Racing",
    "CourseClass": "Men Expert Open",
    "RaceTime": "10:58.9",
    "Position": 5,
    "BehindTime": "+1:09.92",
    "Stage 1 - Trans Drop in Time": "02:29.8",
    "Stage 1 - Trans Drop in Pos": 4,
    "Stage 2 - Moab wrong way Time": "03:10.4",
    "Stage 2 - Moab wrong way Pos": 8,
    "Stage 3 - G.I, JOE/JANE? Time": "00:53.6",
    "Stage 3 - G.I, JOE/JANE? Pos": 7,
    "Stage 4 - THE FATHER GUIDO  Time": "00:57.8",
    "Stage 4 - THE FATHER GUIDO  Pos": 5,
    "Stage 5 - Espina Legde  Time": "01:15.1",
    "Stage 5 - Espina Legde  Pos": 5,
    "Stage 6 - Hidden Espina drops  Time": "02:12.0",
    "Stage 6 - Hidden Espina drops  Pos": 4
  },
  {
    "RaceNumber": 21,
    "Name": "Tony  Escalera",
    "Club": "The Shock Lab",
    "CourseClass": "Men Expert Open",
    "RaceTime": "11:26.4",
    "Position": 6,
    "BehindTime": "+1:37.42",
    "Stage 1 - Trans Drop in Time": "02:40.2",
    "Stage 1 - Trans Drop in Pos": 8,
    "Stage 2 - Moab wrong way Time": "02:59.7",
    "Stage 2 - Moab wrong way Pos": 6,
    "Stage 3 - G.I, JOE/JANE? Time": "00:53.0",
    "Stage 3 - G.I, JOE/JANE? Pos": 6,
    "Stage 4 - THE FATHER GUIDO  Time": "01:02.7",
    "Stage 4 - THE FATHER GUIDO  Pos": 6,
    "Stage 5 - Espina Legde  Time": "01:38.8",
    "Stage 5 - Espina Legde  Pos": 9,
    "Stage 6 - Hidden Espina drops  Time": "02:12.0",
    "Stage 6 - Hidden Espina drops  Pos": 5
  },
  {
    "RaceNumber": 24,
    "Name": "Isaac Chavez",
    "Club": "",
    "CourseClass": "Men Expert Open",
    "RaceTime": "11:28.6",
    "Position": 7,
    "BehindTime": "+1:39.67",
    "Stage 1 - Trans Drop in Time": "02:31.3",
    "Stage 1 - Trans Drop in Pos": 5,
    "Stage 2 - Moab wrong way Time": "03:19.6",
    "Stage 2 - Moab wrong way Pos": 10,
    "Stage 3 - G.I, JOE/JANE? Time": "00:56.0",
    "Stage 3 - G.I, JOE/JANE? Pos": 8,
    "Stage 4 - THE FATHER GUIDO  Time": "01:08.6",
    "Stage 4 - THE FATHER GUIDO  Pos": 9,
    "Stage 5 - Espina Legde  Time": "01:18.5",
    "Stage 5 - Espina Legde  Pos": 7,
    "Stage 6 - Hidden Espina drops  Time": "02:14.6",
    "Stage 6 - Hidden Espina drops  Pos": 7
  },
  {
    "RaceNumber": 18,
    "Name": "Hunt Burdick",
    "Club": "Squadpilot",
    "CourseClass": "Men Expert Open",
    "RaceTime": "11:35.0",
    "Position": 8,
    "BehindTime": "+1:46.02",
    "Stage 1 - Trans Drop in Time": "02:45.5",
    "Stage 1 - Trans Drop in Pos": 9,
    "Stage 2 - Moab wrong way Time": "03:06.5",
    "Stage 2 - Moab wrong way Pos": 7,
    "Stage 3 - G.I, JOE/JANE? Time": "00:58.6",
    "Stage 3 - G.I, JOE/JANE? Pos": 10,
    "Stage 4 - THE FATHER GUIDO  Time": "01:14.0",
    "Stage 4 - THE FATHER GUIDO  Pos": 10,
    "Stage 5 - Espina Legde  Time": "01:16.5",
    "Stage 5 - Espina Legde  Pos": 6,
    "Stage 6 - Hidden Espina drops  Time": "02:13.9",
    "Stage 6 - Hidden Espina drops  Pos": 6
  },
  {
    "RaceNumber": 23,
    "Name": "Mark Nevarez",
    "Club": "",
    "CourseClass": "Men Expert Open",
    "RaceTime": "11:48.9",
    "Position": 9,
    "BehindTime": "+2:00.00",
    "Stage 1 - Trans Drop in Time": "02:48.5",
    "Stage 1 - Trans Drop in Pos": 10,
    "Stage 2 - Moab wrong way Time": "03:16.3",
    "Stage 2 - Moab wrong way Pos": 9,
    "Stage 3 - G.I, JOE/JANE? Time": "00:57.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 9,
    "Stage 4 - THE FATHER GUIDO  Time": "01:08.2",
    "Stage 4 - THE FATHER GUIDO  Pos": 8,
    "Stage 5 - Espina Legde  Time": "01:21.0",
    "Stage 5 - Espina Legde  Pos": 8,
    "Stage 6 - Hidden Espina drops  Time": "02:17.8",
    "Stage 6 - Hidden Espina drops  Pos": 8
  },
  {
    "RaceNumber": 20,
    "Name": "Max Guerra",
    "Club": "The Shock Lab / Merol Racing",
    "CourseClass": "Men Expert Open",
    "RaceTime": "",
    "Position": "",
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:24.4",
    "Stage 1 - Trans Drop in Pos": 3,
    "Stage 2 - Moab wrong way Time": "02:48.4",
    "Stage 2 - Moab wrong way Pos": 3,
    "Stage 3 - G.I, JOE/JANE? Time": "00:47.1",
    "Stage 3 - G.I, JOE/JANE? Pos": 1,
    "Stage 4 - THE FATHER GUIDO  Time": "01:07.1",
    "Stage 4 - THE FATHER GUIDO  Pos": 7,
    "Stage 5 - Espina Legde  Time": "",
    "Stage 5 - Espina Legde  Pos": "",
    "Stage 6 - Hidden Espina drops  Time": "",
    "Stage 6 - Hidden Espina drops  Pos": ""
  },
  {
    "RaceNumber": 26,
    "Name": "Daniel Urrutia",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "10:45.2",
    "Position": 1,
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:39.5",
    "Stage 1 - Trans Drop in Pos": 4,
    "Stage 2 - Moab wrong way Time": "02:56.3",
    "Stage 2 - Moab wrong way Pos": 2,
    "Stage 3 - G.I, JOE/JANE? Time": "00:52.9",
    "Stage 3 - G.I, JOE/JANE? Pos": 1,
    "Stage 4 - THE FATHER GUIDO  Time": "00:58.3",
    "Stage 4 - THE FATHER GUIDO  Pos": 1,
    "Stage 5 - Espina Legde  Time": "01:13.3",
    "Stage 5 - Espina Legde  Pos": 1,
    "Stage 6 - Hidden Espina drops  Time": "02:04.9",
    "Stage 6 - Hidden Espina drops  Pos": 2
  },
  {
    "RaceNumber": 32,
    "Name": "Luis Cortazar",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "10:52.9",
    "Position": 2,
    "BehindTime": "+0:07.74",
    "Stage 1 - Trans Drop in Time": "02:29.0",
    "Stage 1 - Trans Drop in Pos": 1,
    "Stage 2 - Moab wrong way Time": "03:06.6",
    "Stage 2 - Moab wrong way Pos": 5,
    "Stage 3 - G.I, JOE/JANE? Time": "00:54.1",
    "Stage 3 - G.I, JOE/JANE? Pos": 3,
    "Stage 4 - THE FATHER GUIDO  Time": "01:03.6",
    "Stage 4 - THE FATHER GUIDO  Pos": 2,
    "Stage 5 - Espina Legde  Time": "01:17.8",
    "Stage 5 - Espina Legde  Pos": 3,
    "Stage 6 - Hidden Espina drops  Time": "02:01.8",
    "Stage 6 - Hidden Espina drops  Pos": 1
  },
  {
    "RaceNumber": 36,
    "Name": "Jesus Garza",
    "Club": "Tejas Rampage",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "11:13.6",
    "Position": 3,
    "BehindTime": "+0:28.39",
    "Stage 1 - Trans Drop in Time": "02:40.6",
    "Stage 1 - Trans Drop in Pos": 6,
    "Stage 2 - Moab wrong way Time": "02:49.1",
    "Stage 2 - Moab wrong way Pos": 1,
    "Stage 3 - G.I, JOE/JANE? Time": "00:53.5",
    "Stage 3 - G.I, JOE/JANE? Pos": 2,
    "Stage 4 - THE FATHER GUIDO  Time": "01:06.7",
    "Stage 4 - THE FATHER GUIDO  Pos": 4,
    "Stage 5 - Espina Legde  Time": "01:25.9",
    "Stage 5 - Espina Legde  Pos": 10,
    "Stage 6 - Hidden Espina drops  Time": "02:17.8",
    "Stage 6 - Hidden Espina drops  Pos": 3
  },
  {
    "RaceNumber": 37,
    "Name": "Julio Reyes",
    "Club": "Mozketeroz",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "11:42.7",
    "Position": 4,
    "BehindTime": "+0:57.46",
    "Stage 1 - Trans Drop in Time": "02:44.5",
    "Stage 1 - Trans Drop in Pos": 8,
    "Stage 2 - Moab wrong way Time": "03:15.7",
    "Stage 2 - Moab wrong way Pos": 7,
    "Stage 3 - G.I, JOE/JANE? Time": "00:55.7",
    "Stage 3 - G.I, JOE/JANE? Pos": 4,
    "Stage 4 - THE FATHER GUIDO  Time": "01:06.9",
    "Stage 4 - THE FATHER GUIDO  Pos": 5,
    "Stage 5 - Espina Legde  Time": "01:16.6",
    "Stage 5 - Espina Legde  Pos": 2,
    "Stage 6 - Hidden Espina drops  Time": "02:23.3",
    "Stage 6 - Hidden Espina drops  Pos": 4
  },
  {
    "RaceNumber": 31,
    "Name": "Adam Becerra",
    "Club": "Dog Speed / Crazy Cat",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "11:50.6",
    "Position": 5,
    "BehindTime": "+1:05.35",
    "Stage 1 - Trans Drop in Time": "02:39.9",
    "Stage 1 - Trans Drop in Pos": 5,
    "Stage 2 - Moab wrong way Time": "03:03.9",
    "Stage 2 - Moab wrong way Pos": 3,
    "Stage 3 - G.I, JOE/JANE? Time": "01:06.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 10,
    "Stage 4 - THE FATHER GUIDO  Time": "01:06.4",
    "Stage 4 - THE FATHER GUIDO  Pos": 3,
    "Stage 5 - Espina Legde  Time": "01:23.9",
    "Stage 5 - Espina Legde  Pos": 9,
    "Stage 6 - Hidden Espina drops  Time": "02:30.2",
    "Stage 6 - Hidden Espina drops  Pos": 8
  },
  {
    "RaceNumber": 30,
    "Name": "Jeffery McAllister",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "11:57.7",
    "Position": 6,
    "BehindTime": "+1:12.46",
    "Stage 1 - Trans Drop in Time": "02:49.5",
    "Stage 1 - Trans Drop in Pos": 9,
    "Stage 2 - Moab wrong way Time": "03:14.1",
    "Stage 2 - Moab wrong way Pos": 6,
    "Stage 3 - G.I, JOE/JANE? Time": "00:58.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 6,
    "Stage 4 - THE FATHER GUIDO  Time": "01:07.9",
    "Stage 4 - THE FATHER GUIDO  Pos": 7,
    "Stage 5 - Espina Legde  Time": "01:18.1",
    "Stage 5 - Espina Legde  Pos": 5,
    "Stage 6 - Hidden Espina drops  Time": "02:29.9",
    "Stage 6 - Hidden Espina drops  Pos": 7
  },
  {
    "RaceNumber": 35,
    "Name": "Aidan Burke",
    "Club": "Wreckless sending",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "12:02.8",
    "Position": 7,
    "BehindTime": "+1:17.65",
    "Stage 1 - Trans Drop in Time": "02:37.7",
    "Stage 1 - Trans Drop in Pos": 3,
    "Stage 2 - Moab wrong way Time": "03:17.1",
    "Stage 2 - Moab wrong way Pos": 8,
    "Stage 3 - G.I, JOE/JANE? Time": "00:56.8",
    "Stage 3 - G.I, JOE/JANE? Pos": 5,
    "Stage 4 - THE FATHER GUIDO  Time": "01:07.8",
    "Stage 4 - THE FATHER GUIDO  Pos": 6,
    "Stage 5 - Espina Legde  Time": "01:19.6",
    "Stage 5 - Espina Legde  Pos": 6,
    "Stage 6 - Hidden Espina drops  Time": "02:43.9",
    "Stage 6 - Hidden Espina drops  Pos": 10
  },
  {
    "RaceNumber": 29,
    "Name": "Artemio Zaragoza",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "12:04.1",
    "Position": 8,
    "BehindTime": "+1:18.85",
    "Stage 1 - Trans Drop in Time": "02:43.0",
    "Stage 1 - Trans Drop in Pos": 7,
    "Stage 2 - Moab wrong way Time": "03:18.6",
    "Stage 2 - Moab wrong way Pos": 10,
    "Stage 3 - G.I, JOE/JANE? Time": "01:00.4",
    "Stage 3 - G.I, JOE/JANE? Pos": 8,
    "Stage 4 - THE FATHER GUIDO  Time": "01:11.0",
    "Stage 4 - THE FATHER GUIDO  Pos": 8,
    "Stage 5 - Espina Legde  Time": "01:17.9",
    "Stage 5 - Espina Legde  Pos": 4,
    "Stage 6 - Hidden Espina drops  Time": "02:33.2",
    "Stage 6 - Hidden Espina drops  Pos": 9
  },
  {
    "RaceNumber": 33,
    "Name": "Clinton Harkey",
    "Club": "Wreckless Sending",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "12:11.9",
    "Position": 9,
    "BehindTime": "+1:26.74",
    "Stage 1 - Trans Drop in Time": "02:54.1",
    "Stage 1 - Trans Drop in Pos": 10,
    "Stage 2 - Moab wrong way Time": "03:18.6",
    "Stage 2 - Moab wrong way Pos": 9,
    "Stage 3 - G.I, JOE/JANE? Time": "01:00.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 7,
    "Stage 4 - THE FATHER GUIDO  Time": "01:12.4",
    "Stage 4 - THE FATHER GUIDO  Pos": 9,
    "Stage 5 - Espina Legde  Time": "01:21.3",
    "Stage 5 - Espina Legde  Pos": 7,
    "Stage 6 - Hidden Espina drops  Time": "02:25.3",
    "Stage 6 - Hidden Espina drops  Pos": 5
  },
  {
    "RaceNumber": 28,
    "Name": "Wade Messer",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "12:15.3",
    "Position": 10,
    "BehindTime": "+1:30.13",
    "Stage 1 - Trans Drop in Time": "02:36.7",
    "Stage 1 - Trans Drop in Pos": 2,
    "Stage 2 - Moab wrong way Time": "03:06.2",
    "Stage 2 - Moab wrong way Pos": 4,
    "Stage 3 - G.I, JOE/JANE? Time": "01:12.9",
    "Stage 3 - G.I, JOE/JANE? Pos": 12,
    "Stage 4 - THE FATHER GUIDO  Time": "01:28.3",
    "Stage 4 - THE FATHER GUIDO  Pos": 10,
    "Stage 5 - Espina Legde  Time": "01:23.7",
    "Stage 5 - Espina Legde  Pos": 8,
    "Stage 6 - Hidden Espina drops  Time": "02:27.5",
    "Stage 6 - Hidden Espina drops  Pos": 6
  },
  {
    "RaceNumber": 27,
    "Name": "Joshua Arce",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "14:05.5",
    "Position": 11,
    "BehindTime": "+3:20.26",
    "Stage 1 - Trans Drop in Time": "02:54.7",
    "Stage 1 - Trans Drop in Pos": 11,
    "Stage 2 - Moab wrong way Time": "03:55.7",
    "Stage 2 - Moab wrong way Pos": 13,
    "Stage 3 - G.I, JOE/JANE? Time": "01:02.8",
    "Stage 3 - G.I, JOE/JANE? Pos": 9,
    "Stage 4 - THE FATHER GUIDO  Time": "01:28.9",
    "Stage 4 - THE FATHER GUIDO  Pos": 11,
    "Stage 5 - Espina Legde  Time": "01:38.5",
    "Stage 5 - Espina Legde  Pos": 12,
    "Stage 6 - Hidden Espina drops  Time": "03:04.8",
    "Stage 6 - Hidden Espina drops  Pos": 11
  },
  {
    "RaceNumber": 38,
    "Name": "Ramon Gamez",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "14:30.9",
    "Position": 12,
    "BehindTime": "+3:45.68",
    "Stage 1 - Trans Drop in Time": "03:06.5",
    "Stage 1 - Trans Drop in Pos": 12,
    "Stage 2 - Moab wrong way Time": "03:40.2",
    "Stage 2 - Moab wrong way Pos": 11,
    "Stage 3 - G.I, JOE/JANE? Time": "01:08.8",
    "Stage 3 - G.I, JOE/JANE? Pos": 11,
    "Stage 4 - THE FATHER GUIDO  Time": "01:31.4",
    "Stage 4 - THE FATHER GUIDO  Pos": 12,
    "Stage 5 - Espina Legde  Time": "01:30.8",
    "Stage 5 - Espina Legde  Pos": 11,
    "Stage 6 - Hidden Espina drops  Time": "03:33.1",
    "Stage 6 - Hidden Espina drops  Pos": 12
  },
  {
    "RaceNumber": 34,
    "Name": "Alex Perales",
    "Club": "",
    "CourseClass": "Men Intermediate Open",
    "RaceTime": "18:43.4",
    "Position": 13,
    "BehindTime": "+7:58.22",
    "Stage 1 - Trans Drop in Time": "03:22.9",
    "Stage 1 - Trans Drop in Pos": 13,
    "Stage 2 - Moab wrong way Time": "03:53.0",
    "Stage 2 - Moab wrong way Pos": 12,
    "Stage 3 - G.I, JOE/JANE? Time": "01:35.7",
    "Stage 3 - G.I, JOE/JANE? Pos": 13,
    "Stage 4 - THE FATHER GUIDO  Time": "02:19.7",
    "Stage 4 - THE FATHER GUIDO  Pos": 13,
    "Stage 5 - Espina Legde  Time": "02:20.2",
    "Stage 5 - Espina Legde  Pos": 13,
    "Stage 6 - Hidden Espina drops  Time": "05:12.0",
    "Stage 6 - Hidden Espina drops  Pos": 13
  },
  {
    "RaceNumber": 41,
    "Name": "Teresa Hughes",
    "Club": "Trail Party",
    "CourseClass": "Women Open",
    "RaceTime": "12:10.2",
    "Position": 1,
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "02:54.8",
    "Stage 1 - Trans Drop in Pos": 1,
    "Stage 2 - Moab wrong way Time": "03:14.5",
    "Stage 2 - Moab wrong way Pos": 1,
    "Stage 3 - G.I, JOE/JANE? Time": "00:56.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 1,
    "Stage 4 - THE FATHER GUIDO  Time": "01:09.6",
    "Stage 4 - THE FATHER GUIDO  Pos": 1,
    "Stage 5 - Espina Legde  Time": "01:23.8",
    "Stage 5 - Espina Legde  Pos": 1,
    "Stage 6 - Hidden Espina drops  Time": "02:31.4",
    "Stage 6 - Hidden Espina drops  Pos": 1
  },
  {
    "RaceNumber": 49,
    "Name": "Veronica Garza",
    "Club": "Tejas Rampage / ShredNSteeze",
    "CourseClass": "Women Open",
    "RaceTime": "13:00.2",
    "Position": 2,
    "BehindTime": "+0:49.97",
    "Stage 1 - Trans Drop in Time": "02:59.3",
    "Stage 1 - Trans Drop in Pos": 2,
    "Stage 2 - Moab wrong way Time": "03:19.9",
    "Stage 2 - Moab wrong way Pos": 2,
    "Stage 3 - G.I, JOE/JANE? Time": "00:59.9",
    "Stage 3 - G.I, JOE/JANE? Pos": 2,
    "Stage 4 - THE FATHER GUIDO  Time": "01:16.0",
    "Stage 4 - THE FATHER GUIDO  Pos": 2,
    "Stage 5 - Espina Legde  Time": "01:32.3",
    "Stage 5 - Espina Legde  Pos": 2,
    "Stage 6 - Hidden Espina drops  Time": "02:52.9",
    "Stage 6 - Hidden Espina drops  Pos": 2
  },
  {
    "RaceNumber": 42,
    "Name": "Angelica Carrillo",
    "Club": "Spanglish",
    "CourseClass": "Women Open",
    "RaceTime": "15:10.5",
    "Position": 3,
    "BehindTime": "+3:00.23",
    "Stage 1 - Trans Drop in Time": "03:13.4",
    "Stage 1 - Trans Drop in Pos": 3,
    "Stage 2 - Moab wrong way Time": "03:40.1",
    "Stage 2 - Moab wrong way Pos": 3,
    "Stage 3 - G.I, JOE/JANE? Time": "01:13.2",
    "Stage 3 - G.I, JOE/JANE? Pos": 3,
    "Stage 4 - THE FATHER GUIDO  Time": "01:42.0",
    "Stage 4 - THE FATHER GUIDO  Pos": 5,
    "Stage 5 - Espina Legde  Time": "02:09.0",
    "Stage 5 - Espina Legde  Pos": 5,
    "Stage 6 - Hidden Espina drops  Time": "03:12.7",
    "Stage 6 - Hidden Espina drops  Pos": 3
  },
  {
    "RaceNumber": 40,
    "Name": "Oceana  Nelson",
    "Club": "The Dirt Therapy Project / Merol Racing",
    "CourseClass": "Women Open",
    "RaceTime": "17:16.0",
    "Position": 4,
    "BehindTime": "+5:05.74",
    "Stage 1 - Trans Drop in Time": "03:34.0",
    "Stage 1 - Trans Drop in Pos": 5,
    "Stage 2 - Moab wrong way Time": "03:51.6",
    "Stage 2 - Moab wrong way Pos": 4,
    "Stage 3 - G.I, JOE/JANE? Time": "01:18.0",
    "Stage 3 - G.I, JOE/JANE? Pos": 4,
    "Stage 4 - THE FATHER GUIDO  Time": "01:38.1",
    "Stage 4 - THE FATHER GUIDO  Pos": 3,
    "Stage 5 - Espina Legde  Time": "01:46.6",
    "Stage 5 - Espina Legde  Pos": 3,
    "Stage 6 - Hidden Espina drops  Time": "05:07.7",
    "Stage 6 - Hidden Espina drops  Pos": 4
  },
  {
    "RaceNumber": 43,
    "Name": "Ana Montoya",
    "Club": "Miners MTB/Optavia",
    "CourseClass": "Women Open",
    "RaceTime": "19:46.9",
    "Position": 5,
    "BehindTime": "+7:36.61",
    "Stage 1 - Trans Drop in Time": "03:58.9",
    "Stage 1 - Trans Drop in Pos": 6,
    "Stage 2 - Moab wrong way Time": "04:29.5",
    "Stage 2 - Moab wrong way Pos": 6,
    "Stage 3 - G.I, JOE/JANE? Time": "01:39.5",
    "Stage 3 - G.I, JOE/JANE? Pos": 6,
    "Stage 4 - THE FATHER GUIDO  Time": "02:23.9",
    "Stage 4 - THE FATHER GUIDO  Pos": 7,
    "Stage 5 - Espina Legde  Time": "02:06.7",
    "Stage 5 - Espina Legde  Pos": 4,
    "Stage 6 - Hidden Espina drops  Time": "05:08.5",
    "Stage 6 - Hidden Espina drops  Pos": 5
  },
  {
    "RaceNumber": 39,
    "Name": "Sandy Garcia",
    "Club": "The Dirt Therapy Project",
    "CourseClass": "Women Open",
    "RaceTime": "21:16.8",
    "Position": 6,
    "BehindTime": "+9:06.52",
    "Stage 1 - Trans Drop in Time": "04:21.5",
    "Stage 1 - Trans Drop in Pos": 7,
    "Stage 2 - Moab wrong way Time": "04:37.4",
    "Stage 2 - Moab wrong way Pos": 7,
    "Stage 3 - G.I, JOE/JANE? Time": "01:48.8",
    "Stage 3 - G.I, JOE/JANE? Pos": 7,
    "Stage 4 - THE FATHER GUIDO  Time": "01:48.0",
    "Stage 4 - THE FATHER GUIDO  Pos": 6,
    "Stage 5 - Espina Legde  Time": "02:35.2",
    "Stage 5 - Espina Legde  Pos": 6,
    "Stage 6 - Hidden Espina drops  Time": "06:05.9",
    "Stage 6 - Hidden Espina drops  Pos": 6
  },
  {
    "RaceNumber": 44,
    "Name": "Monica Stahl",
    "Club": "Miners MTB/Optavia",
    "CourseClass": "Women Open",
    "RaceTime": "",
    "Position": "",
    "BehindTime": "",
    "Stage 1 - Trans Drop in Time": "03:28.5",
    "Stage 1 - Trans Drop in Pos": 4,
    "Stage 2 - Moab wrong way Time": "03:51.8",
    "Stage 2 - Moab wrong way Pos": 5,
    "Stage 3 - G.I, JOE/JANE? Time": "01:26.9",
    "Stage 3 - G.I, JOE/JANE? Pos": 5,
    "Stage 4 - THE FATHER GUIDO  Time": "01:40.7",
    "Stage 4 - THE FATHER GUIDO  Pos": 4,
    "Stage 5 - Espina Legde  Time": "",
    "Stage 5 - Espina Legde  Pos": "",
    "Stage 6 - Hidden Espina drops  Time": "",
    "Stage 6 - Hidden Espina drops  Pos": ""
  }
]

export default function franklin() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>GNARDURO 2023</h1>

             
        <table class="tableizer-table">
            <thead><tr class="tableizer-firstrow"><th>RaceNumber</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>BehindTime</th><th>Stage 1 - Trans Drop in Time</th><th>Stage 1 - Trans Drop in Pos</th><th>Stage 2 - Moab wrong way Time</th><th>Stage 2 - Moab wrong way Pos</th><th>Stage 3 - G.I, JOE/JANE? Time</th><th>Stage 3 - G.I, JOE/JANE? Pos</th><th>Stage 4 - THE FATHER GUIDO  Time</th><th>Stage 4 - THE FATHER GUIDO  Pos</th><th>Stage 5 - Espina Legde  Time</th><th>Stage 5 - Espina Legde  Pos</th><th>Stage 6 - Hidden Espina drops  Time</th><th>Stage 6 - Hidden Espina drops  Pos</th></tr></thead><tbody>
                    <tr><td>53</td><td>Duncan Medlock </td><td>Crazy Cat / Merol Racing</td><td>Men 50+</td><td>12:27.2</td><td>1</td><td>&nbsp;</td><td>02:56.7</td><td>1</td><td>03:10.8</td><td>1</td><td>00:58.7</td><td>1</td><td>01:18.1</td><td>1</td><td>01:25.5</td><td>1</td><td>02:37.3</td><td>1</td></tr>
                    <tr><td>10</td><td>John Conrad</td><td>OutLaw MTBer/Merol Racing</td><td>Men 50+</td><td>17:20.4</td><td>2</td><td>+4:53.21</td><td>03:41.0</td><td>2</td><td>03:44.6</td><td>2</td><td>01:18.3</td><td>2</td><td>02:15.2</td><td>2</td><td>02:21.7</td><td>2</td><td>03:59.7</td><td>2</td></tr>
                    <tr><td>11</td><td>Charlie Simpson</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>12:52.4</td><td>1</td><td>&nbsp;</td><td>02:51.1</td><td>1</td><td>03:29.8</td><td>1</td><td>01:04.2</td><td>1</td><td>01:11.5</td><td>1</td><td>01:22.3</td><td>1</td><td>02:53.5</td><td>2</td></tr>
                    <tr><td>14</td><td>Vaughn Ryden</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>13:32.6</td><td>2</td><td>+0:40.23</td><td>03:14.9</td><td>3</td><td>03:50.6</td><td>4</td><td>01:10.5</td><td>4</td><td>01:17.5</td><td>2</td><td>01:27.9</td><td>2</td><td>02:31.2</td><td>1</td></tr>
                    <tr><td>15</td><td>Maks Bevins</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>13:46.6</td><td>3</td><td>+0:54.14</td><td>03:00.0</td><td>2</td><td>03:32.6</td><td>2</td><td>01:07.2</td><td>3</td><td>01:33.5</td><td>4</td><td>01:36.0</td><td>3</td><td>02:57.2</td><td>3</td></tr>
                    <tr><td>13</td><td>Jake Rath</td><td>Trek El Paso</td><td>Men Beginner Open</td><td>15:59.8</td><td>4</td><td>+3:07.41</td><td>03:39.6</td><td>4</td><td>03:45.1</td><td>3</td><td>01:07.1</td><td>2</td><td>01:52.3</td><td>5</td><td>02:02.0</td><td>5</td><td>03:33.8</td><td>5</td></tr>
                    <tr><td>12</td><td>Aziel Estrada</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>16:02.1</td><td>5</td><td>+3:09.64</td><td>03:39.6</td><td>5</td><td>04:15.2</td><td>5</td><td>01:16.0</td><td>5</td><td>01:32.4</td><td>3</td><td>01:46.0</td><td>4</td><td>03:32.8</td><td>4</td></tr>
                    <tr><td>25</td><td>Eric Sotomayor</td><td>The Shock Lab</td><td>Men Expert Open</td><td>09:48.9</td><td>1</td><td>&nbsp;</td><td>02:24.1</td><td>2</td><td>02:47.1</td><td>2</td><td>00:48.4</td><td>3</td><td>00:53.6</td><td>1</td><td>01:03.9</td><td>1</td><td>01:51.9</td><td>2</td></tr>
                    <tr><td>17</td><td>Gino Alfrido</td><td>SHREDNSTEEZE, TEJAS RAMPAGE, TEAM TRAIL PARTY SHOC</td><td>Men Expert Open</td><td>10:09.4</td><td>2</td><td>+0:20.47</td><td>02:34.6</td><td>7</td><td>02:50.7</td><td>4</td><td>00:48.1</td><td>2</td><td>00:57.3</td><td>3</td><td>01:07.3</td><td>2</td><td>01:51.3</td><td>1</td></tr>
                    <tr><td>16</td><td>Clint Nelms</td><td>Big Knobby Squad</td><td>Men Expert Open</td><td>10:31.3</td><td>3</td><td>+0:42.39</td><td>02:32.9</td><td>6</td><td>02:58.1</td><td>5</td><td>00:49.6</td><td>5</td><td>00:57.5</td><td>4</td><td>01:12.8</td><td>4</td><td>02:00.4</td><td>3</td></tr>
                    <tr><td>19</td><td>Alexander Gutierrez</td><td>&nbsp;</td><td>Men Expert Open</td><td>10:47.6</td><td>4</td><td>+0:58.66</td><td>02:23.4</td><td>1</td><td>02:46.9</td><td>1</td><td>00:48.8</td><td>4</td><td>00:55.9</td><td>2</td><td>01:11.5</td><td>3</td><td>02:41.1</td><td>9</td></tr>
                    <tr><td>22</td><td>Wyatt Ivey</td><td>Merol Racing</td><td>Men Expert Open</td><td>10:58.9</td><td>5</td><td>+1:09.92</td><td>02:29.8</td><td>4</td><td>03:10.4</td><td>8</td><td>00:53.6</td><td>7</td><td>00:57.8</td><td>5</td><td>01:15.1</td><td>5</td><td>02:12.0</td><td>4</td></tr>
                    <tr><td>21</td><td>Tony  Escalera</td><td>The Shock Lab</td><td>Men Expert Open</td><td>11:26.4</td><td>6</td><td>+1:37.42</td><td>02:40.2</td><td>8</td><td>02:59.7</td><td>6</td><td>00:53.0</td><td>6</td><td>01:02.7</td><td>6</td><td>01:38.8</td><td>9</td><td>02:12.0</td><td>5</td></tr>
                    <tr><td>24</td><td>Isaac Chavez</td><td>&nbsp;</td><td>Men Expert Open</td><td>11:28.6</td><td>7</td><td>+1:39.67</td><td>02:31.3</td><td>5</td><td>03:19.6</td><td>10</td><td>00:56.0</td><td>8</td><td>01:08.6</td><td>9</td><td>01:18.5</td><td>7</td><td>02:14.6</td><td>7</td></tr>
                    <tr><td>18</td><td>Hunt Burdick</td><td>Squadpilot</td><td>Men Expert Open</td><td>11:35.0</td><td>8</td><td>+1:46.02</td><td>02:45.5</td><td>9</td><td>03:06.5</td><td>7</td><td>00:58.6</td><td>10</td><td>01:14.0</td><td>10</td><td>01:16.5</td><td>6</td><td>02:13.9</td><td>6</td></tr>
                    <tr><td>23</td><td>Mark Nevarez</td><td>&nbsp;</td><td>Men Expert Open</td><td>11:48.9</td><td>9</td><td>+2:00.00</td><td>02:48.5</td><td>10</td><td>03:16.3</td><td>9</td><td>00:57.2</td><td>9</td><td>01:08.2</td><td>8</td><td>01:21.0</td><td>8</td><td>02:17.8</td><td>8</td></tr>
                    <tr><td>20</td><td>Max Guerra</td><td>The Shock Lab / Merol Racing</td><td>Men Expert Open</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>02:24.4</td><td>3</td><td>02:48.4</td><td>3</td><td>00:47.1</td><td>1</td><td>01:07.1</td><td>7</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                    <tr><td>26</td><td>Daniel Urrutia</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>10:45.2</td><td>1</td><td>&nbsp;</td><td>02:39.5</td><td>4</td><td>02:56.3</td><td>2</td><td>00:52.9</td><td>1</td><td>00:58.3</td><td>1</td><td>01:13.3</td><td>1</td><td>02:04.9</td><td>2</td></tr>
                    <tr><td>32</td><td>Luis Cortazar</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>10:52.9</td><td>2</td><td>+0:07.74</td><td>02:29.0</td><td>1</td><td>03:06.6</td><td>5</td><td>00:54.1</td><td>3</td><td>01:03.6</td><td>2</td><td>01:17.8</td><td>3</td><td>02:01.8</td><td>1</td></tr>
                    <tr><td>36</td><td>Jesus Garza</td><td>Tejas Rampage</td><td>Men Intermediate Open</td><td>11:13.6</td><td>3</td><td>+0:28.39</td><td>02:40.6</td><td>6</td><td>02:49.1</td><td>1</td><td>00:53.5</td><td>2</td><td>01:06.7</td><td>4</td><td>01:25.9</td><td>10</td><td>02:17.8</td><td>3</td></tr>
                    <tr><td>37</td><td>Julio Reyes</td><td>Mozketeroz</td><td>Men Intermediate Open</td><td>11:42.7</td><td>4</td><td>+0:57.46</td><td>02:44.5</td><td>8</td><td>03:15.7</td><td>7</td><td>00:55.7</td><td>4</td><td>01:06.9</td><td>5</td><td>01:16.6</td><td>2</td><td>02:23.3</td><td>4</td></tr>
                    <tr><td>31</td><td>Adam Becerra</td><td>Dog Speed / Crazy Cat</td><td>Men Intermediate Open</td><td>11:50.6</td><td>5</td><td>+1:05.35</td><td>02:39.9</td><td>5</td><td>03:03.9</td><td>3</td><td>01:06.2</td><td>10</td><td>01:06.4</td><td>3</td><td>01:23.9</td><td>9</td><td>02:30.2</td><td>8</td></tr>
                    <tr><td>30</td><td>Jeffery McAllister</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>11:57.7</td><td>6</td><td>+1:12.46</td><td>02:49.5</td><td>9</td><td>03:14.1</td><td>6</td><td>00:58.2</td><td>6</td><td>01:07.9</td><td>7</td><td>01:18.1</td><td>5</td><td>02:29.9</td><td>7</td></tr>
                    <tr><td>35</td><td>Aidan Burke</td><td>Wreckless sending</td><td>Men Intermediate Open</td><td>12:02.8</td><td>7</td><td>+1:17.65</td><td>02:37.7</td><td>3</td><td>03:17.1</td><td>8</td><td>00:56.8</td><td>5</td><td>01:07.8</td><td>6</td><td>01:19.6</td><td>6</td><td>02:43.9</td><td>10</td></tr>
                    <tr><td>29</td><td>Artemio Zaragoza</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>12:04.1</td><td>8</td><td>+1:18.85</td><td>02:43.0</td><td>7</td><td>03:18.6</td><td>10</td><td>01:00.4</td><td>8</td><td>01:11.0</td><td>8</td><td>01:17.9</td><td>4</td><td>02:33.2</td><td>9</td></tr>
                    <tr><td>33</td><td>Clinton Harkey</td><td>Wreckless Sending</td><td>Men Intermediate Open</td><td>12:11.9</td><td>9</td><td>+1:26.74</td><td>02:54.1</td><td>10</td><td>03:18.6</td><td>9</td><td>01:00.2</td><td>7</td><td>01:12.4</td><td>9</td><td>01:21.3</td><td>7</td><td>02:25.3</td><td>5</td></tr>
                    <tr><td>28</td><td>Wade Messer</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>12:15.3</td><td>10</td><td>+1:30.13</td><td>02:36.7</td><td>2</td><td>03:06.2</td><td>4</td><td>01:12.9</td><td>12</td><td>01:28.3</td><td>10</td><td>01:23.7</td><td>8</td><td>02:27.5</td><td>6</td></tr>
                    <tr><td>27</td><td>Joshua Arce</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>14:05.5</td><td>11</td><td>+3:20.26</td><td>02:54.7</td><td>11</td><td>03:55.7</td><td>13</td><td>01:02.8</td><td>9</td><td>01:28.9</td><td>11</td><td>01:38.5</td><td>12</td><td>03:04.8</td><td>11</td></tr>
                    <tr><td>38</td><td>Ramon Gamez</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>14:30.9</td><td>12</td><td>+3:45.68</td><td>03:06.5</td><td>12</td><td>03:40.2</td><td>11</td><td>01:08.8</td><td>11</td><td>01:31.4</td><td>12</td><td>01:30.8</td><td>11</td><td>03:33.1</td><td>12</td></tr>
                    <tr><td>34</td><td>Alex Perales</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>18:43.4</td><td>13</td><td>+7:58.22</td><td>03:22.9</td><td>13</td><td>03:53.0</td><td>12</td><td>01:35.7</td><td>13</td><td>02:19.7</td><td>13</td><td>02:20.2</td><td>13</td><td>05:12.0</td><td>13</td></tr>
                    <tr><td>41</td><td>Teresa Hughes</td><td>Trail Party</td><td>Women Open</td><td>12:10.2</td><td>1</td><td>&nbsp;</td><td>02:54.8</td><td>1</td><td>03:14.5</td><td>1</td><td>00:56.2</td><td>1</td><td>01:09.6</td><td>1</td><td>01:23.8</td><td>1</td><td>02:31.4</td><td>1</td></tr>
                    <tr><td>49</td><td>Veronica Garza</td><td>Tejas Rampage / ShredNSteeze</td><td>Women Open</td><td>13:00.2</td><td>2</td><td>+0:49.97</td><td>02:59.3</td><td>2</td><td>03:19.9</td><td>2</td><td>00:59.9</td><td>2</td><td>01:16.0</td><td>2</td><td>01:32.3</td><td>2</td><td>02:52.9</td><td>2</td></tr>
                    <tr><td>42</td><td>Angelica Carrillo</td><td>Spanglish</td><td>Women Open</td><td>15:10.5</td><td>3</td><td>+3:00.23</td><td>03:13.4</td><td>3</td><td>03:40.1</td><td>3</td><td>01:13.2</td><td>3</td><td>01:42.0</td><td>5</td><td>02:09.0</td><td>5</td><td>03:12.7</td><td>3</td></tr>
                    <tr><td>40</td><td>Oceana  Nelson</td><td>The Dirt Therapy Project / Merol Racing</td><td>Women Open</td><td>17:16.0</td><td>4</td><td>+5:05.74</td><td>03:34.0</td><td>5</td><td>03:51.6</td><td>4</td><td>01:18.0</td><td>4</td><td>01:38.1</td><td>3</td><td>01:46.6</td><td>3</td><td>05:07.7</td><td>4</td></tr>
                    <tr><td>43</td><td>Ana Montoya</td><td>Miners MTB/Optavia</td><td>Women Open</td><td>19:46.9</td><td>5</td><td>+7:36.61</td><td>03:58.9</td><td>6</td><td>04:29.5</td><td>6</td><td>01:39.5</td><td>6</td><td>02:23.9</td><td>7</td><td>02:06.7</td><td>4</td><td>05:08.5</td><td>5</td></tr>
                    <tr><td>39</td><td>Sandy Garcia</td><td>The Dirt Therapy Project</td><td>Women Open</td><td>21:16.8</td><td>6</td><td>+9:06.52</td><td>04:21.5</td><td>7</td><td>04:37.4</td><td>7</td><td>01:48.8</td><td>7</td><td>01:48.0</td><td>6</td><td>02:35.2</td><td>6</td><td>06:05.9</td><td>6</td></tr>
                    <tr><td>44</td><td>Monica Stahl</td><td>Miners MTB/Optavia</td><td>Women Open</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>03:28.5</td><td>4</td><td>03:51.8</td><td>5</td><td>01:26.9</td><td>5</td><td>01:40.7</td><td>4</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td></td></tr>
            </tbody></table>
            
            <CSVLink className="downloadLink" data={DataDownload}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
