import React from 'react'
import './ld_night.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvDataLDNIGHT2022 = [
    {
      "RaceNumber": 100,
      "Name": "Manny Arellano",
      "Club": "",
      "CourseClass": "Men 50+",
      "RaceTime": "",
      "Position": null,
      "Status": "DNF",
      "BehindTime": "",
      "Stage 1 - 10 Min  Time": "02:47.5",
      "Stage 1 - 10 Min  Pos": null,
      "Stage 2 - Brujos west Time": "02:36.2",
      "Stage 2 - Brujos west Pos": null,
      "Stage 3 - Brujos blitz Time": "01:31.9",
      "Stage 3 - Brujos blitz Pos": null,
      "Stage 4 - Broke Back  Time": "",
      "Stage 4 - Broke Back  Pos": null
    },
    {
      "RaceNumber": 7,
      "Name": "Christopher Beversdorf",
      "Club": "TACOCAT",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:11.5",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - 10 Min  Time": "02:24.6",
      "Stage 1 - 10 Min  Pos": 3,
      "Stage 2 - Brujos west Time": "02:24.2",
      "Stage 2 - Brujos west Pos": 2,
      "Stage 3 - Brujos blitz Time": "01:20.4",
      "Stage 3 - Brujos blitz Pos": 2,
      "Stage 4 - Broke Back  Time": "04:02.3",
      "Stage 4 - Broke Back  Pos": 2
    },
    {
      "RaceNumber": 4,
      "Name": "Rene De La O",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:25.7",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:14.12",
      "Stage 1 - 10 Min  Time": "02:20.6",
      "Stage 1 - 10 Min  Pos": 2,
      "Stage 2 - Brujos west Time": "02:30.9",
      "Stage 2 - Brujos west Pos": 3,
      "Stage 3 - Brujos blitz Time": "01:21.2",
      "Stage 3 - Brujos blitz Pos": 3,
      "Stage 4 - Broke Back  Time": "04:12.9",
      "Stage 4 - Broke Back  Pos": 3
    },
    {
      "RaceNumber": 6,
      "Name": "Wyatt Ivey",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:32.5",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:20.91",
      "Stage 1 - 10 Min  Time": "02:17.8",
      "Stage 1 - 10 Min  Pos": 1,
      "Stage 2 - Brujos west Time": "03:02.5",
      "Stage 2 - Brujos west Pos": 5,
      "Stage 3 - Brujos blitz Time": "01:20.0",
      "Stage 3 - Brujos blitz Pos": 1,
      "Stage 4 - Broke Back  Time": "03:52.0",
      "Stage 4 - Broke Back  Pos": 1
    },
    {
      "RaceNumber": 10,
      "Name": "Max Guerra",
      "Club": "The Shocklab",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:41.2",
      "Position": 4,
      "Status": "",
      "BehindTime": "+0:29.68",
      "Stage 1 - 10 Min  Time": "02:39.4",
      "Stage 1 - 10 Min  Pos": 4,
      "Stage 2 - Brujos west Time": "02:16.4",
      "Stage 2 - Brujos west Pos": 1,
      "Stage 3 - Brujos blitz Time": "01:26.6",
      "Stage 3 - Brujos blitz Pos": 4,
      "Stage 4 - Broke Back  Time": "04:18.9",
      "Stage 4 - Broke Back  Pos": 4
    },
    {
      "RaceNumber": 8,
      "Name": "Izzy Reyes",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "11:31.6",
      "Position": 5,
      "Status": "",
      "BehindTime": "+1:20.09",
      "Stage 1 - 10 Min  Time": "02:43.9",
      "Stage 1 - 10 Min  Pos": 6,
      "Stage 2 - Brujos west Time": "02:33.0",
      "Stage 2 - Brujos west Pos": 4,
      "Stage 3 - Brujos blitz Time": "01:44.6",
      "Stage 3 - Brujos blitz Pos": 6,
      "Stage 4 - Broke Back  Time": "04:30.1",
      "Stage 4 - Broke Back  Pos": 5
    },
    {
      "RaceNumber": 11,
      "Name": "Andre von Twistern",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "12:34.4",
      "Position": 6,
      "Status": "",
      "BehindTime": "+2:22.82",
      "Stage 1 - 10 Min  Time": "02:41.3",
      "Stage 1 - 10 Min  Pos": 5,
      "Stage 2 - Brujos west Time": "03:11.0",
      "Stage 2 - Brujos west Pos": 7,
      "Stage 3 - Brujos blitz Time": "01:41.4",
      "Stage 3 - Brujos blitz Pos": 5,
      "Stage 4 - Broke Back  Time": "05:00.7",
      "Stage 4 - Broke Back  Pos": 9
    },
    {
      "RaceNumber": 3,
      "Name": "Moises Teliz",
      "Club": "Smaz remodeling",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "12:47.7",
      "Position": 7,
      "Status": "",
      "BehindTime": "+2:36.21",
      "Stage 1 - 10 Min  Time": "02:59.2",
      "Stage 1 - 10 Min  Pos": 10,
      "Stage 2 - Brujos west Time": "03:06.6",
      "Stage 2 - Brujos west Pos": 6,
      "Stage 3 - Brujos blitz Time": "01:48.0",
      "Stage 3 - Brujos blitz Pos": 7,
      "Stage 4 - Broke Back  Time": "04:54.0",
      "Stage 4 - Broke Back  Pos": 6
    },
    {
      "RaceNumber": 5,
      "Name": "Jose Luis Ramirez",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "13:04.8",
      "Position": 8,
      "Status": "",
      "BehindTime": "+2:53.23",
      "Stage 1 - 10 Min  Time": "02:51.2",
      "Stage 1 - 10 Min  Pos": 9,
      "Stage 2 - Brujos west Time": "03:14.7",
      "Stage 2 - Brujos west Pos": 9,
      "Stage 3 - Brujos blitz Time": "02:03.4",
      "Stage 3 - Brujos blitz Pos": 9,
      "Stage 4 - Broke Back  Time": "04:55.5",
      "Stage 4 - Broke Back  Pos": 7
    },
    {
      "RaceNumber": 1,
      "Name": "David Peregrino",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "13:12.6",
      "Position": 9,
      "Status": "",
      "BehindTime": "+3:01.08",
      "Stage 1 - 10 Min  Time": "02:50.2",
      "Stage 1 - 10 Min  Pos": 8,
      "Stage 2 - Brujos west Time": "03:11.1",
      "Stage 2 - Brujos west Pos": 8,
      "Stage 3 - Brujos blitz Time": "01:53.1",
      "Stage 3 - Brujos blitz Pos": 8,
      "Stage 4 - Broke Back  Time": "05:18.2",
      "Stage 4 - Broke Back  Pos": 10
    },
    {
      "RaceNumber": 9,
      "Name": "Efren Alvarado",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "13:29.1",
      "Position": 10,
      "Status": "",
      "BehindTime": "+3:17.55",
      "Stage 1 - 10 Min  Time": "02:47.6",
      "Stage 1 - 10 Min  Pos": 7,
      "Stage 2 - Brujos west Time": "03:19.7",
      "Stage 2 - Brujos west Pos": 10,
      "Stage 3 - Brujos blitz Time": "02:25.9",
      "Stage 3 - Brujos blitz Pos": 11,
      "Stage 4 - Broke Back  Time": "04:55.8",
      "Stage 4 - Broke Back  Pos": 8
    },
    {
      "RaceNumber": 51,
      "Name": "Guillermo Zaragoza ",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "17:58.7",
      "Position": 11,
      "Status": "",
      "BehindTime": "+7:47.17",
      "Stage 1 - 10 Min  Time": "03:40.2",
      "Stage 1 - 10 Min  Pos": 11,
      "Stage 2 - Brujos west Time": "03:24.9",
      "Stage 2 - Brujos west Pos": 11,
      "Stage 3 - Brujos blitz Time": "02:20.5",
      "Stage 3 - Brujos blitz Pos": 10,
      "Stage 4 - Broke Back  Time": "08:33.2",
      "Stage 4 - Broke Back  Pos": 11
    },
    {
      "RaceNumber": 23,
      "Name": "Edgar  Ramirez Jr",
      "Club": "The Shock Lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:54.7",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - 10 Min  Time": "02:01.0",
      "Stage 1 - 10 Min  Pos": 1,
      "Stage 2 - Brujos west Time": "01:39.7",
      "Stage 2 - Brujos west Pos": 1,
      "Stage 3 - Brujos blitz Time": "00:58.5",
      "Stage 3 - Brujos blitz Pos": 1,
      "Stage 4 - Broke Back  Time": "03:15.5",
      "Stage 4 - Broke Back  Pos": 1
    },
    {
      "RaceNumber": 17,
      "Name": "Mo Frias",
      "Club": "Middle of nowhere",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:17.3",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:22.60",
      "Stage 1 - 10 Min  Time": "02:09.4",
      "Stage 1 - 10 Min  Pos": 8,
      "Stage 2 - Brujos west Time": "01:42.2",
      "Stage 2 - Brujos west Pos": 2,
      "Stage 3 - Brujos blitz Time": "01:00.8",
      "Stage 3 - Brujos blitz Pos": 2,
      "Stage 4 - Broke Back  Time": "03:25.0",
      "Stage 4 - Broke Back  Pos": 3
    },
    {
      "RaceNumber": 12,
      "Name": "Sergio Armando Acosta Ortega",
      "Club": "Ciclismo Juarez",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:22.4",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:27.68",
      "Stage 1 - 10 Min  Time": "02:04.5",
      "Stage 1 - 10 Min  Pos": 2,
      "Stage 2 - Brujos west Time": "01:48.5",
      "Stage 2 - Brujos west Pos": 5,
      "Stage 3 - Brujos blitz Time": "01:01.8",
      "Stage 3 - Brujos blitz Pos": 4,
      "Stage 4 - Broke Back  Time": "03:27.7",
      "Stage 4 - Broke Back  Pos": 5
    },
    {
      "RaceNumber": 22,
      "Name": "Humberto Arevalo",
      "Club": "The Shock Lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:22.7",
      "Position": 4,
      "Status": "",
      "BehindTime": "+0:27.97",
      "Stage 1 - 10 Min  Time": "02:10.7",
      "Stage 1 - 10 Min  Pos": 10,
      "Stage 2 - Brujos west Time": "01:47.8",
      "Stage 2 - Brujos west Pos": 4,
      "Stage 3 - Brujos blitz Time": "01:01.1",
      "Stage 3 - Brujos blitz Pos": 3,
      "Stage 4 - Broke Back  Time": "03:23.2",
      "Stage 4 - Broke Back  Pos": 2
    },
    {
      "RaceNumber": 16,
      "Name": "Eric Sotomayor",
      "Club": "The Shock Lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:26.2",
      "Position": 5,
      "Status": "",
      "BehindTime": "+0:31.45",
      "Stage 1 - 10 Min  Time": "02:06.5",
      "Stage 1 - 10 Min  Pos": 4,
      "Stage 2 - Brujos west Time": "01:50.3",
      "Stage 2 - Brujos west Pos": 6,
      "Stage 3 - Brujos blitz Time": "01:03.1",
      "Stage 3 - Brujos blitz Pos": 6,
      "Stage 4 - Broke Back  Time": "03:26.3",
      "Stage 4 - Broke Back  Pos": 4
    },
    {
      "RaceNumber": 18,
      "Name": "Alexander Gutierrez",
      "Club": "One Trail At A Time",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:26.6",
      "Position": 6,
      "Status": "",
      "BehindTime": "+0:31.91",
      "Stage 1 - 10 Min  Time": "02:05.1",
      "Stage 1 - 10 Min  Pos": 3,
      "Stage 2 - Brujos west Time": "01:45.2",
      "Stage 2 - Brujos west Pos": 3,
      "Stage 3 - Brujos blitz Time": "01:04.5",
      "Stage 3 - Brujos blitz Pos": 8,
      "Stage 4 - Broke Back  Time": "03:31.9",
      "Stage 4 - Broke Back  Pos": 6
    },
    {
      "RaceNumber": 19,
      "Name": "Xavier Estala",
      "Club": "Desert Fox",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:35.9",
      "Position": 7,
      "Status": "",
      "BehindTime": "+0:41.13",
      "Stage 1 - 10 Min  Time": "02:07.6",
      "Stage 1 - 10 Min  Pos": 6,
      "Stage 2 - Brujos west Time": "01:52.8",
      "Stage 2 - Brujos west Pos": 7,
      "Stage 3 - Brujos blitz Time": "01:03.6",
      "Stage 3 - Brujos blitz Pos": 7,
      "Stage 4 - Broke Back  Time": "03:31.9",
      "Stage 4 - Broke Back  Pos": 7
    },
    {
      "RaceNumber": 15,
      "Name": "Joel lujan",
      "Club": "North Crew/ The shock lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:40.5",
      "Position": 8,
      "Status": "",
      "BehindTime": "+0:45.83",
      "Stage 1 - 10 Min  Time": "02:10.1",
      "Stage 1 - 10 Min  Pos": 9,
      "Stage 2 - Brujos west Time": "01:53.5",
      "Stage 2 - Brujos west Pos": 8,
      "Stage 3 - Brujos blitz Time": "01:02.8",
      "Stage 3 - Brujos blitz Pos": 5,
      "Stage 4 - Broke Back  Time": "03:34.2",
      "Stage 4 - Broke Back  Pos": 8
    },
    {
      "RaceNumber": 20,
      "Name": "Corey Jones",
      "Club": "Merol Racing",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:52.2",
      "Position": 9,
      "Status": "",
      "BehindTime": "+0:57.49",
      "Stage 1 - 10 Min  Time": "02:06.9",
      "Stage 1 - 10 Min  Pos": 5,
      "Stage 2 - Brujos west Time": "02:01.8",
      "Stage 2 - Brujos west Pos": 10,
      "Stage 3 - Brujos blitz Time": "01:09.2",
      "Stage 3 - Brujos blitz Pos": 10,
      "Stage 4 - Broke Back  Time": "03:34.3",
      "Stage 4 - Broke Back  Pos": 9
    },
    {
      "RaceNumber": 21,
      "Name": "Arnold Rodriguez",
      "Club": "Merol Racing/Chuco MTB",
      "CourseClass": "Men Expert Open",
      "RaceTime": "09:05.3",
      "Position": 10,
      "Status": "",
      "BehindTime": "+1:10.57",
      "Stage 1 - 10 Min  Time": "02:09.2",
      "Stage 1 - 10 Min  Pos": 7,
      "Stage 2 - Brujos west Time": "02:01.3",
      "Stage 2 - Brujos west Pos": 9,
      "Stage 3 - Brujos blitz Time": "01:08.1",
      "Stage 3 - Brujos blitz Pos": 9,
      "Stage 4 - Broke Back  Time": "03:46.8",
      "Stage 4 - Broke Back  Pos": 10
    },
    {
      "RaceNumber": 14,
      "Name": "Ricardo Granados",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "09:32.4",
      "Position": 11,
      "Status": "",
      "BehindTime": "+1:37.68",
      "Stage 1 - 10 Min  Time": "02:20.6",
      "Stage 1 - 10 Min  Pos": 12,
      "Stage 2 - Brujos west Time": "02:07.1",
      "Stage 2 - Brujos west Pos": 11,
      "Stage 3 - Brujos blitz Time": "01:16.2",
      "Stage 3 - Brujos blitz Pos": 11,
      "Stage 4 - Broke Back  Time": "03:48.5",
      "Stage 4 - Broke Back  Pos": 11
    },
    {
      "RaceNumber": 13,
      "Name": "Hunt Burdick",
      "Club": "Podium Pirate",
      "CourseClass": "Men Expert Open",
      "RaceTime": "09:37.2",
      "Position": 12,
      "Status": "",
      "BehindTime": "+1:42.51",
      "Stage 1 - 10 Min  Time": "02:14.1",
      "Stage 1 - 10 Min  Pos": 11,
      "Stage 2 - Brujos west Time": "02:14.8",
      "Stage 2 - Brujos west Pos": 12,
      "Stage 3 - Brujos blitz Time": "01:19.6",
      "Stage 3 - Brujos blitz Pos": 12,
      "Stage 4 - Broke Back  Time": "03:48.8",
      "Stage 4 - Broke Back  Pos": 12
    },
    {
      "RaceNumber": 31,
      "Name": "Luke Cutburth",
      "Club": "Lost Dog Chain Breakers",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:01.8",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - 10 Min  Time": "02:14.0",
      "Stage 1 - 10 Min  Pos": 1,
      "Stage 2 - Brujos west Time": "01:55.2",
      "Stage 2 - Brujos west Pos": 1,
      "Stage 3 - Brujos blitz Time": "01:18.4",
      "Stage 3 - Brujos blitz Pos": 7,
      "Stage 4 - Broke Back  Time": "03:34.2",
      "Stage 4 - Broke Back  Pos": 1
    },
    {
      "RaceNumber": 35,
      "Name": "Mark Nevarez",
      "Club": "NorthCrew/Merol Racing",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:21.5",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:19.73",
      "Stage 1 - 10 Min  Time": "02:21.3",
      "Stage 1 - 10 Min  Pos": 9,
      "Stage 2 - Brujos west Time": "02:04.5",
      "Stage 2 - Brujos west Pos": 2,
      "Stage 3 - Brujos blitz Time": "01:15.3",
      "Stage 3 - Brujos blitz Pos": 4,
      "Stage 4 - Broke Back  Time": "03:40.4",
      "Stage 4 - Broke Back  Pos": 3
    },
    {
      "RaceNumber": 39,
      "Name": "Pablo Campos",
      "Club": "Tabola",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:26.6",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:24.83",
      "Stage 1 - 10 Min  Time": "02:14.9",
      "Stage 1 - 10 Min  Pos": 2,
      "Stage 2 - Brujos west Time": "02:13.2",
      "Stage 2 - Brujos west Pos": 9,
      "Stage 3 - Brujos blitz Time": "01:15.1",
      "Stage 3 - Brujos blitz Pos": 3,
      "Stage 4 - Broke Back  Time": "03:43.4",
      "Stage 4 - Broke Back  Pos": 4
    },
    {
      "RaceNumber": 30,
      "Name": "Julio Reyes",
      "Club": "Mozketeroz",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:29.1",
      "Position": 4,
      "Status": "",
      "BehindTime": "+0:27.30",
      "Stage 1 - 10 Min  Time": "02:20.2",
      "Stage 1 - 10 Min  Pos": 8,
      "Stage 2 - Brujos west Time": "02:16.3",
      "Stage 2 - Brujos west Pos": 11,
      "Stage 3 - Brujos blitz Time": "01:15.5",
      "Stage 3 - Brujos blitz Pos": 5,
      "Stage 4 - Broke Back  Time": "03:37.2",
      "Stage 4 - Broke Back  Pos": 2
    },
    {
      "RaceNumber": 25,
      "Name": "Will Palafox",
      "Club": "Merol Racing/Dogspeed",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:31.8",
      "Position": 5,
      "Status": "",
      "BehindTime": "+0:30.04",
      "Stage 1 - 10 Min  Time": "02:17.2",
      "Stage 1 - 10 Min  Pos": 4,
      "Stage 2 - Brujos west Time": "02:09.9",
      "Stage 2 - Brujos west Pos": 6,
      "Stage 3 - Brujos blitz Time": "01:19.8",
      "Stage 3 - Brujos blitz Pos": 10,
      "Stage 4 - Broke Back  Time": "03:44.9",
      "Stage 4 - Broke Back  Pos": 5
    },
    {
      "RaceNumber": 29,
      "Name": "Efren Carranza",
      "Club": "The shock lab/NORTHCREW",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:43.0",
      "Position": 6,
      "Status": "",
      "BehindTime": "+0:41.28",
      "Stage 1 - 10 Min  Time": "02:28.3",
      "Stage 1 - 10 Min  Pos": 13,
      "Stage 2 - Brujos west Time": "02:04.7",
      "Stage 2 - Brujos west Pos": 3,
      "Stage 3 - Brujos blitz Time": "01:14.9",
      "Stage 3 - Brujos blitz Pos": 2,
      "Stage 4 - Broke Back  Time": "03:55.1",
      "Stage 4 - Broke Back  Pos": 8
    },
    {
      "RaceNumber": 38,
      "Name": "Octavio Gonzalez",
      "Club": "Embrace the Suck",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:46.2",
      "Position": 7,
      "Status": "",
      "BehindTime": "+0:44.42",
      "Stage 1 - 10 Min  Time": "02:21.3",
      "Stage 1 - 10 Min  Pos": 9,
      "Stage 2 - Brujos west Time": "02:09.7",
      "Stage 2 - Brujos west Pos": 5,
      "Stage 3 - Brujos blitz Time": "01:10.8",
      "Stage 3 - Brujos blitz Pos": 1,
      "Stage 4 - Broke Back  Time": "04:04.4",
      "Stage 4 - Broke Back  Pos": 11
    },
    {
      "RaceNumber": 32,
      "Name": "Marco Bueno",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:51.4",
      "Position": 8,
      "Status": "",
      "BehindTime": "+0:49.67",
      "Stage 1 - 10 Min  Time": "02:19.2",
      "Stage 1 - 10 Min  Pos": 6,
      "Stage 2 - Brujos west Time": "02:12.5",
      "Stage 2 - Brujos west Pos": 8,
      "Stage 3 - Brujos blitz Time": "01:17.5",
      "Stage 3 - Brujos blitz Pos": 6,
      "Stage 4 - Broke Back  Time": "04:02.2",
      "Stage 4 - Broke Back  Pos": 10
    },
    {
      "RaceNumber": 40,
      "Name": "Luis Gallegos",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:53.2",
      "Position": 9,
      "Status": "",
      "BehindTime": "+0:51.46",
      "Stage 1 - 10 Min  Time": "02:25.5",
      "Stage 1 - 10 Min  Pos": 12,
      "Stage 2 - Brujos west Time": "02:13.9",
      "Stage 2 - Brujos west Pos": 10,
      "Stage 3 - Brujos blitz Time": "01:19.4",
      "Stage 3 - Brujos blitz Pos": 9,
      "Stage 4 - Broke Back  Time": "03:54.4",
      "Stage 4 - Broke Back  Pos": 7
    },
    {
      "RaceNumber": 34,
      "Name": "Ylian Guzman",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "10:01.0",
      "Position": 10,
      "Status": "",
      "BehindTime": "+0:59.20",
      "Stage 1 - 10 Min  Time": "02:22.5",
      "Stage 1 - 10 Min  Pos": 11,
      "Stage 2 - Brujos west Time": "02:12.3",
      "Stage 2 - Brujos west Pos": 7,
      "Stage 3 - Brujos blitz Time": "01:19.3",
      "Stage 3 - Brujos blitz Pos": 8,
      "Stage 4 - Broke Back  Time": "04:06.9",
      "Stage 4 - Broke Back  Pos": 12
    },
    {
      "RaceNumber": 36,
      "Name": "Ezri Navarro",
      "Club": "Comperros",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "10:08.0",
      "Position": 11,
      "Status": "",
      "BehindTime": "+1:06.27",
      "Stage 1 - 10 Min  Time": "02:18.0",
      "Stage 1 - 10 Min  Pos": 5,
      "Stage 2 - Brujos west Time": "02:22.1",
      "Stage 2 - Brujos west Pos": 13,
      "Stage 3 - Brujos blitz Time": "01:28.1",
      "Stage 3 - Brujos blitz Pos": 13,
      "Stage 4 - Broke Back  Time": "03:59.8",
      "Stage 4 - Broke Back  Pos": 9
    },
    {
      "RaceNumber": 28,
      "Name": "Jake Gustin",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "10:18.6",
      "Position": 12,
      "Status": "",
      "BehindTime": "+1:16.88",
      "Stage 1 - 10 Min  Time": "02:35.2",
      "Stage 1 - 10 Min  Pos": 15,
      "Stage 2 - Brujos west Time": "02:27.0",
      "Stage 2 - Brujos west Pos": 14,
      "Stage 3 - Brujos blitz Time": "01:26.9",
      "Stage 3 - Brujos blitz Pos": 12,
      "Stage 4 - Broke Back  Time": "03:49.5",
      "Stage 4 - Broke Back  Pos": 6
    },
    {
      "RaceNumber": 26,
      "Name": "Arturo  Garcia",
      "Club": "Ciclismo Juarez",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "10:27.1",
      "Position": 13,
      "Status": "",
      "BehindTime": "+1:25.37",
      "Stage 1 - 10 Min  Time": "02:32.6",
      "Stage 1 - 10 Min  Pos": 14,
      "Stage 2 - Brujos west Time": "02:09.1",
      "Stage 2 - Brujos west Pos": 4,
      "Stage 3 - Brujos blitz Time": "01:20.3",
      "Stage 3 - Brujos blitz Pos": 11,
      "Stage 4 - Broke Back  Time": "04:25.2",
      "Stage 4 - Broke Back  Pos": 15
    },
    {
      "RaceNumber": 27,
      "Name": "Adam Becerra",
      "Club": "DOGSPEED",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "10:52.4",
      "Position": 14,
      "Status": "",
      "BehindTime": "+1:50.68",
      "Stage 1 - 10 Min  Time": "02:15.4",
      "Stage 1 - 10 Min  Pos": 3,
      "Stage 2 - Brujos west Time": "02:50.2",
      "Stage 2 - Brujos west Pos": 16,
      "Stage 3 - Brujos blitz Time": "01:31.3",
      "Stage 3 - Brujos blitz Pos": 15,
      "Stage 4 - Broke Back  Time": "04:15.6",
      "Stage 4 - Broke Back  Pos": 13
    },
    {
      "RaceNumber": 37,
      "Name": "Hector Murguia",
      "Club": "Comperros",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "11:21.9",
      "Position": 15,
      "Status": "",
      "BehindTime": "+2:20.16",
      "Stage 1 - 10 Min  Time": "02:58.2",
      "Stage 1 - 10 Min  Pos": 16,
      "Stage 2 - Brujos west Time": "02:30.9",
      "Stage 2 - Brujos west Pos": 15,
      "Stage 3 - Brujos blitz Time": "01:32.4",
      "Stage 3 - Brujos blitz Pos": 16,
      "Stage 4 - Broke Back  Time": "04:20.5",
      "Stage 4 - Broke Back  Pos": 14
    },
    {
      "RaceNumber": 42,
      "Name": "Aaron Crawford",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "15:41.4",
      "Position": 16,
      "Status": "",
      "BehindTime": "+6:39.61",
      "Stage 1 - 10 Min  Time": "02:19.7",
      "Stage 1 - 10 Min  Pos": 7,
      "Stage 2 - Brujos west Time": "02:21.0",
      "Stage 2 - Brujos west Pos": 12,
      "Stage 3 - Brujos blitz Time": "01:30.6",
      "Stage 3 - Brujos blitz Pos": 14,
      "Stage 4 - Broke Back  Time": "09:30.2",
      "Stage 4 - Broke Back  Pos": 17
    },
    {
      "RaceNumber": 41,
      "Name": "Rodrigo Castillo",
      "Club": "MIDDLE OF NOWHERE",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "18:18.1",
      "Position": 17,
      "Status": "",
      "BehindTime": "+9:16.33",
      "Stage 1 - 10 Min  Time": "04:07.6",
      "Stage 1 - 10 Min  Pos": 17,
      "Stage 2 - Brujos west Time": "05:08.6",
      "Stage 2 - Brujos west Pos": 17,
      "Stage 3 - Brujos blitz Time": "02:35.1",
      "Stage 3 - Brujos blitz Pos": 17,
      "Stage 4 - Broke Back  Time": "06:26.8",
      "Stage 4 - Broke Back  Pos": 16
    },
    {
      "RaceNumber": 48,
      "Name": "Lilly Sanchez",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "11:02.9",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - 10 Min  Time": "02:34.6",
      "Stage 1 - 10 Min  Pos": 1,
      "Stage 2 - Brujos west Time": "02:33.3",
      "Stage 2 - Brujos west Pos": 1,
      "Stage 3 - Brujos blitz Time": "01:25.5",
      "Stage 3 - Brujos blitz Pos": 1,
      "Stage 4 - Broke Back  Time": "04:29.5",
      "Stage 4 - Broke Back  Pos": 2
    },
    {
      "RaceNumber": 49,
      "Name": "Brissia Montalvo",
      "Club": "Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "11:05.1",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:02.20",
      "Stage 1 - 10 Min  Time": "02:40.8",
      "Stage 1 - 10 Min  Pos": 2,
      "Stage 2 - Brujos west Time": "02:33.9",
      "Stage 2 - Brujos west Pos": 2,
      "Stage 3 - Brujos blitz Time": "01:28.9",
      "Stage 3 - Brujos blitz Pos": 2,
      "Stage 4 - Broke Back  Time": "04:21.4",
      "Stage 4 - Broke Back  Pos": 1
    },
    {
      "RaceNumber": 50,
      "Name": "Genesis Garcia ",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "12:54.7",
      "Position": 3,
      "Status": "",
      "BehindTime": "+1:51.79",
      "Stage 1 - 10 Min  Time": "03:04.8",
      "Stage 1 - 10 Min  Pos": 4,
      "Stage 2 - Brujos west Time": "03:08.4",
      "Stage 2 - Brujos west Pos": 4,
      "Stage 3 - Brujos blitz Time": "01:51.1",
      "Stage 3 - Brujos blitz Pos": 4,
      "Stage 4 - Broke Back  Time": "04:50.4",
      "Stage 4 - Broke Back  Pos": 3
    },
    {
      "RaceNumber": 47,
      "Name": "Kara Samsel",
      "Club": "Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "14:38.8",
      "Position": 4,
      "Status": "",
      "BehindTime": "+3:35.89",
      "Stage 1 - 10 Min  Time": "02:41.1",
      "Stage 1 - 10 Min  Pos": 3,
      "Stage 2 - Brujos west Time": "02:40.0",
      "Stage 2 - Brujos west Pos": 3,
      "Stage 3 - Brujos blitz Time": "01:30.8",
      "Stage 3 - Brujos blitz Pos": 3,
      "Stage 4 - Broke Back  Time": "07:47.0",
      "Stage 4 - Broke Back  Pos": 7
    },
    {
      "RaceNumber": 43,
      "Name": "Oceana Nelson",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "14:44.3",
      "Position": 5,
      "Status": "",
      "BehindTime": "+3:41.38",
      "Stage 1 - 10 Min  Time": "03:24.0",
      "Stage 1 - 10 Min  Pos": 6,
      "Stage 2 - Brujos west Time": "03:39.6",
      "Stage 2 - Brujos west Pos": 5,
      "Stage 3 - Brujos blitz Time": "02:11.1",
      "Stage 3 - Brujos blitz Pos": 5,
      "Stage 4 - Broke Back  Time": "05:29.7",
      "Stage 4 - Broke Back  Pos": 5
    },
    {
      "RaceNumber": 44,
      "Name": "Sarah Forrestal",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "15:46.9",
      "Position": 6,
      "Status": "",
      "BehindTime": "+4:44.06",
      "Stage 1 - 10 Min  Time": "03:21.2",
      "Stage 1 - 10 Min  Pos": 5,
      "Stage 2 - Brujos west Time": "04:26.7",
      "Stage 2 - Brujos west Pos": 6,
      "Stage 3 - Brujos blitz Time": "02:30.5",
      "Stage 3 - Brujos blitz Pos": 6,
      "Stage 4 - Broke Back  Time": "05:28.6",
      "Stage 4 - Broke Back  Pos": 4
    },
    {
      "RaceNumber": 46,
      "Name": "Susie Edwinson",
      "Club": "Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "17:35.4",
      "Position": 7,
      "Status": "",
      "BehindTime": "+6:32.54",
      "Stage 1 - 10 Min  Time": "03:27.5",
      "Stage 1 - 10 Min  Pos": 7,
      "Stage 2 - Brujos west Time": "04:58.4",
      "Stage 2 - Brujos west Pos": 7,
      "Stage 3 - Brujos blitz Time": "03:09.9",
      "Stage 3 - Brujos blitz Pos": 7,
      "Stage 4 - Broke Back  Time": "05:59.7",
      "Stage 4 - Broke Back  Pos": 6
    },
    {
      "RaceNumber": 45,
      "Name": "Sophia Garcia",
      "Club": "Smaz remodeling",
      "CourseClass": "Women Open",
      "RaceTime": "31:46.2",
      "Position": 8,
      "Status": "",
      "BehindTime": "+20:43.31",
      "Stage 1 - 10 Min  Time": "04:55.0",
      "Stage 1 - 10 Min  Pos": 8,
      "Stage 2 - Brujos west Time": "08:48.3",
      "Stage 2 - Brujos west Pos": 8,
      "Stage 3 - Brujos blitz Time": "07:31.7",
      "Stage 3 - Brujos blitz Pos": 8,
      "Stage 4 - Broke Back  Time": "10:31.3",
      "Stage 4 - Broke Back  Pos": 8
    }
   ]

export default function franklin() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>DARKDURO 2022</h1>

            <table className="tableizer-table">
                <thead><tr className="tableizer-firstrow"><th>RaceNumber</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>Status</th><th>BehindTime</th><th>Stage 1 - 10 Min  Time</th><th>Stage 1 - 10 Min  Pos</th><th>Stage 2 - Brujos west Time</th><th>Stage 2 - Brujos west Pos</th><th>Stage 3 - Brujos blitz Time</th><th>Stage 3 - Brujos blitz Pos</th><th>Stage 4 - Broke Back  Time</th><th>Stage 4 - Broke Back  Pos</th></tr></thead><tbody>
                <tr><td>100</td><td>Manny Arellano</td><td>&nbsp;</td><td>Men 50+</td><td>&nbsp;</td><td>&nbsp;</td><td>DNF</td><td>&nbsp;</td><td>02:47.5</td><td>&nbsp;</td><td>02:36.2</td><td>&nbsp;</td><td>01:31.9</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>7</td><td>Christopher Beversdorf</td><td>TACOCAT</td><td>Men Beginner Open</td><td>10:11.5</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:24.6</td><td>3</td><td>02:24.2</td><td>2</td><td>01:20.4</td><td>2</td><td>04:02.3</td><td>2</td></tr>
                <tr><td>4</td><td>Rene De La O</td><td>&nbsp;</td><td>Men Beginner Open</td><td>10:25.7</td><td>2</td><td>&nbsp;</td><td>+0:14.12</td><td>02:20.6</td><td>2</td><td>02:30.9</td><td>3</td><td>01:21.2</td><td>3</td><td>04:12.9</td><td>3</td></tr>
                <tr><td>6</td><td>Wyatt Ivey</td><td>&nbsp;</td><td>Men Beginner Open</td><td>10:32.5</td><td>3</td><td>&nbsp;</td><td>+0:20.91</td><td>02:17.8</td><td>1</td><td>03:02.5</td><td>5</td><td>01:20.0</td><td>1</td><td>03:52.0</td><td>1</td></tr>
                <tr><td>10</td><td>Max Guerra</td><td>The Shocklab</td><td>Men Beginner Open</td><td>10:41.2</td><td>4</td><td>&nbsp;</td><td>+0:29.68</td><td>02:39.4</td><td>4</td><td>02:16.4</td><td>1</td><td>01:26.6</td><td>4</td><td>04:18.9</td><td>4</td></tr>
                <tr><td>8</td><td>Izzy Reyes</td><td>&nbsp;</td><td>Men Beginner Open</td><td>11:31.6</td><td>5</td><td>&nbsp;</td><td>+1:20.09</td><td>02:43.9</td><td>6</td><td>02:33.0</td><td>4</td><td>01:44.6</td><td>6</td><td>04:30.1</td><td>5</td></tr>
                <tr><td>11</td><td>Andre von Twistern</td><td>&nbsp;</td><td>Men Beginner Open</td><td>12:34.4</td><td>6</td><td>&nbsp;</td><td>+2:22.82</td><td>02:41.3</td><td>5</td><td>03:11.0</td><td>7</td><td>01:41.4</td><td>5</td><td>05:00.7</td><td>9</td></tr>
                <tr><td>3</td><td>Moises Teliz</td><td>Smaz remodeling</td><td>Men Beginner Open</td><td>12:47.7</td><td>7</td><td>&nbsp;</td><td>+2:36.21</td><td>02:59.2</td><td>10</td><td>03:06.6</td><td>6</td><td>01:48.0</td><td>7</td><td>04:54.0</td><td>6</td></tr>
                <tr><td>5</td><td>Jose Luis Ramirez</td><td>&nbsp;</td><td>Men Beginner Open</td><td>13:04.8</td><td>8</td><td>&nbsp;</td><td>+2:53.23</td><td>02:51.2</td><td>9</td><td>03:14.7</td><td>9</td><td>02:03.4</td><td>9</td><td>04:55.5</td><td>7</td></tr>
                <tr><td>1</td><td>David Peregrino</td><td>&nbsp;</td><td>Men Beginner Open</td><td>13:12.6</td><td>9</td><td>&nbsp;</td><td>+3:01.08</td><td>02:50.2</td><td>8</td><td>03:11.1</td><td>8</td><td>01:53.1</td><td>8</td><td>05:18.2</td><td>10</td></tr>
                <tr><td>9</td><td>Efren Alvarado</td><td>&nbsp;</td><td>Men Beginner Open</td><td>13:29.1</td><td>10</td><td>&nbsp;</td><td>+3:17.55</td><td>02:47.6</td><td>7</td><td>03:19.7</td><td>10</td><td>02:25.9</td><td>11</td><td>04:55.8</td><td>8</td></tr>
                <tr><td>51</td><td>Guillermo Zaragoza </td><td>&nbsp;</td><td>Men Beginner Open</td><td>17:58.7</td><td>11</td><td>&nbsp;</td><td>+7:47.17</td><td>03:40.2</td><td>11</td><td>03:24.9</td><td>11</td><td>02:20.5</td><td>10</td><td>08:33.2</td><td>11</td></tr>
                <tr><td>23</td><td>Edgar  Ramirez Jr</td><td>The Shock Lab</td><td>Men Expert Open</td><td>07:54.7</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:01.0</td><td>1</td><td>01:39.7</td><td>1</td><td>00:58.5</td><td>1</td><td>03:15.5</td><td>1</td></tr>
                <tr><td>17</td><td>Mo Frias</td><td>Middle of nowhere</td><td>Men Expert Open</td><td>08:17.3</td><td>2</td><td>&nbsp;</td><td>+0:22.60</td><td>02:09.4</td><td>8</td><td>01:42.2</td><td>2</td><td>01:00.8</td><td>2</td><td>03:25.0</td><td>3</td></tr>
                <tr><td>12</td><td>Sergio Armando Acosta Ortega</td><td>Ciclismo Juarez</td><td>Men Expert Open</td><td>08:22.4</td><td>3</td><td>&nbsp;</td><td>+0:27.68</td><td>02:04.5</td><td>2</td><td>01:48.5</td><td>5</td><td>01:01.8</td><td>4</td><td>03:27.7</td><td>5</td></tr>
                <tr><td>22</td><td>Humberto Arevalo</td><td>The Shock Lab</td><td>Men Expert Open</td><td>08:22.7</td><td>4</td><td>&nbsp;</td><td>+0:27.97</td><td>02:10.7</td><td>10</td><td>01:47.8</td><td>4</td><td>01:01.1</td><td>3</td><td>03:23.2</td><td>2</td></tr>
                <tr><td>16</td><td>Eric Sotomayor</td><td>The Shock Lab</td><td>Men Expert Open</td><td>08:26.2</td><td>5</td><td>&nbsp;</td><td>+0:31.45</td><td>02:06.5</td><td>4</td><td>01:50.3</td><td>6</td><td>01:03.1</td><td>6</td><td>03:26.3</td><td>4</td></tr>
                <tr><td>18</td><td>Alexander Gutierrez</td><td>One Trail At A Time</td><td>Men Expert Open</td><td>08:26.6</td><td>6</td><td>&nbsp;</td><td>+0:31.91</td><td>02:05.1</td><td>3</td><td>01:45.2</td><td>3</td><td>01:04.5</td><td>8</td><td>03:31.9</td><td>6</td></tr>
                <tr><td>19</td><td>Xavier Estala</td><td>Desert Fox</td><td>Men Expert Open</td><td>08:35.9</td><td>7</td><td>&nbsp;</td><td>+0:41.13</td><td>02:07.6</td><td>6</td><td>01:52.8</td><td>7</td><td>01:03.6</td><td>7</td><td>03:31.9</td><td>7</td></tr>
                <tr><td>15</td><td>Joel lujan</td><td>North Crew/ The shock lab</td><td>Men Expert Open</td><td>08:40.5</td><td>8</td><td>&nbsp;</td><td>+0:45.83</td><td>02:10.1</td><td>9</td><td>01:53.5</td><td>8</td><td>01:02.8</td><td>5</td><td>03:34.2</td><td>8</td></tr>
                <tr><td>20</td><td>Corey Jones</td><td>Merol Racing</td><td>Men Expert Open</td><td>08:52.2</td><td>9</td><td>&nbsp;</td><td>+0:57.49</td><td>02:06.9</td><td>5</td><td>02:01.8</td><td>10</td><td>01:09.2</td><td>10</td><td>03:34.3</td><td>9</td></tr>
                <tr><td>21</td><td>Arnold Rodriguez</td><td>Merol Racing/Chuco MTB</td><td>Men Expert Open</td><td>09:05.3</td><td>10</td><td>&nbsp;</td><td>+1:10.57</td><td>02:09.2</td><td>7</td><td>02:01.3</td><td>9</td><td>01:08.1</td><td>9</td><td>03:46.8</td><td>10</td></tr>
                <tr><td>14</td><td>Ricardo Granados</td><td>&nbsp;</td><td>Men Expert Open</td><td>09:32.4</td><td>11</td><td>&nbsp;</td><td>+1:37.68</td><td>02:20.6</td><td>12</td><td>02:07.1</td><td>11</td><td>01:16.2</td><td>11</td><td>03:48.5</td><td>11</td></tr>
                <tr><td>13</td><td>Hunt Burdick</td><td>Podium Pirate</td><td>Men Expert Open</td><td>09:37.2</td><td>12</td><td>&nbsp;</td><td>+1:42.51</td><td>02:14.1</td><td>11</td><td>02:14.8</td><td>12</td><td>01:19.6</td><td>12</td><td>03:48.8</td><td>12</td></tr>
                <tr><td>31</td><td>Luke Cutburth</td><td>Lost Dog Chain Breakers</td><td>Men Intermediate Open</td><td>09:01.8</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:14.0</td><td>1</td><td>01:55.2</td><td>1</td><td>01:18.4</td><td>7</td><td>03:34.2</td><td>1</td></tr>
                <tr><td>35</td><td>Mark Nevarez</td><td>NorthCrew/Merol Racing</td><td>Men Intermediate Open</td><td>09:21.5</td><td>2</td><td>&nbsp;</td><td>+0:19.73</td><td>02:21.3</td><td>9</td><td>02:04.5</td><td>2</td><td>01:15.3</td><td>4</td><td>03:40.4</td><td>3</td></tr>
                <tr><td>39</td><td>Pablo Campos</td><td>Tabola</td><td>Men Intermediate Open</td><td>09:26.6</td><td>3</td><td>&nbsp;</td><td>+0:24.83</td><td>02:14.9</td><td>2</td><td>02:13.2</td><td>9</td><td>01:15.1</td><td>3</td><td>03:43.4</td><td>4</td></tr>
                <tr><td>30</td><td>Julio Reyes</td><td>Mozketeroz</td><td>Men Intermediate Open</td><td>09:29.1</td><td>4</td><td>&nbsp;</td><td>+0:27.30</td><td>02:20.2</td><td>8</td><td>02:16.3</td><td>11</td><td>01:15.5</td><td>5</td><td>03:37.2</td><td>2</td></tr>
                <tr><td>25</td><td>Will Palafox</td><td>Merol Racing/Dogspeed</td><td>Men Intermediate Open</td><td>09:31.8</td><td>5</td><td>&nbsp;</td><td>+0:30.04</td><td>02:17.2</td><td>4</td><td>02:09.9</td><td>6</td><td>01:19.8</td><td>10</td><td>03:44.9</td><td>5</td></tr>
                <tr><td>29</td><td>Efren Carranza</td><td>The shock lab/NORTHCREW</td><td>Men Intermediate Open</td><td>09:43.0</td><td>6</td><td>&nbsp;</td><td>+0:41.28</td><td>02:28.3</td><td>13</td><td>02:04.7</td><td>3</td><td>01:14.9</td><td>2</td><td>03:55.1</td><td>8</td></tr>
                <tr><td>38</td><td>Octavio Gonzalez</td><td>Embrace the Suck</td><td>Men Intermediate Open</td><td>09:46.2</td><td>7</td><td>&nbsp;</td><td>+0:44.42</td><td>02:21.3</td><td>9</td><td>02:09.7</td><td>5</td><td>01:10.8</td><td>1</td><td>04:04.4</td><td>11</td></tr>
                <tr><td>32</td><td>Marco Bueno</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>09:51.4</td><td>8</td><td>&nbsp;</td><td>+0:49.67</td><td>02:19.2</td><td>6</td><td>02:12.5</td><td>8</td><td>01:17.5</td><td>6</td><td>04:02.2</td><td>10</td></tr>
                <tr><td>40</td><td>Luis Gallegos</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>09:53.2</td><td>9</td><td>&nbsp;</td><td>+0:51.46</td><td>02:25.5</td><td>12</td><td>02:13.9</td><td>10</td><td>01:19.4</td><td>9</td><td>03:54.4</td><td>7</td></tr>
                <tr><td>34</td><td>Ylian Guzman</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>10:01.0</td><td>10</td><td>&nbsp;</td><td>+0:59.20</td><td>02:22.5</td><td>11</td><td>02:12.3</td><td>7</td><td>01:19.3</td><td>8</td><td>04:06.9</td><td>12</td></tr>
                <tr><td>36</td><td>Ezri Navarro</td><td>Comperros</td><td>Men Intermediate Open</td><td>10:08.0</td><td>11</td><td>&nbsp;</td><td>+1:06.27</td><td>02:18.0</td><td>5</td><td>02:22.1</td><td>13</td><td>01:28.1</td><td>13</td><td>03:59.8</td><td>9</td></tr>
                <tr><td>28</td><td>Jake Gustin</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>10:18.6</td><td>12</td><td>&nbsp;</td><td>+1:16.88</td><td>02:35.2</td><td>15</td><td>02:27.0</td><td>14</td><td>01:26.9</td><td>12</td><td>03:49.5</td><td>6</td></tr>
                <tr><td>26</td><td>Arturo  Garcia</td><td>Ciclismo Juarez</td><td>Men Intermediate Open</td><td>10:27.1</td><td>13</td><td>&nbsp;</td><td>+1:25.37</td><td>02:32.6</td><td>14</td><td>02:09.1</td><td>4</td><td>01:20.3</td><td>11</td><td>04:25.2</td><td>15</td></tr>
                <tr><td>27</td><td>Adam Becerra</td><td>DOGSPEED</td><td>Men Intermediate Open</td><td>10:52.4</td><td>14</td><td>&nbsp;</td><td>+1:50.68</td><td>02:15.4</td><td>3</td><td>02:50.2</td><td>16</td><td>01:31.3</td><td>15</td><td>04:15.6</td><td>13</td></tr>
                <tr><td>37</td><td>Hector Murguia</td><td>Comperros</td><td>Men Intermediate Open</td><td>11:21.9</td><td>15</td><td>&nbsp;</td><td>+2:20.16</td><td>02:58.2</td><td>16</td><td>02:30.9</td><td>15</td><td>01:32.4</td><td>16</td><td>04:20.5</td><td>14</td></tr>
                <tr><td>42</td><td>Aaron Crawford</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>15:41.4</td><td>16</td><td>&nbsp;</td><td>+6:39.61</td><td>02:19.7</td><td>7</td><td>02:21.0</td><td>12</td><td>01:30.6</td><td>14</td><td>09:30.2</td><td>17</td></tr>
                <tr><td>41</td><td>Rodrigo Castillo</td><td>MIDDLE OF NOWHERE</td><td>Men Intermediate Open</td><td>18:18.1</td><td>17</td><td>&nbsp;</td><td>+9:16.33</td><td>04:07.6</td><td>17</td><td>05:08.6</td><td>17</td><td>02:35.1</td><td>17</td><td>06:26.8</td><td>16</td></tr>
                <tr><td>48</td><td>Lilly Sanchez</td><td>&nbsp;</td><td>Women Open</td><td>11:02.9</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:34.6</td><td>1</td><td>02:33.3</td><td>1</td><td>01:25.5</td><td>1</td><td>04:29.5</td><td>2</td></tr>
                <tr><td>49</td><td>Brissia Montalvo</td><td>Podium Finish</td><td>Women Open</td><td>11:05.1</td><td>2</td><td>&nbsp;</td><td>+0:02.20</td><td>02:40.8</td><td>2</td><td>02:33.9</td><td>2</td><td>01:28.9</td><td>2</td><td>04:21.4</td><td>1</td></tr>
                <tr><td>50</td><td>Genesis Garcia </td><td>&nbsp;</td><td>Women Open</td><td>12:54.7</td><td>3</td><td>&nbsp;</td><td>+1:51.79</td><td>03:04.8</td><td>4</td><td>03:08.4</td><td>4</td><td>01:51.1</td><td>4</td><td>04:50.4</td><td>3</td></tr>
                <tr><td>47</td><td>Kara Samsel</td><td>Podium Finish</td><td>Women Open</td><td>14:38.8</td><td>4</td><td>&nbsp;</td><td>+3:35.89</td><td>02:41.1</td><td>3</td><td>02:40.0</td><td>3</td><td>01:30.8</td><td>3</td><td>07:47.0</td><td>7</td></tr>
                <tr><td>43</td><td>Oceana Nelson</td><td>&nbsp;</td><td>Women Open</td><td>14:44.3</td><td>5</td><td>&nbsp;</td><td>+3:41.38</td><td>03:24.0</td><td>6</td><td>03:39.6</td><td>5</td><td>02:11.1</td><td>5</td><td>05:29.7</td><td>5</td></tr>
                <tr><td>44</td><td>Sarah Forrestal</td><td>&nbsp;</td><td>Women Open</td><td>15:46.9</td><td>6</td><td>&nbsp;</td><td>+4:44.06</td><td>03:21.2</td><td>5</td><td>04:26.7</td><td>6</td><td>02:30.5</td><td>6</td><td>05:28.6</td><td>4</td></tr>
                <tr><td>46</td><td>Susie Edwinson</td><td>Podium Finish</td><td>Women Open</td><td>17:35.4</td><td>7</td><td>&nbsp;</td><td>+6:32.54</td><td>03:27.5</td><td>7</td><td>04:58.4</td><td>7</td><td>03:09.9</td><td>7</td><td>05:59.7</td><td>6</td></tr>
                <tr><td>45</td><td>Sophia Garcia</td><td>Smaz remodeling</td><td>Women Open</td><td>31:46.2</td><td>8</td><td>&nbsp;</td><td>+20:43.31</td><td>04:55.0</td><td>8</td><td>08:48.3</td><td>8</td><td>07:31.7</td><td>8</td><td>10:31.3</td><td>8</td></tr>
            </tbody></table>
            <CSVLink className="downloadLink" data={csvDataLDNIGHT2022}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
