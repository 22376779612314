import React from 'react'
import './monks_2022.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvData = [
    {
      "RaceNumber": 25,
      "CardNumbers": 8032670,
      "Name": "Octavio Gonzalez",
      "Club": "Embrace the Suck",
      "CourseClass": "Men 50+",
      "RaceTime": "07:30.2",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:10.8",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:31.6",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "02:06.3",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:16.5",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:24.9",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 21,
      "CardNumbers": 8143980,
      "Name": "Todd Ryden",
      "Club": "",
      "CourseClass": "Men 50+",
      "RaceTime": "10:37.0",
      "Position": 2,
      "Behind Time": "+3:06.79",
      "Stage 1 - Anna's Plunge  Time": "01:39.3",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "02:18.3",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "02:49.4",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "02:02.0",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:48.0",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 23,
      "CardNumbers": 8143982,
      "Name": "David Peregrino",
      "Club": "Team C.H.O.A.D.",
      "CourseClass": "Men 50+",
      "RaceTime": "11:43.2",
      "Position": 3,
      "Behind Time": "+4:12.98",
      "Stage 1 - Anna's Plunge  Time": "01:56.8",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "02:24.3",
      "Stage 2 - Hiss & Claw  Pos": 4,
      "Stage 3 - Upper Monks Time": "03:11.4",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "02:13.1",
      "Stage 4 -  Black Cat Pos": 4,
      "Stage 5 - Lower Monks  Time": "01:57.5",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 22,
      "CardNumbers": 8143956,
      "Name": "Sergio Pachecho",
      "Club": "SNMTB",
      "CourseClass": "Men 50+",
      "RaceTime": "21:53.6",
      "Position": 4,
      "Behind Time": "+14:23.46",
      "Stage 1 - Anna's Plunge  Time": "02:14.2",
      "Stage 1 - Anna's Plunge  Pos": 4,
      "Stage 2 - Hiss & Claw  Time": "02:08.4",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "03:11.5",
      "Stage 3 - Upper Monks Pos": 4,
      "Stage 4 -  Black Cat Time": "01:57.9",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "12:21.6",
      "Stage 5 - Lower Monks  Pos": 4
    },
    {
      "RaceNumber": 63,
      "CardNumbers": 8032649,
      "Name": "Max Guerra",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "07:50.5",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:10.8",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "01:40.2",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "02:11.5",
      "Stage 3 - Upper Monks Pos": 4,
      "Stage 4 -  Black Cat Time": "01:20.4",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "01:27.6",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 29,
      "CardNumbers": 8143971,
      "Name": "Rene De La O",
      "Club": "Podium Finish",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "07:55.7",
      "Position": 2,
      "Behind Time": "+0:05.13",
      "Stage 1 - Anna's Plunge  Time": "01:14.0",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "01:47.1",
      "Stage 2 - Hiss & Claw  Pos": 5,
      "Stage 3 - Upper Monks Time": "02:03.3",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:26.9",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:24.4",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 62,
      "CardNumbers": 8032640,
      "Name": "Wyatt Ivey",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:07.3",
      "Position": 3,
      "Behind Time": "+0:16.83",
      "Stage 1 - Anna's Plunge  Time": "01:06.7",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:31.4",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "02:48.2",
      "Stage 3 - Upper Monks Pos": 10,
      "Stage 4 -  Black Cat Time": "01:18.2",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:22.8",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 27,
      "CardNumbers": 8143975,
      "Name": "Eduardo Herrera",
      "Club": "Smoothie King",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:11.0",
      "Position": 4,
      "Behind Time": "+0:20.51",
      "Stage 1 - Anna's Plunge  Time": "01:16.3",
      "Stage 1 - Anna's Plunge  Pos": 4,
      "Stage 2 - Hiss & Claw  Time": "01:42.8",
      "Stage 2 - Hiss & Claw  Pos": 4,
      "Stage 3 - Upper Monks Time": "02:08.1",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "01:29.0",
      "Stage 4 -  Black Cat Pos": 5,
      "Stage 5 - Lower Monks  Time": "01:34.9",
      "Stage 5 - Lower Monks  Pos": 5
    },
    {
      "RaceNumber": 26,
      "CardNumbers": 8143978,
      "Name": "Jesus  Corral",
      "Club": "Smoothie King",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:15.0",
      "Position": 5,
      "Behind Time": "+0:24.51",
      "Stage 1 - Anna's Plunge  Time": "01:23.3",
      "Stage 1 - Anna's Plunge  Pos": 6,
      "Stage 2 - Hiss & Claw  Time": "01:41.1",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "02:10.7",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "01:27.6",
      "Stage 4 -  Black Cat Pos": 4,
      "Stage 5 - Lower Monks  Time": "01:32.4",
      "Stage 5 - Lower Monks  Pos": 4
    },
    {
      "RaceNumber": 34,
      "CardNumbers": 8143955,
      "Name": "Moises Teliz",
      "Club": "Smaz Remodeling",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:51.1",
      "Position": 6,
      "Behind Time": "+1:00.61",
      "Stage 1 - Anna's Plunge  Time": "01:21.0",
      "Stage 1 - Anna's Plunge  Pos": 5,
      "Stage 2 - Hiss & Claw  Time": "01:53.3",
      "Stage 2 - Hiss & Claw  Pos": 7,
      "Stage 3 - Upper Monks Time": "02:22.7",
      "Stage 3 - Upper Monks Pos": 6,
      "Stage 4 -  Black Cat Time": "01:38.5",
      "Stage 4 -  Black Cat Pos": 6,
      "Stage 5 - Lower Monks  Time": "01:35.7",
      "Stage 5 - Lower Monks  Pos": 6
    },
    {
      "RaceNumber": 32,
      "CardNumbers": 8032672,
      "Name": "Andre von Twistern",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "09:05.7",
      "Position": 7,
      "Behind Time": "+1:15.17",
      "Stage 1 - Anna's Plunge  Time": "01:38.0",
      "Stage 1 - Anna's Plunge  Pos": 9,
      "Stage 2 - Hiss & Claw  Time": "01:47.7",
      "Stage 2 - Hiss & Claw  Pos": 6,
      "Stage 3 - Upper Monks Time": "02:20.7",
      "Stage 3 - Upper Monks Pos": 5,
      "Stage 4 -  Black Cat Time": "01:42.2",
      "Stage 4 -  Black Cat Pos": 7,
      "Stage 5 - Lower Monks  Time": "01:37.2",
      "Stage 5 - Lower Monks  Pos": 7
    },
    {
      "RaceNumber": 35,
      "CardNumbers": 8032632,
      "Name": "Izzy Reyes",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "09:35.7",
      "Position": 8,
      "Behind Time": "+1:45.16",
      "Stage 1 - Anna's Plunge  Time": "01:27.6",
      "Stage 1 - Anna's Plunge  Pos": 7,
      "Stage 2 - Hiss & Claw  Time": "02:00.2",
      "Stage 2 - Hiss & Claw  Pos": 8,
      "Stage 3 - Upper Monks Time": "02:37.0",
      "Stage 3 - Upper Monks Pos": 7,
      "Stage 4 -  Black Cat Time": "01:49.4",
      "Stage 4 -  Black Cat Pos": 8,
      "Stage 5 - Lower Monks  Time": "01:41.5",
      "Stage 5 - Lower Monks  Pos": 8
    },
    {
      "RaceNumber": 33,
      "CardNumbers": 8032615,
      "Name": "Efren Alvarado",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:10.0",
      "Position": 9,
      "Behind Time": "+2:19.49",
      "Stage 1 - Anna's Plunge  Time": "01:36.9",
      "Stage 1 - Anna's Plunge  Pos": 8,
      "Stage 2 - Hiss & Claw  Time": "02:13.9",
      "Stage 2 - Hiss & Claw  Pos": 10,
      "Stage 3 - Upper Monks Time": "02:41.2",
      "Stage 3 - Upper Monks Pos": 8,
      "Stage 4 -  Black Cat Time": "01:51.0",
      "Stage 4 -  Black Cat Pos": 9,
      "Stage 5 - Lower Monks  Time": "01:47.0",
      "Stage 5 - Lower Monks  Pos": 10
    },
    {
      "RaceNumber": 28,
      "CardNumbers": 8032668,
      "Name": "Lalo Garc�a",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "10:23.0",
      "Position": 10,
      "Behind Time": "+2:32.52",
      "Stage 1 - Anna's Plunge  Time": "01:44.1",
      "Stage 1 - Anna's Plunge  Pos": 10,
      "Stage 2 - Hiss & Claw  Time": "02:06.7",
      "Stage 2 - Hiss & Claw  Pos": 9,
      "Stage 3 - Upper Monks Time": "02:42.5",
      "Stage 3 - Upper Monks Pos": 9,
      "Stage 4 -  Black Cat Time": "02:06.4",
      "Stage 4 -  Black Cat Pos": 10,
      "Stage 5 - Lower Monks  Time": "01:43.4",
      "Stage 5 - Lower Monks  Pos": 9
    },
    {
      "RaceNumber": 48,
      "CardNumbers": 8032657,
      "Name": "Myles Overstreet",
      "Club": "Dogspeed / Merol Racing / EP Enduro",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:00.7",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "00:52.1",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:13.8",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "01:43.7",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:01.3",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:09.8",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 40,
      "CardNumbers": 8032610,
      "Name": "Eric Sotomayor",
      "Club": "The Shock Lab/ NorthCrew",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:28.3",
      "Position": 2,
      "Behind Time": "+0:27.58",
      "Stage 1 - Anna's Plunge  Time": "00:58.2",
      "Stage 1 - Anna's Plunge  Pos": 4,
      "Stage 2 - Hiss & Claw  Time": "01:19.3",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "01:48.7",
      "Stage 3 - Upper Monks Pos": 4,
      "Stage 4 -  Black Cat Time": "01:08.2",
      "Stage 4 -  Black Cat Pos": 4,
      "Stage 5 - Lower Monks  Time": "01:14.0",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 41,
      "CardNumbers": 8032653,
      "Name": "Alexander Gutierrez",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:28.6",
      "Position": 3,
      "Behind Time": "+0:27.89",
      "Stage 1 - Anna's Plunge  Time": "00:55.0",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "01:22.8",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "01:47.5",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "01:08.6",
      "Stage 4 -  Black Cat Pos": 5,
      "Stage 5 - Lower Monks  Time": "01:14.6",
      "Stage 5 - Lower Monks  Pos": 4
    },
    {
      "RaceNumber": 46,
      "CardNumbers": 8143973,
      "Name": "Corey Jones",
      "Club": "Merol Racing / EP Enduro",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:36.2",
      "Position": 4,
      "Behind Time": "+0:35.52",
      "Stage 1 - Anna's Plunge  Time": "00:58.7",
      "Stage 1 - Anna's Plunge  Pos": 5,
      "Stage 2 - Hiss & Claw  Time": "01:24.5",
      "Stage 2 - Hiss & Claw  Pos": 6,
      "Stage 3 - Upper Monks Time": "01:52.3",
      "Stage 3 - Upper Monks Pos": 7,
      "Stage 4 -  Black Cat Time": "01:06.1",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:14.7",
      "Stage 5 - Lower Monks  Pos": 5
    },
    {
      "RaceNumber": 37,
      "CardNumbers": 8032637,
      "Name": "Jon Ordonez",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:39.1",
      "Position": 5,
      "Behind Time": "+0:38.40",
      "Stage 1 - Anna's Plunge  Time": "00:57.5",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "01:41.7",
      "Stage 2 - Hiss & Claw  Pos": 15,
      "Stage 3 - Upper Monks Time": "01:46.3",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "01:02.7",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "01:10.9",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 38,
      "CardNumbers": 8032643,
      "Name": "Joel lujan",
      "Club": "North Crew/ The shock lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:41.9",
      "Position": 6,
      "Behind Time": "+0:41.20",
      "Stage 1 - Anna's Plunge  Time": "01:00.2",
      "Stage 1 - Anna's Plunge  Pos": 8,
      "Stage 2 - Hiss & Claw  Time": "01:23.8",
      "Stage 2 - Hiss & Claw  Pos": 5,
      "Stage 3 - Upper Monks Time": "01:51.1",
      "Stage 3 - Upper Monks Pos": 5,
      "Stage 4 -  Black Cat Time": "01:10.7",
      "Stage 4 -  Black Cat Pos": 8,
      "Stage 5 - Lower Monks  Time": "01:16.2",
      "Stage 5 - Lower Monks  Pos": 6
    },
    {
      "RaceNumber": 39,
      "CardNumbers": 8032608,
      "Name": "Wayne Shaffer",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:42.5",
      "Position": 7,
      "Behind Time": "+0:41.78",
      "Stage 1 - Anna's Plunge  Time": "00:59.7",
      "Stage 1 - Anna's Plunge  Pos": 6,
      "Stage 2 - Hiss & Claw  Time": "01:22.9",
      "Stage 2 - Hiss & Claw  Pos": 4,
      "Stage 3 - Upper Monks Time": "01:51.4",
      "Stage 3 - Upper Monks Pos": 6,
      "Stage 4 -  Black Cat Time": "01:09.0",
      "Stage 4 -  Black Cat Pos": 6,
      "Stage 5 - Lower Monks  Time": "01:19.4",
      "Stage 5 - Lower Monks  Pos": 8
    },
    {
      "RaceNumber": 43,
      "CardNumbers": 8032612,
      "Name": "Luke Cutburth",
      "Club": "Rad Dog Bikes LDCB",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:55.6",
      "Position": 8,
      "Behind Time": "+0:54.94",
      "Stage 1 - Anna's Plunge  Time": "01:00.1",
      "Stage 1 - Anna's Plunge  Pos": 7,
      "Stage 2 - Hiss & Claw  Time": "01:24.8",
      "Stage 2 - Hiss & Claw  Pos": 7,
      "Stage 3 - Upper Monks Time": "01:55.1",
      "Stage 3 - Upper Monks Pos": 9,
      "Stage 4 -  Black Cat Time": "01:14.1",
      "Stage 4 -  Black Cat Pos": 9,
      "Stage 5 - Lower Monks  Time": "01:21.5",
      "Stage 5 - Lower Monks  Pos": 12
    },
    {
      "RaceNumber": 45,
      "CardNumbers": 8032662,
      "Name": "Michael Ordonez",
      "Club": "Loam society",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:00.2",
      "Position": 9,
      "Behind Time": "+0:59.52",
      "Stage 1 - Anna's Plunge  Time": "01:00.5",
      "Stage 1 - Anna's Plunge  Pos": 9,
      "Stage 2 - Hiss & Claw  Time": "01:29.4",
      "Stage 2 - Hiss & Claw  Pos": 10,
      "Stage 3 - Upper Monks Time": "01:59.4",
      "Stage 3 - Upper Monks Pos": 10,
      "Stage 4 -  Black Cat Time": "01:10.4",
      "Stage 4 -  Black Cat Pos": 7,
      "Stage 5 - Lower Monks  Time": "01:20.5",
      "Stage 5 - Lower Monks  Pos": 10
    },
    {
      "RaceNumber": 44,
      "CardNumbers": 8143966,
      "Name": "Hunt Burdick",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:17.4",
      "Position": 10,
      "Behind Time": "+1:16.73",
      "Stage 1 - Anna's Plunge  Time": "01:04.6",
      "Stage 1 - Anna's Plunge  Pos": 11,
      "Stage 2 - Hiss & Claw  Time": "01:31.1",
      "Stage 2 - Hiss & Claw  Pos": 12,
      "Stage 3 - Upper Monks Time": "02:04.3",
      "Stage 3 - Upper Monks Pos": 12,
      "Stage 4 -  Black Cat Time": "01:15.7",
      "Stage 4 -  Black Cat Pos": 11,
      "Stage 5 - Lower Monks  Time": "01:21.8",
      "Stage 5 - Lower Monks  Pos": 13
    },
    {
      "RaceNumber": 42,
      "CardNumbers": 8143954,
      "Name": "Clint Nelms",
      "Club": "Podium Finish",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:25.9",
      "Position": 11,
      "Behind Time": "+1:25.21",
      "Stage 1 - Anna's Plunge  Time": "01:04.2",
      "Stage 1 - Anna's Plunge  Pos": 10,
      "Stage 2 - Hiss & Claw  Time": "01:25.3",
      "Stage 2 - Hiss & Claw  Pos": 8,
      "Stage 3 - Upper Monks Time": "01:54.9",
      "Stage 3 - Upper Monks Pos": 8,
      "Stage 4 -  Black Cat Time": "01:40.3",
      "Stage 4 -  Black Cat Pos": 15,
      "Stage 5 - Lower Monks  Time": "01:21.3",
      "Stage 5 - Lower Monks  Pos": 11
    },
    {
      "RaceNumber": 36,
      "CardNumbers": 8143965,
      "Name": "Todge Cooper",
      "Club": "SHERPA,WTB,DVO,TRP,DEITY",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:29.7",
      "Position": 12,
      "Behind Time": "+1:29.04",
      "Stage 1 - Anna's Plunge  Time": "01:05.4",
      "Stage 1 - Anna's Plunge  Pos": 12,
      "Stage 2 - Hiss & Claw  Time": "01:30.7",
      "Stage 2 - Hiss & Claw  Pos": 11,
      "Stage 3 - Upper Monks Time": "02:10.7",
      "Stage 3 - Upper Monks Pos": 14,
      "Stage 4 -  Black Cat Time": "01:15.5",
      "Stage 4 -  Black Cat Pos": 10,
      "Stage 5 - Lower Monks  Time": "01:27.6",
      "Stage 5 - Lower Monks  Pos": 15
    },
    {
      "RaceNumber": 47,
      "CardNumbers": 8032665,
      "Name": "Antonio Escalera",
      "Club": "Ethykl Gear",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:39.9",
      "Position": 13,
      "Behind Time": "+1:39.22",
      "Stage 1 - Anna's Plunge  Time": "01:13.0",
      "Stage 1 - Anna's Plunge  Pos": 13,
      "Stage 2 - Hiss & Claw  Time": "01:37.7",
      "Stage 2 - Hiss & Claw  Pos": 13,
      "Stage 3 - Upper Monks Time": "02:03.6",
      "Stage 3 - Upper Monks Pos": 11,
      "Stage 4 -  Black Cat Time": "01:26.1",
      "Stage 4 -  Black Cat Pos": 14,
      "Stage 5 - Lower Monks  Time": "01:19.6",
      "Stage 5 - Lower Monks  Pos": 9
    },
    {
      "RaceNumber": 72,
      "CardNumbers": 8032651,
      "Name": "Luis Encerrado",
      "Club": "Crazy Cat / Handske",
      "CourseClass": "Men Expert Open",
      "RaceTime": "08:02.2",
      "Position": 14,
      "Behind Time": "+2:01.46",
      "Stage 1 - Anna's Plunge  Time": "01:26.6",
      "Stage 1 - Anna's Plunge  Pos": 15,
      "Stage 2 - Hiss & Claw  Time": "01:39.9",
      "Stage 2 - Hiss & Claw  Pos": 14,
      "Stage 3 - Upper Monks Time": "02:05.8",
      "Stage 3 - Upper Monks Pos": 13,
      "Stage 4 -  Black Cat Time": "01:22.7",
      "Stage 4 -  Black Cat Pos": 13,
      "Stage 5 - Lower Monks  Time": "01:27.3",
      "Stage 5 - Lower Monks  Pos": 14
    },
    {
      "RaceNumber": 79,
      "CardNumbers": 8143985,
      "Name": "Richie Sanchez",
      "Club": "Rad Dog Bikes",
      "CourseClass": "Men Expert Open",
      "RaceTime": "",
      "Position": "",
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:23.9",
      "Stage 1 - Anna's Plunge  Pos": 14,
      "Stage 2 - Hiss & Claw  Time": "01:29.2",
      "Stage 2 - Hiss & Claw  Pos": 9,
      "Stage 3 - Upper Monks Time": "",
      "Stage 3 - Upper Monks Pos": "",
      "Stage 4 -  Black Cat Time": "01:17.3",
      "Stage 4 -  Black Cat Pos": 12,
      "Stage 5 - Lower Monks  Time": "01:18.1",
      "Stage 5 - Lower Monks  Pos": 7
    },
    {
      "RaceNumber": 56,
      "CardNumbers": 8032631,
      "Name": "Isaac Chavez",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:15.3",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:09.7",
      "Stage 1 - Anna's Plunge  Pos": 7,
      "Stage 2 - Hiss & Claw  Time": "01:31.9",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "01:57.1",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:15.1",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "01:21.5",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 50,
      "CardNumbers": 8032656,
      "Name": "Justin Rose",
      "Club": "ROSE RACING",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:20.9",
      "Position": 2,
      "Behind Time": "+0:05.63",
      "Stage 1 - Anna's Plunge  Time": "01:06.5",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "01:35.0",
      "Stage 2 - Hiss & Claw  Pos": 6,
      "Stage 3 - Upper Monks Time": "01:59.7",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "01:15.1",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:24.7",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 53,
      "CardNumbers": 8032609,
      "Name": "Efren Carranza",
      "Club": "The shock lab/NORTHCREW",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:34.3",
      "Position": 3,
      "Behind Time": "+0:18.99",
      "Stage 1 - Anna's Plunge  Time": "01:06.5",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "01:33.0",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "02:04.1",
      "Stage 3 - Upper Monks Pos": 4,
      "Stage 4 -  Black Cat Time": "01:21.0",
      "Stage 4 -  Black Cat Pos": 4,
      "Stage 5 - Lower Monks  Time": "01:29.7",
      "Stage 5 - Lower Monks  Pos": 8
    },
    {
      "RaceNumber": 52,
      "CardNumbers": 8032674,
      "Name": "Adam Becerra",
      "Club": "Dog Speed / Crazy Cat",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:35.0",
      "Position": 4,
      "Behind Time": "+0:19.70",
      "Stage 1 - Anna's Plunge  Time": "01:09.0",
      "Stage 1 - Anna's Plunge  Pos": 5,
      "Stage 2 - Hiss & Claw  Time": "01:33.1",
      "Stage 2 - Hiss & Claw  Pos": 4,
      "Stage 3 - Upper Monks Time": "02:02.2",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "01:25.6",
      "Stage 4 -  Black Cat Pos": 8,
      "Stage 5 - Lower Monks  Time": "01:25.2",
      "Stage 5 - Lower Monks  Pos": 5
    },
    {
      "RaceNumber": 54,
      "CardNumbers": 8032616,
      "Name": "Mark Nevarez",
      "Club": "NorthCrew/EPEnduro/MerolRacing",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:36.0",
      "Position": 5,
      "Behind Time": "+0:20.68",
      "Stage 1 - Anna's Plunge  Time": "01:09.6",
      "Stage 1 - Anna's Plunge  Pos": 6,
      "Stage 2 - Hiss & Claw  Time": "01:33.4",
      "Stage 2 - Hiss & Claw  Pos": 5,
      "Stage 3 - Upper Monks Time": "02:04.6",
      "Stage 3 - Upper Monks Pos": 5,
      "Stage 4 -  Black Cat Time": "01:21.6",
      "Stage 4 -  Black Cat Pos": 5,
      "Stage 5 - Lower Monks  Time": "01:26.7",
      "Stage 5 - Lower Monks  Pos": 6
    },
    {
      "RaceNumber": 58,
      "CardNumbers": 8032659,
      "Name": "RENE OINEZA",
      "Club": "Southern New Mexico MTB (SNMTB)",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:50.1",
      "Position": 6,
      "Behind Time": "+0:34.77",
      "Stage 1 - Anna's Plunge  Time": "01:07.5",
      "Stage 1 - Anna's Plunge  Pos": 4,
      "Stage 2 - Hiss & Claw  Time": "01:36.6",
      "Stage 2 - Hiss & Claw  Pos": 7,
      "Stage 3 - Upper Monks Time": "02:09.2",
      "Stage 3 - Upper Monks Pos": 7,
      "Stage 4 -  Black Cat Time": "01:27.1",
      "Stage 4 -  Black Cat Pos": 9,
      "Stage 5 - Lower Monks  Time": "01:29.7",
      "Stage 5 - Lower Monks  Pos": 9
    },
    {
      "RaceNumber": 51,
      "CardNumbers": 8032666,
      "Name": "Julio Reyes",
      "Club": "Mozketeroz",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:51.0",
      "Position": 7,
      "Behind Time": "+0:35.75",
      "Stage 1 - Anna's Plunge  Time": "01:13.4",
      "Stage 1 - Anna's Plunge  Pos": 9,
      "Stage 2 - Hiss & Claw  Time": "01:40.0",
      "Stage 2 - Hiss & Claw  Pos": 8,
      "Stage 3 - Upper Monks Time": "02:09.0",
      "Stage 3 - Upper Monks Pos": 6,
      "Stage 4 -  Black Cat Time": "01:23.7",
      "Stage 4 -  Black Cat Pos": 6,
      "Stage 5 - Lower Monks  Time": "01:24.9",
      "Stage 5 - Lower Monks  Pos": 4
    },
    {
      "RaceNumber": 55,
      "CardNumbers": 8032614,
      "Name": "John Shredsalot",
      "Club": "NorthCrew/SNMTB",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "07:59.1",
      "Position": 8,
      "Behind Time": "+0:43.78",
      "Stage 1 - Anna's Plunge  Time": "01:06.0",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:28.9",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "02:44.0",
      "Stage 3 - Upper Monks Pos": 11,
      "Stage 4 -  Black Cat Time": "01:15.9",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:24.3",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 70,
      "CardNumbers": 8032638,
      "Name": "Kevin Dunn",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "08:05.5",
      "Position": 9,
      "Behind Time": "+0:50.20",
      "Stage 1 - Anna's Plunge  Time": "01:13.7",
      "Stage 1 - Anna's Plunge  Pos": 10,
      "Stage 2 - Hiss & Claw  Time": "01:49.0",
      "Stage 2 - Hiss & Claw  Pos": 11,
      "Stage 3 - Upper Monks Time": "02:11.2",
      "Stage 3 - Upper Monks Pos": 8,
      "Stage 4 -  Black Cat Time": "01:24.4",
      "Stage 4 -  Black Cat Pos": 7,
      "Stage 5 - Lower Monks  Time": "01:27.2",
      "Stage 5 - Lower Monks  Pos": 7
    },
    {
      "RaceNumber": 60,
      "CardNumbers": 8032652,
      "Name": "Josh Bell",
      "Club": "Podium Finish",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "08:40.6",
      "Position": 10,
      "Behind Time": "+1:25.35",
      "Stage 1 - Anna's Plunge  Time": "01:13.3",
      "Stage 1 - Anna's Plunge  Pos": 8,
      "Stage 2 - Hiss & Claw  Time": "01:47.7",
      "Stage 2 - Hiss & Claw  Pos": 9,
      "Stage 3 - Upper Monks Time": "02:20.3",
      "Stage 3 - Upper Monks Pos": 10,
      "Stage 4 -  Black Cat Time": "01:40.2",
      "Stage 4 -  Black Cat Pos": 10,
      "Stage 5 - Lower Monks  Time": "01:39.2",
      "Stage 5 - Lower Monks  Pos": 11
    },
    {
      "RaceNumber": 49,
      "CardNumbers": 8032611,
      "Name": "Luke James Tsoukalas",
      "Club": "Insanity Racing",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "08:52.0",
      "Position": 11,
      "Behind Time": "+1:36.71",
      "Stage 1 - Anna's Plunge  Time": "01:23.6",
      "Stage 1 - Anna's Plunge  Pos": 11,
      "Stage 2 - Hiss & Claw  Time": "01:48.6",
      "Stage 2 - Hiss & Claw  Pos": 10,
      "Stage 3 - Upper Monks Time": "02:19.9",
      "Stage 3 - Upper Monks Pos": 9,
      "Stage 4 -  Black Cat Time": "01:44.9",
      "Stage 4 -  Black Cat Pos": 11,
      "Stage 5 - Lower Monks  Time": "01:35.1",
      "Stage 5 - Lower Monks  Pos": 10
    },
    {
      "RaceNumber": 69,
      "CardNumbers": 8032676,
      "Name": "Will  Palafox",
      "Club": "Merol Racing/Ep Enduro",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "",
      "Position": "",
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "02:04.8",
      "Stage 1 - Anna's Plunge  Pos": 12,
      "Stage 2 - Hiss & Claw  Time": "",
      "Stage 2 - Hiss & Claw  Pos": "",
      "Stage 3 - Upper Monks Time": "",
      "Stage 3 - Upper Monks Pos": "",
      "Stage 4 -  Black Cat Time": "",
      "Stage 4 -  Black Cat Pos": "",
      "Stage 5 - Lower Monks  Time": "",
      "Stage 5 - Lower Monks  Pos": ""
    },
    {
      "RaceNumber": 61,
      "CardNumbers": 8032654,
      "Name": "Vaughn Ryden",
      "Club": "",
      "CourseClass": "U16 JR class",
      "RaceTime": "10:11.9",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:37.1",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:56.2",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "02:57.9",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:48.3",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:52.4",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 64,
      "CardNumbers": 8032635,
      "Name": "Maksim bevins",
      "Club": "",
      "CourseClass": "U16 JR class",
      "RaceTime": "10:31.5",
      "Position": 2,
      "Behind Time": "+0:19.59",
      "Stage 1 - Anna's Plunge  Time": "01:44.9",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "02:05.6",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "03:01.6",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "02:01.9",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:37.7",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 71,
      "CardNumbers": 8032613,
      "Name": "Myles Westbrook",
      "Club": "",
      "CourseClass": "U16 JR class",
      "RaceTime": "11:33.5",
      "Position": 3,
      "Behind Time": "+1:21.55",
      "Stage 1 - Anna's Plunge  Time": "01:45.7",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "02:12.8",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "03:32.4",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "02:01.2",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "02:01.4",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 67,
      "CardNumbers": 8032622,
      "Name": "Kara Samsel",
      "Club": "Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "09:01.8",
      "Position": 1,
      "Behind Time": "",
      "Stage 1 - Anna's Plunge  Time": "01:26.1",
      "Stage 1 - Anna's Plunge  Pos": 1,
      "Stage 2 - Hiss & Claw  Time": "01:47.3",
      "Stage 2 - Hiss & Claw  Pos": 1,
      "Stage 3 - Upper Monks Time": "02:23.5",
      "Stage 3 - Upper Monks Pos": 2,
      "Stage 4 -  Black Cat Time": "01:45.6",
      "Stage 4 -  Black Cat Pos": 1,
      "Stage 5 - Lower Monks  Time": "01:39.3",
      "Stage 5 - Lower Monks  Pos": 1
    },
    {
      "RaceNumber": 66,
      "CardNumbers": 8143968,
      "Name": "Lilly Sanchez",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "09:59.8",
      "Position": 2,
      "Behind Time": "00:58.0",
      "Stage 1 - Anna's Plunge  Time": "02:08.3",
      "Stage 1 - Anna's Plunge  Pos": 2,
      "Stage 2 - Hiss & Claw  Time": "01:56.8",
      "Stage 2 - Hiss & Claw  Pos": 2,
      "Stage 3 - Upper Monks Time": "02:21.9",
      "Stage 3 - Upper Monks Pos": 1,
      "Stage 4 -  Black Cat Time": "01:53.0",
      "Stage 4 -  Black Cat Pos": 2,
      "Stage 5 - Lower Monks  Time": "01:39.8",
      "Stage 5 - Lower Monks  Pos": 2
    },
    {
      "RaceNumber": 65,
      "CardNumbers": 8032677,
      "Name": "Oceana Nelson",
      "Club": "The Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "11:48.9",
      "Position": 3,
      "Behind Time": "+2:47.08",
      "Stage 1 - Anna's Plunge  Time": "02:16.3",
      "Stage 1 - Anna's Plunge  Pos": 3,
      "Stage 2 - Hiss & Claw  Time": "02:27.7",
      "Stage 2 - Hiss & Claw  Pos": 3,
      "Stage 3 - Upper Monks Time": "03:16.9",
      "Stage 3 - Upper Monks Pos": 3,
      "Stage 4 -  Black Cat Time": "01:54.6",
      "Stage 4 -  Black Cat Pos": 3,
      "Stage 5 - Lower Monks  Time": "01:53.3",
      "Stage 5 - Lower Monks  Pos": 3
    },
    {
      "RaceNumber": 68,
      "CardNumbers": 8032642,
      "Name": "Sophia Garcia",
      "Club": "Smaz remodeling",
      "CourseClass": "Women Open",
      "RaceTime": "34:43.8",
      "Position": 4,
      "Behind Time": "+25:42.00",
      "Stage 1 - Anna's Plunge  Time": "06:32.8",
      "Stage 1 - Anna's Plunge  Pos": 4,
      "Stage 2 - Hiss & Claw  Time": "07:12.7",
      "Stage 2 - Hiss & Claw  Pos": 4,
      "Stage 3 - Upper Monks Time": "06:55.9",
      "Stage 3 - Upper Monks Pos": 4,
      "Stage 4 -  Black Cat Time": "08:04.5",
      "Stage 4 -  Black Cat Pos": 4,
      "Stage 5 - Lower Monks  Time": "05:57.9",
      "Stage 5 - Lower Monks  Pos": 4
    }
  ]

export default function lost_dog_2022() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>Monks 2022</h1>
         
                <table class="tableizer-table">
<thead><tr class="tableizer-firstrow"><th>RaceNumber</th><th>CardNumbers</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>Behind Time</th><th>Stage 1 - Anna's Plunge  Time</th><th>Stage 1 - Anna's Plunge  Pos</th><th>Stage 2 - Hiss & Claw  Time</th><th>Stage 2 - Hiss & Claw  Pos</th><th>Stage 3 - Upper Monks Time</th><th>Stage 3 - Upper Monks Pos</th><th>Stage 4 -  Black Cat Time</th><th>Stage 4 -  Black Cat Pos</th><th>Stage 5 - Lower Monks  Time</th><th>Stage 5 - Lower Monks  Pos</th></tr></thead><tbody>
 <tr><td>25</td><td>8032670</td><td>Octavio Gonzalez</td><td>Embrace the Suck</td><td>Men 50+</td><td>07:30.2</td><td>1</td><td>&nbsp;</td><td>01:10.8</td><td>1</td><td>01:31.6</td><td>1</td><td>02:06.3</td><td>1</td><td>01:16.5</td><td>1</td><td>01:24.9</td><td>1</td></tr>
 <tr><td>21</td><td>8143980</td><td>Todd Ryden</td><td>&nbsp;</td><td>Men 50+</td><td>10:37.0</td><td>2</td><td>+3:06.79</td><td>01:39.3</td><td>2</td><td>02:18.3</td><td>3</td><td>02:49.4</td><td>2</td><td>02:02.0</td><td>3</td><td>01:48.0</td><td>2</td></tr>
 <tr><td>23</td><td>8143982</td><td>David Peregrino</td><td>Team C.H.O.A.D.</td><td>Men 50+</td><td>11:43.2</td><td>3</td><td>+4:12.98</td><td>01:56.8</td><td>3</td><td>02:24.3</td><td>4</td><td>03:11.4</td><td>3</td><td>02:13.1</td><td>4</td><td>01:57.5</td><td>3</td></tr>
 <tr><td>22</td><td>8143956</td><td>Sergio Pachecho </td><td>SNMTB</td><td>Men 50+</td><td>21:53.6</td><td>4</td><td>+14:23.46</td><td>02:14.2</td><td>4</td><td>02:08.4</td><td>2</td><td>03:11.5</td><td>4</td><td>01:57.9</td><td>2</td><td>12:21.6</td><td>4</td></tr>
 <tr><td>63</td><td>8032649</td><td>Max Guerra</td><td>&nbsp;</td><td>Men Beginner Open</td><td>07:50.5</td><td>1</td><td>&nbsp;</td><td>01:10.8</td><td>2</td><td>01:40.2</td><td>2</td><td>02:11.5</td><td>4</td><td>01:20.4</td><td>2</td><td>01:27.6</td><td>3</td></tr>
 <tr><td>29</td><td>8143971</td><td>Rene De La O</td><td>Podium Finish</td><td>Men Beginner Open</td><td>07:55.7</td><td>2</td><td>+0:05.13</td><td>01:14.0</td><td>3</td><td>01:47.1</td><td>5</td><td>02:03.3</td><td>1</td><td>01:26.9</td><td>3</td><td>01:24.4</td><td>2</td></tr>
 <tr><td>62</td><td>8032640</td><td>Wyatt Ivey</td><td>&nbsp;</td><td>Men Beginner Open</td><td>08:07.3</td><td>3</td><td>+0:16.83</td><td>01:06.7</td><td>1</td><td>01:31.4</td><td>1</td><td>02:48.2</td><td>10</td><td>01:18.2</td><td>1</td><td>01:22.8</td><td>1</td></tr>
 <tr><td>27</td><td>8143975</td><td>Eduardo Herrera</td><td>Smoothie King</td><td>Men Beginner Open</td><td>08:11.0</td><td>4</td><td>+0:20.51</td><td>01:16.3</td><td>4</td><td>01:42.8</td><td>4</td><td>02:08.1</td><td>2</td><td>01:29.0</td><td>5</td><td>01:34.9</td><td>5</td></tr>
 <tr><td>26</td><td>8143978</td><td>Jesus  Corral</td><td>Smoothie King</td><td>Men Beginner Open</td><td>08:15.0</td><td>5</td><td>+0:24.51</td><td>01:23.3</td><td>6</td><td>01:41.1</td><td>3</td><td>02:10.7</td><td>3</td><td>01:27.6</td><td>4</td><td>01:32.4</td><td>4</td></tr>
 <tr><td>34</td><td>8143955</td><td>Moises Teliz</td><td>Smaz Remodeling</td><td>Men Beginner Open</td><td>08:51.1</td><td>6</td><td>+1:00.61</td><td>01:21.0</td><td>5</td><td>01:53.3</td><td>7</td><td>02:22.7</td><td>6</td><td>01:38.5</td><td>6</td><td>01:35.7</td><td>6</td></tr>
 <tr><td>32</td><td>8032672</td><td>Andre von Twistern</td><td>&nbsp;</td><td>Men Beginner Open</td><td>09:05.7</td><td>7</td><td>+1:15.17</td><td>01:38.0</td><td>9</td><td>01:47.7</td><td>6</td><td>02:20.7</td><td>5</td><td>01:42.2</td><td>7</td><td>01:37.2</td><td>7</td></tr>
 <tr><td>35</td><td>8032632</td><td>Izzy Reyes</td><td>&nbsp;</td><td>Men Beginner Open</td><td>09:35.7</td><td>8</td><td>+1:45.16</td><td>01:27.6</td><td>7</td><td>02:00.2</td><td>8</td><td>02:37.0</td><td>7</td><td>01:49.4</td><td>8</td><td>01:41.5</td><td>8</td></tr>
 <tr><td>33</td><td>8032615</td><td>Efren Alvarado</td><td>&nbsp;</td><td>Men Beginner Open</td><td>10:10.0</td><td>9</td><td>+2:19.49</td><td>01:36.9</td><td>8</td><td>02:13.9</td><td>10</td><td>02:41.2</td><td>8</td><td>01:51.0</td><td>9</td><td>01:47.0</td><td>10</td></tr>
 <tr><td>28</td><td>8032668</td><td>Lalo García</td><td>&nbsp;</td><td>Men Beginner Open</td><td>10:23.0</td><td>10</td><td>+2:32.52</td><td>01:44.1</td><td>10</td><td>02:06.7</td><td>9</td><td>02:42.5</td><td>9</td><td>02:06.4</td><td>10</td><td>01:43.4</td><td>9</td></tr>
 <tr><td>48</td><td>8032657</td><td>Myles Overstreet</td><td>Dogspeed / Merol Racing / EP Enduro </td><td>Men Expert Open</td><td>06:00.7</td><td>1</td><td>&nbsp;</td><td>00:52.1</td><td>1</td><td>01:13.8</td><td>1</td><td>01:43.7</td><td>1</td><td>01:01.3</td><td>1</td><td>01:09.8</td><td>1</td></tr>
 <tr><td>40</td><td>8032610</td><td>Eric Sotomayor</td><td>The Shock Lab/ NorthCrew</td><td>Men Expert Open</td><td>06:28.3</td><td>2</td><td>+0:27.58</td><td>00:58.2</td><td>4</td><td>01:19.3</td><td>2</td><td>01:48.7</td><td>4</td><td>01:08.2</td><td>4</td><td>01:14.0</td><td>3</td></tr>
 <tr><td>41</td><td>8032653</td><td>Alexander Gutierrez</td><td>&nbsp;</td><td>Men Expert Open</td><td>06:28.6</td><td>3</td><td>+0:27.89</td><td>00:55.0</td><td>2</td><td>01:22.8</td><td>3</td><td>01:47.5</td><td>3</td><td>01:08.6</td><td>5</td><td>01:14.6</td><td>4</td></tr>
 <tr><td>46</td><td>8143973</td><td>Corey Jones</td><td>Merol Racing / EP Enduro</td><td>Men Expert Open</td><td>06:36.2</td><td>4</td><td>+0:35.52</td><td>00:58.7</td><td>5</td><td>01:24.5</td><td>6</td><td>01:52.3</td><td>7</td><td>01:06.1</td><td>3</td><td>01:14.7</td><td>5</td></tr>
 <tr><td>37</td><td>8032637</td><td>Jon Ordonez</td><td>&nbsp;</td><td>Men Expert Open</td><td>06:39.1</td><td>5</td><td>+0:38.40</td><td>00:57.5</td><td>3</td><td>01:41.7</td><td>15</td><td>01:46.3</td><td>2</td><td>01:02.7</td><td>2</td><td>01:10.9</td><td>2</td></tr>
 <tr><td>38</td><td>8032643</td><td>Joel lujan</td><td>North Crew/ The shock lab</td><td>Men Expert Open</td><td>06:41.9</td><td>6</td><td>+0:41.20</td><td>01:00.2</td><td>8</td><td>01:23.8</td><td>5</td><td>01:51.1</td><td>5</td><td>01:10.7</td><td>8</td><td>01:16.2</td><td>6</td></tr>
 <tr><td>39</td><td>8032608</td><td>Wayne Shaffer</td><td>&nbsp;</td><td>Men Expert Open</td><td>06:42.5</td><td>7</td><td>+0:41.78</td><td>00:59.7</td><td>6</td><td>01:22.9</td><td>4</td><td>01:51.4</td><td>6</td><td>01:09.0</td><td>6</td><td>01:19.4</td><td>8</td></tr>
 <tr><td>43</td><td>8032612</td><td>Luke Cutburth</td><td>Rad Dog Bikes LDCB</td><td>Men Expert Open</td><td>06:55.6</td><td>8</td><td>+0:54.94</td><td>01:00.1</td><td>7</td><td>01:24.8</td><td>7</td><td>01:55.1</td><td>9</td><td>01:14.1</td><td>9</td><td>01:21.5</td><td>12</td></tr>
 <tr><td>45</td><td>8032662</td><td>Michael Ordonez</td><td>Loam society</td><td>Men Expert Open</td><td>07:00.2</td><td>9</td><td>+0:59.52</td><td>01:00.5</td><td>9</td><td>01:29.4</td><td>10</td><td>01:59.4</td><td>10</td><td>01:10.4</td><td>7</td><td>01:20.5</td><td>10</td></tr>
 <tr><td>44</td><td>8143966</td><td>Hunt Burdick</td><td>&nbsp;</td><td>Men Expert Open</td><td>07:17.4</td><td>10</td><td>+1:16.73</td><td>01:04.6</td><td>11</td><td>01:31.1</td><td>12</td><td>02:04.3</td><td>12</td><td>01:15.7</td><td>11</td><td>01:21.8</td><td>13</td></tr>
 <tr><td>42</td><td>8143954</td><td>Clint Nelms</td><td>Podium Finish</td><td>Men Expert Open</td><td>07:25.9</td><td>11</td><td>+1:25.21</td><td>01:04.2</td><td>10</td><td>01:25.3</td><td>8</td><td>01:54.9</td><td>8</td><td>01:40.3</td><td>15</td><td>01:21.3</td><td>11</td></tr>
 <tr><td>36</td><td>8143965</td><td>Todge Cooper</td><td>SHERPA,WTB,DVO,TRP,DEITY</td><td>Men Expert Open</td><td>07:29.7</td><td>12</td><td>+1:29.04</td><td>01:05.4</td><td>12</td><td>01:30.7</td><td>11</td><td>02:10.7</td><td>14</td><td>01:15.5</td><td>10</td><td>01:27.6</td><td>15</td></tr>
 <tr><td>47</td><td>8032665</td><td>Antonio Escalera</td><td>Ethykl Gear</td><td>Men Expert Open</td><td>07:39.9</td><td>13</td><td>+1:39.22</td><td>01:13.0</td><td>13</td><td>01:37.7</td><td>13</td><td>02:03.6</td><td>11</td><td>01:26.1</td><td>14</td><td>01:19.6</td><td>9</td></tr>
 <tr><td>72</td><td>8032651</td><td>Luis Encerrado </td><td>Crazy Cat / Handske </td><td>Men Expert Open</td><td>08:02.2</td><td>14</td><td>+2:01.46</td><td>01:26.6</td><td>15</td><td>01:39.9</td><td>14</td><td>02:05.8</td><td>13</td><td>01:22.7</td><td>13</td><td>01:27.3</td><td>14</td></tr>
 <tr><td>79</td><td>8143985</td><td>Richie Sanchez </td><td>Rad Dog Bikes</td><td>Men Expert Open</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>01:23.9</td><td>14</td><td>01:29.2</td><td>9</td><td>&nbsp;</td><td>&nbsp;</td><td>01:17.3</td><td>12</td><td>01:18.1</td><td>7</td></tr>
 <tr><td>56</td><td>8032631</td><td>Isaac Chavez</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>07:15.3</td><td>1</td><td>&nbsp;</td><td>01:09.7</td><td>7</td><td>01:31.9</td><td>2</td><td>01:57.1</td><td>1</td><td>01:15.1</td><td>2</td><td>01:21.5</td><td>1</td></tr>
 <tr><td>50</td><td>8032656</td><td>Justin Rose</td><td>ROSE RACING</td><td>Men Intermediate Open</td><td>07:20.9</td><td>2</td><td>+0:05.63</td><td>01:06.5</td><td>2</td><td>01:35.0</td><td>6</td><td>01:59.7</td><td>2</td><td>01:15.1</td><td>1</td><td>01:24.7</td><td>3</td></tr>
 <tr><td>53</td><td>8032609</td><td>Efren Carranza</td><td>The shock lab/NORTHCREW</td><td>Men Intermediate Open</td><td>07:34.3</td><td>3</td><td>+0:18.99</td><td>01:06.5</td><td>3</td><td>01:33.0</td><td>3</td><td>02:04.1</td><td>4</td><td>01:21.0</td><td>4</td><td>01:29.7</td><td>8</td></tr>
 <tr><td>52</td><td>8032674</td><td>Adam Becerra</td><td>Dog Speed / Crazy Cat</td><td>Men Intermediate Open</td><td>07:35.0</td><td>4</td><td>+0:19.70</td><td>01:09.0</td><td>5</td><td>01:33.1</td><td>4</td><td>02:02.2</td><td>3</td><td>01:25.6</td><td>8</td><td>01:25.2</td><td>5</td></tr>
 <tr><td>54</td><td>8032616</td><td>Mark Nevarez</td><td>NorthCrew/EPEnduro/MerolRacing</td><td>Men Intermediate Open</td><td>07:36.0</td><td>5</td><td>+0:20.68</td><td>01:09.6</td><td>6</td><td>01:33.4</td><td>5</td><td>02:04.6</td><td>5</td><td>01:21.6</td><td>5</td><td>01:26.7</td><td>6</td></tr>
 <tr><td>58</td><td>8032659</td><td>RENE OINEZA</td><td>Southern New Mexico MTB (SNMTB)</td><td>Men Intermediate Open</td><td>07:50.1</td><td>6</td><td>+0:34.77</td><td>01:07.5</td><td>4</td><td>01:36.6</td><td>7</td><td>02:09.2</td><td>7</td><td>01:27.1</td><td>9</td><td>01:29.7</td><td>9</td></tr>
 <tr><td>51</td><td>8032666</td><td>Julio Reyes</td><td>Mozketeroz</td><td>Men Intermediate Open</td><td>07:51.0</td><td>7</td><td>+0:35.75</td><td>01:13.4</td><td>9</td><td>01:40.0</td><td>8</td><td>02:09.0</td><td>6</td><td>01:23.7</td><td>6</td><td>01:24.9</td><td>4</td></tr>
 <tr><td>55</td><td>8032614</td><td>John Shredsalot</td><td>NorthCrew/SNMTB</td><td>Men Intermediate Open</td><td>07:59.1</td><td>8</td><td>+0:43.78</td><td>01:06.0</td><td>1</td><td>01:28.9</td><td>1</td><td>02:44.0</td><td>11</td><td>01:15.9</td><td>3</td><td>01:24.3</td><td>2</td></tr>
 <tr><td>70</td><td>8032638</td><td>Kevin Dunn</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>08:05.5</td><td>9</td><td>+0:50.20</td><td>01:13.7</td><td>10</td><td>01:49.0</td><td>11</td><td>02:11.2</td><td>8</td><td>01:24.4</td><td>7</td><td>01:27.2</td><td>7</td></tr>
 <tr><td>60</td><td>8032652</td><td>Josh Bell</td><td>Podium Finish</td><td>Men Intermediate Open</td><td>08:40.6</td><td>10</td><td>+1:25.35</td><td>01:13.3</td><td>8</td><td>01:47.7</td><td>9</td><td>02:20.3</td><td>10</td><td>01:40.2</td><td>10</td><td>01:39.2</td><td>11</td></tr>
 <tr><td>49</td><td>8032611</td><td>Luke James Tsoukalas</td><td>Insanity Racing</td><td>Men Intermediate Open</td><td>08:52.0</td><td>11</td><td>+1:36.71</td><td>01:23.6</td><td>11</td><td>01:48.6</td><td>10</td><td>02:19.9</td><td>9</td><td>01:44.9</td><td>11</td><td>01:35.1</td><td>10</td></tr>
 <tr><td>69</td><td>8032676</td><td>Will  Palafox</td><td>Merol Racing/Ep Enduro</td><td>Men Intermediate Open</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>02:04.8</td><td>12</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
 <tr><td>61</td><td>8032654</td><td>Vaughn Ryden</td><td>&nbsp;</td><td>U16 JR class</td><td>10:11.9</td><td>1</td><td>&nbsp;</td><td>01:37.1</td><td>1</td><td>01:56.2</td><td>1</td><td>02:57.9</td><td>1</td><td>01:48.3</td><td>1</td><td>01:52.4</td><td>2</td></tr>
 <tr><td>64</td><td>8032635</td><td>Maksim bevins</td><td>&nbsp;</td><td>U16 JR class</td><td>10:31.5</td><td>2</td><td>+0:19.59</td><td>01:44.9</td><td>2</td><td>02:05.6</td><td>2</td><td>03:01.6</td><td>2</td><td>02:01.9</td><td>3</td><td>01:37.7</td><td>1</td></tr>
 <tr><td>71</td><td>8032613</td><td>Myles Westbrook</td><td>&nbsp;</td><td>U16 JR class</td><td>11:33.5</td><td>3</td><td>+1:21.55</td><td>01:45.7</td><td>3</td><td>02:12.8</td><td>3</td><td>03:32.4</td><td>3</td><td>02:01.2</td><td>2</td><td>02:01.4</td><td>3</td></tr>
 <tr><td>67</td><td>8032622</td><td>Kara Samsel</td><td>Podium Finish</td><td>Women Open</td><td>09:01.8</td><td>1</td><td>&nbsp;</td><td>01:26.1</td><td>1</td><td>01:47.3</td><td>1</td><td>02:23.5</td><td>2</td><td>01:45.6</td><td>1</td><td>01:39.3</td><td>1</td></tr>
 <tr><td>66</td><td>8143968</td><td>Lilly Sanchez</td><td>&nbsp;</td><td>Women Open</td><td>09:59.8</td><td>2</td><td>00:58.0</td><td>02:08.3</td><td>2</td><td>01:56.8</td><td>2</td><td>02:21.9</td><td>1</td><td>01:53.0</td><td>2</td><td>01:39.8</td><td>2</td></tr>
 <tr><td>65</td><td>8032677</td><td>Oceana Nelson</td><td>The Podium Finish</td><td>Women Open</td><td>11:48.9</td><td>3</td><td>+2:47.08</td><td>02:16.3</td><td>3</td><td>02:27.7</td><td>3</td><td>03:16.9</td><td>3</td><td>01:54.6</td><td>3</td><td>01:53.3</td><td>3</td></tr>
 <tr><td>68</td><td>8032642</td><td>Sophia Garcia</td><td>Smaz remodeling</td><td>Women Open</td><td>34:43.8</td><td>4</td><td>+25:42.00</td><td>06:32.8</td><td>4</td><td>07:12.7</td><td>4</td><td>06:55.9</td><td>4</td><td>08:04.5</td><td>4</td><td>05:57.9</td><td>4</td></tr>
</tbody></table>
            
            <CSVLink className="downloadLink" data={csvData}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
