import React from 'react'
import './lost_enduro.css'
import { CSVLink, CSVDownload } from "react-csv";



const csvDataLostDog = [
    {
      RaceNumber: "35",
      CardNumbers: "8032651",
      Name: "Michael Ordonez",
      Club: "",
      Country: "SAN",
      CourseClass: "Beginner Men Open ",
      RaceTime: "11:31.5",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:55.6",
      Stage1Pos: "2",
      Stage2Time: "04:22.8",
      Stage2Pos: "2",
      Stage3Time: "01:13.4",
      Stage3Pos: "1",
      Stage4Time: "01:22.2",
      Stage4Pos: "1",
      Stage5Time: "01:37.2",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "22",
      CardNumbers: "8032608",
      Name: "Adam Becerra",
      Club: "Dog Speed",
      Country: "EL ",
      CourseClass: "Beginner Men Open ",
      RaceTime: "12:06.7",
      Position: "2",
      BehindTime: "+0:35.22",
      Stage1Time: "02:53.3",
      Stage1Pos: "1",
      Stage2Time: "04:19.7",
      Stage2Pos: "1",
      Stage3Time: "01:27.5",
      Stage3Pos: "2",
      Stage4Time: "01:36.6",
      Stage4Pos: "2",
      Stage5Time: "01:49.4",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "8",
      CardNumbers: "8032637",
      Name: "Cesar Gallegos",
      Club: "",
      Country: "HOR",
      CourseClass: "Beginner Men Open ",
      RaceTime: "14:21.9",
      Position: "3",
      BehindTime: "+2:50.44",
      Stage1Time: "03:16.3",
      Stage1Pos: "4",
      Stage2Time: "04:48.6",
      Stage2Pos: "4",
      Stage3Time: "01:50.8",
      Stage3Pos: "3",
      Stage4Time: "01:58.1",
      Stage4Pos: "5",
      Stage5Time: "02:28.0",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "74",
      CardNumbers: "8032643",
      Name: "Victor Cordero",
      Club: "",
      Country: "EL ",
      CourseClass: "Beginner Men Open ",
      RaceTime: "15:13.5",
      Position: "4",
      BehindTime: "+3:42.03",
      Stage1Time: "03:16.1",
      Stage1Pos: "3",
      Stage2Time: "04:24.3",
      Stage2Pos: "3",
      Stage3Time: "02:18.7",
      Stage3Pos: "6",
      Stage4Time: "01:57.4",
      Stage4Pos: "4",
      Stage5Time: "03:16.9",
      Stage5Pos: "6"
    },
    {
      RaceNumber: "16",
      CardNumbers: "8143954",
      Name: "Raul Carrillo",
      Club: "",
      Country: "",
      CourseClass: "Beginner Men Open ",
      RaceTime: "16:20.2",
      Position: "5",
      BehindTime: "+4:48.72",
      Stage1Time: "04:08.1",
      Stage1Pos: "6",
      Stage2Time: "05:28.6",
      Stage2Pos: "5",
      Stage3Time: "02:16.2",
      Stage3Pos: "5",
      Stage4Time: "01:54.9",
      Stage4Pos: "3",
      Stage5Time: "02:32.3",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "77",
      CardNumbers: "8032623",
      Name: "Lloyd Hamilton",
      Club: "Lloyd Hamilton Construction",
      Country: "EL ",
      CourseClass: "Beginner Men Open ",
      RaceTime: "16:46.6",
      Position: "6",
      BehindTime: "+5:15.16",
      Stage1Time: "03:55.7",
      Stage1Pos: "5",
      Stage2Time: "05:30.0",
      Stage2Pos: "6",
      Stage3Time: "02:07.4",
      Stage3Pos: "4",
      Stage4Time: "02:35.1",
      Stage4Pos: "6",
      Stage5Time: "02:38.2",
      Stage5Pos: "5"
    },
    {
      RaceNumber: "33",
      CardNumbers: "8143966",
      Name: "Eduardo Guerra",
      Club: "",
      Country: "EL ",
      CourseClass: "Beginner Men Open ",
      RaceTime: "23:02.8",
      Position: "7",
      BehindTime: "+11:31.29",
      Stage1Time: "05:20.1",
      Stage1Pos: "7",
      Stage2Time: "07:35.7",
      Stage2Pos: "7",
      Stage3Time: "03:25.7",
      Stage3Pos: "7",
      Stage4Time: "02:52.5",
      Stage4Pos: "7",
      Stage5Time: "03:48.6",
      Stage5Pos: "7"
    },
    {
      RaceNumber: "36",
      CardNumbers: "8032666",
      Name: "Fernanda Polanco ",
      Club: "Handske",
      Country: "",
      CourseClass: "Women Open",
      RaceTime: "13:28.9",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:09.9",
      Stage1Pos: "1",
      Stage2Time: "04:38.6",
      Stage2Pos: "1",
      Stage3Time: "01:40.6",
      Stage3Pos: "2",
      Stage4Time: "01:42.4",
      Stage4Pos: "1",
      Stage5Time: "02:17.3",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "29",
      CardNumbers: "8032648",
      Name: "Kim Waechter",
      Club: "",
      Country: "EL ",
      CourseClass: "Women Open",
      RaceTime: "14:25.8",
      Position: "2",
      BehindTime: "+0:56.90",
      Stage1Time: "03:16.9",
      Stage1Pos: "3",
      Stage2Time: "05:04.4",
      Stage2Pos: "3",
      Stage3Time: "01:42.5",
      Stage3Pos: "3",
      Stage4Time: "02:11.3",
      Stage4Pos: "4",
      Stage5Time: "02:10.5",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "52",
      CardNumbers: "8032649",
      Name: "Jennifer Morrow",
      Club: "",
      Country: "LAS",
      CourseClass: "Women Open",
      RaceTime: "14:28.5",
      Position: "3",
      BehindTime: "+0:59.58",
      Stage1Time: "03:19.3",
      Stage1Pos: "4",
      Stage2Time: "05:13.1",
      Stage2Pos: "5",
      Stage3Time: "01:27.7",
      Stage3Pos: "1",
      Stage4Time: "02:03.2",
      Stage4Pos: "3",
      Stage5Time: "02:25.0",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "16",
      CardNumbers: "8032656",
      Name: "Norma Rivera",
      Club: "Last Sundays Ride",
      Country: "EL ",
      CourseClass: "Women Open",
      RaceTime: "14:35.3",
      Position: "4",
      BehindTime: "+1:06.39",
      Stage1Time: "03:12.7",
      Stage1Pos: "2",
      Stage2Time: "04:54.0",
      Stage2Pos: "2",
      Stage3Time: "01:43.5",
      Stage3Pos: "4",
      Stage4Time: "02:14.1",
      Stage4Pos: "5",
      Stage5Time: "02:30.8",
      Stage5Pos: "5"
    },
    {
      RaceNumber: "48",
      CardNumbers: "8032645",
      Name: "Genesis Garcia",
      Club: "Crazy Cat Cyclery",
      Country: "",
      CourseClass: "Women Open",
      RaceTime: "17:44.8",
      Position: "5",
      BehindTime: "+4:15.87",
      Stage1Time: "06:25.0",
      Stage1Pos: "5",
      Stage2Time: "05:05.6",
      Stage2Pos: "4",
      Stage3Time: "01:43.9",
      Stage3Pos: "5",
      Stage4Time: "02:02.5",
      Stage4Pos: "2",
      Stage5Time: "02:27.6",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "20",
      CardNumbers: "8032665",
      Name: "Perla Reyes",
      Club: "Last Sunday Ride",
      Country: "",
      CourseClass: "Women Open",
      RaceTime: "19:46.7",
      Position: "6",
      BehindTime: "+6:17.80",
      Stage1Time: "06:38.7",
      Stage1Pos: "6",
      Stage2Time: "05:33.2",
      Stage2Pos: "6",
      Stage3Time: "02:00.2",
      Stage3Pos: "6",
      Stage4Time: "02:15.8",
      Stage4Pos: "6",
      Stage5Time: "03:18.6",
      Stage5Pos: "6"
    },
    {
      RaceNumber: "24",
      CardNumbers: "8032634",
      Name: "Ramon Gamez",
      Club: "",
      Country: "EL ",
      CourseClass: "18-29 Men",
      RaceTime: "11:34.0",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:46.4",
      Stage1Pos: "1",
      Stage2Time: "04:07.4",
      Stage2Pos: "3",
      Stage3Time: "01:15.2",
      Stage3Pos: "1",
      Stage4Time: "01:42.3",
      Stage4Pos: "2",
      Stage5Time: "01:42.5",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "5",
      CardNumbers: "8032632",
      Name: "Blas Meza",
      Club: "",
      Country: "",
      CourseClass: "18-29 Men",
      RaceTime: "11:42.1",
      Position: "2",
      BehindTime: "+0:08.12",
      Stage1Time: "02:57.4",
      Stage1Pos: "2",
      Stage2Time: "03:48.5",
      Stage2Pos: "1",
      Stage3Time: "01:37.9",
      Stage3Pos: "4",
      Stage4Time: "01:31.8",
      Stage4Pos: "1",
      Stage5Time: "01:46.3",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "42",
      CardNumbers: "8032612",
      Name: "Luis Encerrado",
      Club: "Handske",
      Country: "",
      CourseClass: "18-29 Men",
      RaceTime: "12:57.1",
      Position: "3",
      BehindTime: "+1:23.12",
      Stage1Time: "03:58.4",
      Stage1Pos: "4",
      Stage2Time: "04:00.9",
      Stage2Pos: "2",
      Stage3Time: "01:19.7",
      Stage3Pos: "2",
      Stage4Time: "02:04.6",
      Stage4Pos: "4",
      Stage5Time: "01:33.4",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "14",
      CardNumbers: "8032616",
      Name: "Issac Munoz",
      Club: "",
      Country: "EL ",
      CourseClass: "18-29 Men",
      RaceTime: "13:06.2",
      Position: "4",
      BehindTime: "+1:32.24",
      Stage1Time: "03:07.0",
      Stage1Pos: "3",
      Stage2Time: "04:18.8",
      Stage2Pos: "4",
      Stage3Time: "01:29.6",
      Stage3Pos: "3",
      Stage4Time: "01:44.8",
      Stage4Pos: "3",
      Stage5Time: "02:25.9",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "38",
      CardNumbers: "8143971",
      Name: "Will Palafox",
      Club: "Podium Finish Sport Boutique",
      Country: "EL ",
      CourseClass: "30-39 Men",
      RaceTime: "10:09.1",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:38.4",
      Stage1Pos: "1",
      Stage2Time: "03:32.0",
      Stage2Pos: "1",
      Stage3Time: "01:08.6",
      Stage3Pos: "4",
      Stage4Time: "01:18.7",
      Stage4Pos: "4",
      Stage5Time: "01:31.3",
      Stage5Pos: "5"
    },
    {
      RaceNumber: "39",
      CardNumbers: "8032646",
      Name: "Eddie Sanchez",
      Club: "EP Enduro/ NorthCrew / The Shock Lab",
      Country: "EL ",
      CourseClass: "30-39 Men",
      RaceTime: "10:17.9",
      Position: "2",
      BehindTime: "+0:08.78",
      Stage1Time: "02:44.8",
      Stage1Pos: "3",
      Stage2Time: "03:49.4",
      Stage2Pos: "2",
      Stage3Time: "01:01.4",
      Stage3Pos: "2",
      Stage4Time: "01:17.5",
      Stage4Pos: "3",
      Stage5Time: "01:24.6",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "69",
      CardNumbers: "8032642",
      Name: "Wayne Shaffer",
      Club: "Bicycle Experience",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "10:31.5",
      Position: "3",
      BehindTime: "+0:22.41",
      Stage1Time: "02:51.2",
      Stage1Pos: "5",
      Stage2Time: "03:50.3",
      Stage2Pos: "3",
      Stage3Time: "01:07.2",
      Stage3Pos: "3",
      Stage4Time: "01:22.5",
      Stage4Pos: "5",
      Stage5Time: "01:20.1",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "26",
      CardNumbers: "8143978",
      Name: "John Shredsalot",
      Club: "SNMTB/ The Shock Lab / EP Enduro",
      Country: "LAS",
      CourseClass: "30-39 Men",
      RaceTime: "12:17.1",
      Position: "4",
      BehindTime: "+2:08.06",
      Stage1Time: "02:46.4",
      Stage1Pos: "4",
      Stage2Time: "05:55.8",
      Stage2Pos: "9",
      Stage3Time: "00:58.7",
      Stage3Pos: "1",
      Stage4Time: "01:13.0",
      Stage4Pos: "2",
      Stage5Time: "01:23.1",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "17",
      CardNumbers: "8143965",
      Name: "Hector Rodriguez",
      Club: "",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "13:09.8",
      Position: "5",
      BehindTime: "+3:00.76",
      Stage1Time: "03:10.5",
      Stage1Pos: "6",
      Stage2Time: "04:21.4",
      Stage2Pos: "4",
      Stage3Time: "01:45.4",
      Stage3Pos: "8",
      Stage4Time: "02:11.3",
      Stage4Pos: "9",
      Stage5Time: "01:41.1",
      Stage5Pos: "6"
    },
    {
      RaceNumber: "4",
      CardNumbers: "8032653",
      Name: "Christian Ottobre",
      Club: "Donkey Show Bicycle Club",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "13:16.5",
      Position: "6",
      BehindTime: "+3:07.38",
      Stage1Time: "02:43.0",
      Stage1Pos: "2",
      Stage2Time: "06:42.0",
      Stage2Pos: "10",
      Stage3Time: "01:11.2",
      Stage3Pos: "5",
      Stage4Time: "01:12.8",
      Stage4Pos: "1",
      Stage5Time: "01:27.4",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "2",
      CardNumbers: "8032654",
      Name: "Angel Valles",
      Club: "",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "13:34.9",
      Position: "7",
      BehindTime: "+3:25.87",
      Stage1Time: "03:22.8",
      Stage1Pos: "9",
      Stage2Time: "04:36.2",
      Stage2Pos: "6",
      Stage3Time: "01:52.3",
      Stage3Pos: "9",
      Stage4Time: "01:49.1",
      Stage4Pos: "8",
      Stage5Time: "01:54.4",
      Stage5Pos: "8"
    },
    {
      RaceNumber: "37",
      CardNumbers: "8032611",
      Name: "Christopher Beversdorf",
      Club: "TACOCAT",
      Country: "EL ",
      CourseClass: "30-39 Men",
      RaceTime: "14:11.1",
      Position: "8",
      BehindTime: "+4:02.02",
      Stage1Time: "03:15.6",
      Stage1Pos: "7",
      Stage2Time: "05:41.2",
      Stage2Pos: "8",
      Stage3Time: "01:31.0",
      Stage3Pos: "6",
      Stage4Time: "01:36.0",
      Stage4Pos: "7",
      Stage5Time: "02:07.2",
      Stage5Pos: "10"
    },
    {
      RaceNumber: "90",
      CardNumbers: "8032630",
      Name: "Moses Teliz",
      Club: "",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "18:10.4",
      Position: "9",
      BehindTime: "+8:01.35",
      Stage1Time: "06:41.0",
      Stage1Pos: "10",
      Stage2Time: "04:39.5",
      Stage2Pos: "7",
      Stage3Time: "01:35.6",
      Stage3Pos: "7",
      Stage4Time: "03:25.3",
      Stage4Pos: "10",
      Stage5Time: "01:48.9",
      Stage5Pos: "7"
    },
    {
      RaceNumber: "41",
      CardNumbers: "8143980",
      Name: "Arturo Garcia",
      Club: "",
      Country: "",
      CourseClass: "30-39 Men",
      RaceTime: "20:34.6",
      Position: "10",
      BehindTime: "+10:25.49",
      Stage1Time: "03:21.3",
      Stage1Pos: "8",
      Stage2Time: "04:31.9",
      Stage2Pos: "5",
      Stage3Time: "09:12.5",
      Stage3Pos: "10",
      Stage4Time: "01:32.3",
      Stage4Pos: "6",
      Stage5Time: "01:56.4",
      Stage5Pos: "9"
    },
    {
      RaceNumber: "3",
      CardNumbers: "8032675",
      Name: "Drew Drennan",
      Club: "",
      Country: "SPR",
      CourseClass: "30-39 Men",
      RaceTime: "",
      Position: "",
      BehindTime: "",
      Stage1Time: "06:43.4",
      Stage1Pos: "11",
      Stage2Time: "",
      Stage2Pos: "",
      Stage3Time: "",
      Stage3Pos: "",
      Stage4Time: "",
      Stage4Pos: "",
      Stage5Time: "",
      Stage5Pos: ""
    },
    {
      RaceNumber: "23",
      CardNumbers: "8032625",
      Name: "Arnold Rodriguez",
      Club: "Chuco MTB",
      Country: "EL ",
      CourseClass: "40-49 Men",
      RaceTime: "09:47.4",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:36.3",
      Stage1Pos: "2",
      Stage2Time: "03:28.8",
      Stage2Pos: "2",
      Stage3Time: "01:01.5",
      Stage3Pos: "1",
      Stage4Time: "01:19.1",
      Stage4Pos: "3",
      Stage5Time: "01:21.6",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "40",
      CardNumbers: "8143982",
      Name: "Sergio Acosta ",
      Club: "",
      Country: "",
      CourseClass: "40-49 Men",
      RaceTime: "10:10.6",
      Position: "2",
      BehindTime: "+0:23.14",
      Stage1Time: "02:34.9",
      Stage1Pos: "1",
      Stage2Time: "03:27.4",
      Stage2Pos: "1",
      Stage3Time: "01:23.2",
      Stage3Pos: "3",
      Stage4Time: "01:13.8",
      Stage4Pos: "2",
      Stage5Time: "01:31.1",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "34",
      CardNumbers: "8032622",
      Name: "Mathieu Maillon",
      Club: "",
      Country: "",
      CourseClass: "40-49 Men",
      RaceTime: "12:11.5",
      Position: "3",
      BehindTime: "+2:24.08",
      Stage1Time: "03:05.4",
      Stage1Pos: "4",
      Stage2Time: "04:20.8",
      Stage2Pos: "4",
      Stage3Time: "01:29.8",
      Stage3Pos: "4",
      Stage4Time: "01:33.5",
      Stage4Pos: "4",
      Stage5Time: "01:41.8",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "47",
      CardNumbers: "8032619",
      Name: "Brian Canales",
      Club: "Desert Downs ",
      Country: "EL ",
      CourseClass: "40-49 Men",
      RaceTime: "13:22.1",
      Position: "4",
      BehindTime: "+3:34.66",
      Stage1Time: "02:59.1",
      Stage1Pos: "3",
      Stage2Time: "04:25.1",
      Stage2Pos: "5",
      Stage3Time: "01:35.8",
      Stage3Pos: "5",
      Stage4Time: "02:01.6",
      Stage4Pos: "5",
      Stage5Time: "02:20.3",
      Stage5Pos: "5"
    },
    {
      RaceNumber: "53",
      CardNumbers: "8032674",
      Name: "Mark Nevarez",
      Club: "",
      Country: "LAS",
      CourseClass: "40-49 Men",
      RaceTime: "13:37.6",
      Position: "5",
      BehindTime: "+3:50.19",
      Stage1Time: "06:05.1",
      Stage1Pos: "5",
      Stage2Time: "03:36.9",
      Stage2Pos: "3",
      Stage3Time: "01:17.9",
      Stage3Pos: "2",
      Stage4Time: "01:12.0",
      Stage4Pos: "1",
      Stage5Time: "01:25.6",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "9",
      CardNumbers: "8032609",
      Name: "Mario Lopez",
      Club: "Podium Finish ",
      Country: "EL ",
      CourseClass: "50+ Men",
      RaceTime: "12:14.2",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:01.1",
      Stage1Pos: "1",
      Stage2Time: "04:13.0",
      Stage2Pos: "1",
      Stage3Time: "01:34.6",
      Stage3Pos: "1",
      Stage4Time: "01:37.3",
      Stage4Pos: "1",
      Stage5Time: "01:48.0",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "15",
      CardNumbers: "8032647",
      Name: "Miguel Rico",
      Club: "",
      Country: "EL ",
      CourseClass: "50+ Men",
      RaceTime: "20:56.2",
      Position: "2",
      BehindTime: "+8:42.06",
      Stage1Time: "04:40.5",
      Stage1Pos: "2",
      Stage2Time: "06:07.5",
      Stage2Pos: "2",
      Stage3Time: "02:45.4",
      Stage3Pos: "2",
      Stage4Time: "02:45.5",
      Stage4Pos: "2",
      Stage5Time: "04:37.1",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "31",
      CardNumbers: "8032676",
      Name: "Tom Baumann",
      Club: "The Flying Cannoli",
      Country: "EL ",
      CourseClass: "50+ Men",
      RaceTime: "29:58.9",
      Position: "3",
      BehindTime: "+17:44.74",
      Stage1Time: "05:13.6",
      Stage1Pos: "3",
      Stage2Time: "09:26.0",
      Stage2Pos: "3",
      Stage3Time: "04:11.5",
      Stage3Pos: "3",
      Stage4Time: "03:20.5",
      Stage4Pos: "3",
      Stage5Time: "07:47.2",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "11",
      CardNumbers: "8143950",
      Name: "Lee Shamaley",
      Club: "",
      Country: "",
      CourseClass: "E-Bike",
      RaceTime: "13:33.1",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:41.1",
      Stage1Pos: "1",
      Stage2Time: "05:33.0",
      Stage2Pos: "1",
      Stage3Time: "01:25.7",
      Stage3Pos: "1",
      Stage4Time: "01:44.7",
      Stage4Pos: "1",
      Stage5Time: "02:08.5",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "27",
      CardNumbers: "8143956",
      Name: "Fabrizzio Delgado",
      Club: "",
      Country: "",
      CourseClass: "E-Bike",
      RaceTime: "22:58.0",
      Position: "2",
      BehindTime: "+9:24.95",
      Stage1Time: "05:12.2",
      Stage1Pos: "2",
      Stage2Time: "06:00.9",
      Stage2Pos: "2",
      Stage3Time: "03:42.6",
      Stage3Pos: "2",
      Stage4Time: "03:17.1",
      Stage4Pos: "2",
      Stage5Time: "04:45.1",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "30",
      CardNumbers: "8032613",
      Name: "Humberto Arevalo",
      Club: "The shock lab",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "08:15.0",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:18.5",
      Stage1Pos: "1",
      Stage2Time: "02:55.1",
      Stage2Pos: "1",
      Stage3Time: "00:51.5",
      Stage3Pos: "1",
      Stage4Time: "01:03.9",
      Stage4Pos: "2",
      Stage5Time: "01:05.9",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "49",
      CardNumbers: "8032635",
      Name: "Edgar Ramirez",
      Club: "The Shock Lab",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "08:36.2",
      Position: "2",
      BehindTime: "+0:21.19",
      Stage1Time: "02:19.1",
      Stage1Pos: "2",
      Stage2Time: "03:10.3",
      Stage2Pos: "2",
      Stage3Time: "00:51.7",
      Stage3Pos: "2",
      Stage4Time: "01:01.6",
      Stage4Pos: "1",
      Stage5Time: "01:13.4",
      Stage5Pos: "5"
    },
    {
      RaceNumber: "6",
      CardNumbers: "8032633",
      Name: "Ivan Valdez ",
      Club: "Salt Cycles",
      Country: "",
      CourseClass: "Expert Men",
      RaceTime: "08:45.2",
      Position: "3",
      BehindTime: "+0:30.26",
      Stage1Time: "02:22.8",
      Stage1Pos: "4",
      Stage2Time: "03:15.4",
      Stage2Pos: "4",
      Stage3Time: "00:52.0",
      Stage3Pos: "3",
      Stage4Time: "01:04.1",
      Stage4Pos: "3",
      Stage5Time: "01:10.7",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "7",
      CardNumbers: "8032610",
      Name: "Eric Sotomajor",
      Club: "The Shock Lab",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "09:01.7",
      Position: "4",
      BehindTime: "+0:46.72",
      Stage1Time: "02:21.0",
      Stage1Pos: "3",
      Stage2Time: "03:31.9",
      Stage2Pos: "6",
      Stage3Time: "00:53.1",
      Stage3Pos: "4",
      Stage4Time: "01:06.7",
      Stage4Pos: "5",
      Stage5Time: "01:08.7",
      Stage5Pos: "2"
    },
    {
      RaceNumber: "25",
      CardNumbers: "8032618",
      Name: "Joel Lujan",
      Club: "The Shock Lab",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "09:08.2",
      Position: "5",
      BehindTime: "+0:53.26",
      Stage1Time: "02:37.6",
      Stage1Pos: "6",
      Stage2Time: "03:11.9",
      Stage2Pos: "3",
      Stage3Time: "00:55.1",
      Stage3Pos: "5",
      Stage4Time: "01:10.7",
      Stage4Pos: "7",
      Stage5Time: "01:12.7",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "18",
      CardNumbers: "8032672",
      Name: "Alejandro Soto ",
      Club: "Dog Speed",
      Country: "",
      CourseClass: "Expert Men",
      RaceTime: "09:43.0",
      Position: "6",
      BehindTime: "+1:27.99",
      Stage1Time: "02:38.2",
      Stage1Pos: "7",
      Stage2Time: "03:26.4",
      Stage2Pos: "5",
      Stage3Time: "01:12.0",
      Stage3Pos: "9",
      Stage4Time: "01:08.9",
      Stage4Pos: "6",
      Stage5Time: "01:17.2",
      Stage5Pos: "7"
    },
    {
      RaceNumber: "28",
      CardNumbers: "8143957",
      Name: "José Garcia",
      Club: "The Shock Lab",
      Country: "",
      CourseClass: "Expert Men",
      RaceTime: "09:46.7",
      Position: "7",
      BehindTime: "+1:31.74",
      Stage1Time: "02:38.6",
      Stage1Pos: "8",
      Stage2Time: "03:36.5",
      Stage2Pos: "7",
      Stage3Time: "00:55.9",
      Stage3Pos: "6",
      Stage4Time: "01:14.1",
      Stage4Pos: "8",
      Stage5Time: "01:21.5",
      Stage5Pos: "9"
    },
    {
      RaceNumber: "32",
      CardNumbers: "8032668",
      Name: "Byrl Hunt Burdick IV",
      Club: "EP Enduro",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "09:49.5",
      Position: "8",
      BehindTime: "+1:34.53",
      Stage1Time: "02:35.2",
      Stage1Pos: "5",
      Stage2Time: "03:38.1",
      Stage2Pos: "8",
      Stage3Time: "00:58.1",
      Stage3Pos: "7",
      Stage4Time: "01:21.5",
      Stage4Pos: "11",
      Stage5Time: "01:16.5",
      Stage5Pos: "6"
    },
    {
      RaceNumber: "21",
      CardNumbers: "8032677",
      Name: "Ricardo Granados",
      Club: "",
      Country: "",
      CourseClass: "Expert Men",
      RaceTime: "10:26.9",
      Position: "9",
      BehindTime: "+2:11.93",
      Stage1Time: "02:43.7",
      Stage1Pos: "10",
      Stage2Time: "03:56.6",
      Stage2Pos: "10",
      Stage3Time: "01:02.4",
      Stage3Pos: "8",
      Stage4Time: "01:14.8",
      Stage4Pos: "9",
      Stage5Time: "01:29.1",
      Stage5Pos: "10"
    },
    {
      RaceNumber: "88",
      CardNumbers: "8032652",
      Name: "Rene Oineza",
      Club: "Southern New Mexico MTB (SNMTB)",
      Country: "LAS",
      CourseClass: "Expert Men",
      RaceTime: "10:43.1",
      Position: "10",
      BehindTime: "+2:28.11",
      Stage1Time: "02:46.2",
      Stage1Pos: "11",
      Stage2Time: "03:50.6",
      Stage2Pos: "9",
      Stage3Time: "01:16.1",
      Stage3Pos: "10",
      Stage4Time: "01:18.2",
      Stage4Pos: "10",
      Stage5Time: "01:31.8",
      Stage5Pos: "11"
    },
    {
      RaceNumber: "1",
      CardNumbers: "8032655",
      Name: "Ricky Scoles",
      Club: "Desert Downs",
      Country: "",
      CourseClass: "Expert Men",
      RaceTime: "16:26.9",
      Position: "11",
      BehindTime: "+8:11.89",
      Stage1Time: "02:38.9",
      Stage1Pos: "9",
      Stage2Time: "08:38.7",
      Stage2Pos: "11",
      Stage3Time: "02:30.3",
      Stage3Pos: "11",
      Stage4Time: "01:05.6",
      Stage4Pos: "4",
      Stage5Time: "01:33.2",
      Stage5Pos: "12"
    },
    {
      RaceNumber: "44",
      CardNumbers: "8032662",
      Name: "Pablo Fernandez",
      Club: "",
      Country: "EL ",
      CourseClass: "Expert Men",
      RaceTime: "",
      Position: "",
      BehindTime: "",
      Stage1Time: "06:22.6",
      Stage1Pos: "12",
      Stage2Time: "",
      Stage2Pos: "",
      Stage3Time: "",
      Stage3Pos: "",
      Stage4Time: "",
      Stage4Pos: "",
      Stage5Time: "01:18.1",
      Stage5Pos: "8"
    },
    {
      RaceNumber: "45",
      CardNumbers: "8032614",
      Name: "Alexander Gutierrez",
      Club: "",
      Country: "EL ",
      CourseClass: "JR Boys U17",
      RaceTime: "09:51.8",
      Position: "1",
      BehindTime: "",
      Stage1Time: "02:40.1",
      Stage1Pos: "1",
      Stage2Time: "03:36.7",
      Stage2Pos: "1",
      Stage3Time: "01:01.1",
      Stage3Pos: "1",
      Stage4Time: "01:15.5",
      Stage4Pos: "1",
      Stage5Time: "01:18.2",
      Stage5Pos: "1"
    },
    {
      RaceNumber: "10",
      CardNumbers: "8032640",
      Name: "Wyatt Ivey",
      Club: "",
      Country: "",
      CourseClass: "JR Boys U17",
      RaceTime: "12:02.1",
      Position: "2",
      BehindTime: "+2:10.30",
      Stage1Time: "02:56.6",
      Stage1Pos: "2",
      Stage2Time: "04:57.3",
      Stage2Pos: "2",
      Stage3Time: "01:10.5",
      Stage3Pos: "3",
      Stage4Time: "01:27.7",
      Stage4Pos: "3",
      Stage5Time: "01:29.8",
      Stage5Pos: "3"
    },
    {
      RaceNumber: "55",
      CardNumbers: "8032629",
      Name: "Maximiliano Guerra",
      Club: "",
      Country: "EL ",
      CourseClass: "JR Boys U17",
      RaceTime: "13:36.6",
      Position: "3",
      BehindTime: "+3:44.81",
      Stage1Time: "03:32.6",
      Stage1Pos: "4",
      Stage2Time: "05:09.5",
      Stage2Pos: "3",
      Stage3Time: "01:20.3",
      Stage3Pos: "4",
      Stage4Time: "01:34.7",
      Stage4Pos: "4",
      Stage5Time: "01:59.4",
      Stage5Pos: "4"
    },
    {
      RaceNumber: "12",
      CardNumbers: "8032615",
      Name: "Andrew Fernandez",
      Club: "",
      Country: "EL ",
      CourseClass: "JR Boys U17",
      RaceTime: "19:50.7",
      Position: "4",
      BehindTime: "+9:58.99",
      Stage1Time: "03:16.3",
      Stage1Pos: "3",
      Stage2Time: "12:34.4",
      Stage2Pos: "4",
      Stage3Time: "01:08.9",
      Stage3Pos: "2",
      Stage4Time: "01:23.8",
      Stage4Pos: "2",
      Stage5Time: "01:27.1",
      Stage5Pos: "2"
    }
  ]

export default function lost_enduro() {
    return (
        <div className='background-dog'>
        
        <div className="lost-dog">
              
            <div className='container'>
                <h1 className='gold-0'>Lost Dog Enduro</h1>



                <table class="tableizer-table">
                    <thead><tr class="tableizer-firstrow"><th>RaceNumber</th><th>CardNumbers</th><th>Name</th><th>Club</th><th>Country</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>BehindTime</th><th>Stage 1 - BABY HEAD Time</th><th>Stage 1 - BABY HEAD Pos</th><th>Stage 2 - LITTLE MOAB Time</th><th>Stage 2 - LITTLE MOAB Pos</th><th>Stage 3 - TIN MINE Time</th><th>Stage 3 - TIN MINE Pos</th><th>Stage 4 - G.I. JOE Time</th><th>Stage 4 - G.I. JOE Pos</th><th>Stage 5 - LA ESPINA LEDGE Time</th><th>Stage 5 - LA ESPINA LEDGE Pos</th></tr></thead><tbody>
                    <tr><td>35</td><td>8032651</td><td>Michael Ordonez</td><td>&nbsp;</td><td>SAN</td><td>Beginner Men Open </td><td>11:31.5</td><td>1</td><td>&nbsp;</td><td>02:55.6</td><td>2</td><td>04:22.8</td><td>2</td><td>01:13.4</td><td>1</td><td>01:22.2</td><td>1</td><td>01:37.2</td><td>1</td></tr>
                    <tr><td>22</td><td>8032608</td><td>Adam Becerra</td><td>Dog Speed</td><td>EL </td><td>Beginner Men Open </td><td>12:06.7</td><td>2</td><td>+0:35.22</td><td>02:53.3</td><td>1</td><td>04:19.7</td><td>1</td><td>01:27.5</td><td>2</td><td>01:36.6</td><td>2</td><td>01:49.4</td><td>2</td></tr>
                    <tr><td>8</td><td>8032637</td><td>Cesar Gallegos</td><td>&nbsp;</td><td>HOR</td><td>Beginner Men Open </td><td>14:21.9</td><td>3</td><td>+2:50.44</td><td>03:16.3</td><td>4</td><td>04:48.6</td><td>4</td><td>01:50.8</td><td>3</td><td>01:58.1</td><td>5</td><td>02:28.0</td><td>3</td></tr>
                    <tr><td>74</td><td>8032643</td><td>Victor Cordero</td><td>&nbsp;</td><td>EL </td><td>Beginner Men Open </td><td>15:13.5</td><td>4</td><td>+3:42.03</td><td>03:16.1</td><td>3</td><td>04:24.3</td><td>3</td><td>02:18.7</td><td>6</td><td>01:57.4</td><td>4</td><td>03:16.9</td><td>6</td></tr>
                    <tr><td>16</td><td>8143954</td><td>Raul Carrillo</td><td>&nbsp;</td><td>&nbsp;</td><td>Beginner Men Open </td><td>16:20.2</td><td>5</td><td>+4:48.72</td><td>04:08.1</td><td>6</td><td>05:28.6</td><td>5</td><td>02:16.2</td><td>5</td><td>01:54.9</td><td>3</td><td>02:32.3</td><td>4</td></tr>
                    <tr><td>77</td><td>8032623</td><td>Lloyd Hamilton</td><td>Lloyd Hamilton Construction</td><td>EL </td><td>Beginner Men Open </td><td>16:46.6</td><td>6</td><td>+5:15.16</td><td>03:55.7</td><td>5</td><td>05:30.0</td><td>6</td><td>02:07.4</td><td>4</td><td>02:35.1</td><td>6</td><td>02:38.2</td><td>5</td></tr>
                    <tr><td>33</td><td>8143966</td><td>Eduardo Guerra</td><td>&nbsp;</td><td>EL </td><td>Beginner Men Open </td><td>23:02.8</td><td>7</td><td>+11:31.29</td><td>05:20.1</td><td>7</td><td>07:35.7</td><td>7</td><td>03:25.7</td><td>7</td><td>02:52.5</td><td>7</td><td>03:48.6</td><td>7</td></tr>
                    <tr><td>36</td><td>8032666</td><td>Fernanda Polanco </td><td>Handske</td><td>&nbsp;</td><td>Women Open</td><td>13:28.9</td><td>1</td><td>&nbsp;</td><td>03:09.9</td><td>1</td><td>04:38.6</td><td>1</td><td>01:40.6</td><td>2</td><td>01:42.4</td><td>1</td><td>02:17.3</td><td>2</td></tr>
                    <tr><td>29</td><td>8032648</td><td>Kim Waechter</td><td>&nbsp;</td><td>EL </td><td>Women Open</td><td>14:25.8</td><td>2</td><td>+0:56.90</td><td>03:16.9</td><td>3</td><td>05:04.4</td><td>3</td><td>01:42.5</td><td>3</td><td>02:11.3</td><td>4</td><td>02:10.5</td><td>1</td></tr>
                    <tr><td>52</td><td>8032649</td><td>Jennifer Morrow</td><td>&nbsp;</td><td>LAS</td><td>Women Open</td><td>14:28.5</td><td>3</td><td>+0:59.58</td><td>03:19.3</td><td>4</td><td>05:13.1</td><td>5</td><td>01:27.7</td><td>1</td><td>02:03.2</td><td>3</td><td>02:25.0</td><td>3</td></tr>
                    <tr><td>16</td><td>8032656</td><td>Norma Rivera</td><td>Last Sundays Ride</td><td>EL </td><td>Women Open</td><td>14:35.3</td><td>4</td><td>+1:06.39</td><td>03:12.7</td><td>2</td><td>04:54.0</td><td>2</td><td>01:43.5</td><td>4</td><td>02:14.1</td><td>5</td><td>02:30.8</td><td>5</td></tr>
                    <tr><td>48</td><td>8032645</td><td>Genesis Garcia</td><td>Crazy Cat Cyclery</td><td>&nbsp;</td><td>Women Open</td><td>17:44.8</td><td>5</td><td>+4:15.87</td><td>06:25.0</td><td>5</td><td>05:05.6</td><td>4</td><td>01:43.9</td><td>5</td><td>02:02.5</td><td>2</td><td>02:27.6</td><td>4</td></tr>
                    <tr><td>20</td><td>8032665</td><td>Perla Reyes</td><td>Last Sunday Ride</td><td>&nbsp;</td><td>Women Open</td><td>19:46.7</td><td>6</td><td>+6:17.80</td><td>06:38.7</td><td>6</td><td>05:33.2</td><td>6</td><td>02:00.2</td><td>6</td><td>02:15.8</td><td>6</td><td>03:18.6</td><td>6</td></tr>
                    <tr><td>24</td><td>8032634</td><td>Ramon Gamez</td><td>&nbsp;</td><td>EL </td><td>18-29 Men</td><td>11:34.0</td><td>1</td><td>&nbsp;</td><td>02:46.4</td><td>1</td><td>04:07.4</td><td>3</td><td>01:15.2</td><td>1</td><td>01:42.3</td><td>2</td><td>01:42.5</td><td>2</td></tr>
                    <tr><td>5</td><td>8032632</td><td>Blas Meza</td><td>&nbsp;</td><td>&nbsp;</td><td>18-29 Men</td><td>11:42.1</td><td>2</td><td>+0:08.12</td><td>02:57.4</td><td>2</td><td>03:48.5</td><td>1</td><td>01:37.9</td><td>4</td><td>01:31.8</td><td>1</td><td>01:46.3</td><td>3</td></tr>
                    <tr><td>42</td><td>8032612</td><td>Luis Encerrado</td><td>Handske</td><td>&nbsp;</td><td>18-29 Men</td><td>12:57.1</td><td>3</td><td>+1:23.12</td><td>03:58.4</td><td>4</td><td>04:00.9</td><td>2</td><td>01:19.7</td><td>2</td><td>02:04.6</td><td>4</td><td>01:33.4</td><td>1</td></tr>
                    <tr><td>14</td><td>8032616</td><td>Issac Munoz</td><td>&nbsp;</td><td>EL </td><td>18-29 Men</td><td>13:06.2</td><td>4</td><td>+1:32.24</td><td>03:07.0</td><td>3</td><td>04:18.8</td><td>4</td><td>01:29.6</td><td>3</td><td>01:44.8</td><td>3</td><td>02:25.9</td><td>4</td></tr>
                    <tr><td>38</td><td>8143971</td><td>Will Palafox</td><td>Podium Finish Sport Boutique</td><td>EL </td><td>30-39 Men</td><td>10:09.1</td><td>1</td><td>&nbsp;</td><td>02:38.4</td><td>1</td><td>03:32.0</td><td>1</td><td>01:08.6</td><td>4</td><td>01:18.7</td><td>4</td><td>01:31.3</td><td>5</td></tr>
                    <tr><td>39</td><td>8032646</td><td>Eddie Sanchez</td><td>EP Enduro/ NorthCrew / The Shock Lab</td><td>EL </td><td>30-39 Men</td><td>10:17.9</td><td>2</td><td>+0:08.78</td><td>02:44.8</td><td>3</td><td>03:49.4</td><td>2</td><td>01:01.4</td><td>2</td><td>01:17.5</td><td>3</td><td>01:24.6</td><td>3</td></tr>
                    <tr><td>69</td><td>8032642</td><td>Wayne Shaffer</td><td>Bicycle Experience</td><td>&nbsp;</td><td>30-39 Men</td><td>10:31.5</td><td>3</td><td>+0:22.41</td><td>02:51.2</td><td>5</td><td>03:50.3</td><td>3</td><td>01:07.2</td><td>3</td><td>01:22.5</td><td>5</td><td>01:20.1</td><td>1</td></tr>
                    <tr><td>26</td><td>8143978</td><td>John Shredsalot</td><td>SNMTB/ The Shock Lab / EP Enduro</td><td>LAS</td><td>30-39 Men</td><td>12:17.1</td><td>4</td><td>+2:08.06</td><td>02:46.4</td><td>4</td><td>05:55.8</td><td>9</td><td>00:58.7</td><td>1</td><td>01:13.0</td><td>2</td><td>01:23.1</td><td>2</td></tr>
                    <tr><td>17</td><td>8143965</td><td>Hector Rodriguez</td><td>&nbsp;</td><td>&nbsp;</td><td>30-39 Men</td><td>13:09.8</td><td>5</td><td>+3:00.76</td><td>03:10.5</td><td>6</td><td>04:21.4</td><td>4</td><td>01:45.4</td><td>8</td><td>02:11.3</td><td>9</td><td>01:41.1</td><td>6</td></tr>
                    <tr><td>4</td><td>8032653</td><td>Christian Ottobre</td><td>Donkey Show Bicycle Club</td><td>&nbsp;</td><td>30-39 Men</td><td>13:16.5</td><td>6</td><td>+3:07.38</td><td>02:43.0</td><td>2</td><td>06:42.0</td><td>10</td><td>01:11.2</td><td>5</td><td>01:12.8</td><td>1</td><td>01:27.4</td><td>4</td></tr>
                    <tr><td>2</td><td>8032654</td><td>Angel Valles</td><td>&nbsp;</td><td>&nbsp;</td><td>30-39 Men</td><td>13:34.9</td><td>7</td><td>+3:25.87</td><td>03:22.8</td><td>9</td><td>04:36.2</td><td>6</td><td>01:52.3</td><td>9</td><td>01:49.1</td><td>8</td><td>01:54.4</td><td>8</td></tr>
                    <tr><td>37</td><td>8032611</td><td>Christopher Beversdorf</td><td>TACOCAT</td><td>EL </td><td>30-39 Men</td><td>14:11.1</td><td>8</td><td>+4:02.02</td><td>03:15.6</td><td>7</td><td>05:41.2</td><td>8</td><td>01:31.0</td><td>6</td><td>01:36.0</td><td>7</td><td>02:07.2</td><td>10</td></tr>
                    <tr><td>90</td><td>8032630</td><td>Moses Teliz</td><td>&nbsp;</td><td>&nbsp;</td><td>30-39 Men</td><td>18:10.4</td><td>9</td><td>+8:01.35</td><td>06:41.0</td><td>10</td><td>04:39.5</td><td>7</td><td>01:35.6</td><td>7</td><td>03:25.3</td><td>10</td><td>01:48.9</td><td>7</td></tr>
                    <tr><td>41</td><td>8143980</td><td>Arturo Garcia</td><td>&nbsp;</td><td>&nbsp;</td><td>30-39 Men</td><td>20:34.6</td><td>10</td><td>+10:25.49</td><td>03:21.3</td><td>8</td><td>04:31.9</td><td>5</td><td>09:12.5</td><td>10</td><td>01:32.3</td><td>6</td><td>01:56.4</td><td>9</td></tr>
                    <tr><td>3</td><td>8032675</td><td>Drew Drennan</td><td>&nbsp;</td><td>SPR</td><td>30-39 Men</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>06:43.4</td><td>11</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                    <tr><td>23</td><td>8032625</td><td>Arnold Rodriguez</td><td>Chuco MTB</td><td>EL </td><td>40-49 Men</td><td>09:47.4</td><td>1</td><td>&nbsp;</td><td>02:36.3</td><td>2</td><td>03:28.8</td><td>2</td><td>01:01.5</td><td>1</td><td>01:19.1</td><td>3</td><td>01:21.6</td><td>1</td></tr>
                    <tr><td>40</td><td>8143982</td><td>Sergio Acosta </td><td>&nbsp;</td><td>&nbsp;</td><td>40-49 Men</td><td>10:10.6</td><td>2</td><td>+0:23.14</td><td>02:34.9</td><td>1</td><td>03:27.4</td><td>1</td><td>01:23.2</td><td>3</td><td>01:13.8</td><td>2</td><td>01:31.1</td><td>3</td></tr>
                    <tr><td>34</td><td>8032622</td><td>Mathieu Maillon</td><td>&nbsp;</td><td>&nbsp;</td><td>40-49 Men</td><td>12:11.5</td><td>3</td><td>+2:24.08</td><td>03:05.4</td><td>4</td><td>04:20.8</td><td>4</td><td>01:29.8</td><td>4</td><td>01:33.5</td><td>4</td><td>01:41.8</td><td>4</td></tr>
                    <tr><td>47</td><td>8032619</td><td>Brian Canales</td><td>Desert Downs </td><td>EL </td><td>40-49 Men</td><td>13:22.1</td><td>4</td><td>+3:34.66</td><td>02:59.1</td><td>3</td><td>04:25.1</td><td>5</td><td>01:35.8</td><td>5</td><td>02:01.6</td><td>5</td><td>02:20.3</td><td>5</td></tr>
                    <tr><td>53</td><td>8032674</td><td>Mark Nevarez</td><td>&nbsp;</td><td>LAS</td><td>40-49 Men</td><td>13:37.6</td><td>5</td><td>+3:50.19</td><td>06:05.1</td><td>5</td><td>03:36.9</td><td>3</td><td>01:17.9</td><td>2</td><td>01:12.0</td><td>1</td><td>01:25.6</td><td>2</td></tr>
                    <tr><td>9</td><td>8032609</td><td>Mario Lopez</td><td>Podium Finish </td><td>EL </td><td>50+ Men</td><td>12:14.2</td><td>1</td><td>&nbsp;</td><td>03:01.1</td><td>1</td><td>04:13.0</td><td>1</td><td>01:34.6</td><td>1</td><td>01:37.3</td><td>1</td><td>01:48.0</td><td>1</td></tr>
                    <tr><td>15</td><td>8032647</td><td>Miguel Rico</td><td>&nbsp;</td><td>EL </td><td>50+ Men</td><td>20:56.2</td><td>2</td><td>+8:42.06</td><td>04:40.5</td><td>2</td><td>06:07.5</td><td>2</td><td>02:45.4</td><td>2</td><td>02:45.5</td><td>2</td><td>04:37.1</td><td>2</td></tr>
                    <tr><td>31</td><td>8032676</td><td>Tom Baumann</td><td>The Flying Cannoli</td><td>EL </td><td>50+ Men</td><td>29:58.9</td><td>3</td><td>+17:44.74</td><td>05:13.6</td><td>3</td><td>09:26.0</td><td>3</td><td>04:11.5</td><td>3</td><td>03:20.5</td><td>3</td><td>07:47.2</td><td>3</td></tr>
                    <tr><td>11</td><td>8143950</td><td>Lee Shamaley</td><td>&nbsp;</td><td>&nbsp;</td><td>E-Bike</td><td>13:33.1</td><td>1</td><td>&nbsp;</td><td>02:41.1</td><td>1</td><td>05:33.0</td><td>1</td><td>01:25.7</td><td>1</td><td>01:44.7</td><td>1</td><td>02:08.5</td><td>1</td></tr>
                    <tr><td>27</td><td>8143956</td><td>Fabrizzio Delgado</td><td>&nbsp;</td><td>&nbsp;</td><td>E-Bike</td><td>22:58.0</td><td>2</td><td>+9:24.95</td><td>05:12.2</td><td>2</td><td>06:00.9</td><td>2</td><td>03:42.6</td><td>2</td><td>03:17.1</td><td>2</td><td>04:45.1</td><td>2</td></tr>
                    <tr><td>30</td><td>8032613</td><td>Humberto Arevalo</td><td>The shock lab</td><td>EL </td><td>Expert Men</td><td>08:15.0</td><td>1</td><td>&nbsp;</td><td>02:18.5</td><td>1</td><td>02:55.1</td><td>1</td><td>00:51.5</td><td>1</td><td>01:03.9</td><td>2</td><td>01:05.9</td><td>1</td></tr>
                    <tr><td>49</td><td>8032635</td><td>Edgar Ramirez</td><td>The Shock Lab</td><td>EL </td><td>Expert Men</td><td>08:36.2</td><td>2</td><td>+0:21.19</td><td>02:19.1</td><td>2</td><td>03:10.3</td><td>2</td><td>00:51.7</td><td>2</td><td>01:01.6</td><td>1</td><td>01:13.4</td><td>5</td></tr>
                    <tr><td>6</td><td>8032633</td><td>Ivan Valdez </td><td>Salt Cycles</td><td>&nbsp;</td><td>Expert Men</td><td>08:45.2</td><td>3</td><td>+0:30.26</td><td>02:22.8</td><td>4</td><td>03:15.4</td><td>4</td><td>00:52.0</td><td>3</td><td>01:04.1</td><td>3</td><td>01:10.7</td><td>3</td></tr>
                    <tr><td>7</td><td>8032610</td><td>Eric Sotomajor</td><td>The Shock Lab</td><td>EL </td><td>Expert Men</td><td>09:01.7</td><td>4</td><td>+0:46.72</td><td>02:21.0</td><td>3</td><td>03:31.9</td><td>6</td><td>00:53.1</td><td>4</td><td>01:06.7</td><td>5</td><td>01:08.7</td><td>2</td></tr>
                    <tr><td>25</td><td>8032618</td><td>Joel Lujan</td><td>The Shock Lab</td><td>EL </td><td>Expert Men</td><td>09:08.2</td><td>5</td><td>+0:53.26</td><td>02:37.6</td><td>6</td><td>03:11.9</td><td>3</td><td>00:55.1</td><td>5</td><td>01:10.7</td><td>7</td><td>01:12.7</td><td>4</td></tr>
                    <tr><td>18</td><td>8032672</td><td>Alejandro Soto </td><td>Dog Speed</td><td>&nbsp;</td><td>Expert Men</td><td>09:43.0</td><td>6</td><td>+1:27.99</td><td>02:38.2</td><td>7</td><td>03:26.4</td><td>5</td><td>01:12.0</td><td>9</td><td>01:08.9</td><td>6</td><td>01:17.2</td><td>7</td></tr>
                    <tr><td>28</td><td>8143957</td><td>Jos√© Garcia</td><td>The Shock Lab</td><td>&nbsp;</td><td>Expert Men</td><td>09:46.7</td><td>7</td><td>+1:31.74</td><td>02:38.6</td><td>8</td><td>03:36.5</td><td>7</td><td>00:55.9</td><td>6</td><td>01:14.1</td><td>8</td><td>01:21.5</td><td>9</td></tr>
                    <tr><td>32</td><td>8032668</td><td>Byrl Hunt Burdick IV</td><td>EP Enduro</td><td>EL </td><td>Expert Men</td><td>09:49.5</td><td>8</td><td>+1:34.53</td><td>02:35.2</td><td>5</td><td>03:38.1</td><td>8</td><td>00:58.1</td><td>7</td><td>01:21.5</td><td>11</td><td>01:16.5</td><td>6</td></tr>
                    <tr><td>21</td><td>8032677</td><td>Ricardo Granados</td><td>&nbsp;</td><td>&nbsp;</td><td>Expert Men</td><td>10:26.9</td><td>9</td><td>+2:11.93</td><td>02:43.7</td><td>10</td><td>03:56.6</td><td>10</td><td>01:02.4</td><td>8</td><td>01:14.8</td><td>9</td><td>01:29.1</td><td>10</td></tr>
                    <tr><td>88</td><td>8032652</td><td>Rene Oineza</td><td>Southern New Mexico MTB (SNMTB)</td><td>LAS</td><td>Expert Men</td><td>10:43.1</td><td>10</td><td>+2:28.11</td><td>02:46.2</td><td>11</td><td>03:50.6</td><td>9</td><td>01:16.1</td><td>10</td><td>01:18.2</td><td>10</td><td>01:31.8</td><td>11</td></tr>
                    <tr><td>1</td><td>8032655</td><td>Ricky Scoles</td><td>Desert Downs</td><td>&nbsp;</td><td>Expert Men</td><td>16:26.9</td><td>11</td><td>+8:11.89</td><td>02:38.9</td><td>9</td><td>08:38.7</td><td>11</td><td>02:30.3</td><td>11</td><td>01:05.6</td><td>4</td><td>01:33.2</td><td>12</td></tr>
                    <tr><td>44</td><td>8032662</td><td>Pablo Fernandez</td><td>&nbsp;</td><td>EL </td><td>Expert Men</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>06:22.6</td><td>12</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>01:18.1</td><td>8</td></tr>
                    <tr><td>45</td><td>8032614</td><td>Alexander Gutierrez</td><td>&nbsp;</td><td>EL </td><td>JR Boys U17</td><td>09:51.8</td><td>1</td><td>&nbsp;</td><td>02:40.1</td><td>1</td><td>03:36.7</td><td>1</td><td>01:01.1</td><td>1</td><td>01:15.5</td><td>1</td><td>01:18.2</td><td>1</td></tr>
                    <tr><td>10</td><td>8032640</td><td>Wyatt Ivey</td><td>&nbsp;</td><td>&nbsp;</td><td>JR Boys U17</td><td>12:02.1</td><td>2</td><td>+2:10.30</td><td>02:56.6</td><td>2</td><td>04:57.3</td><td>2</td><td>01:10.5</td><td>3</td><td>01:27.7</td><td>3</td><td>01:29.8</td><td>3</td></tr>
                    <tr><td>55</td><td>8032629</td><td>Maximiliano Guerra</td><td>&nbsp;</td><td>EL </td><td>JR Boys U17</td><td>13:36.6</td><td>3</td><td>+3:44.81</td><td>03:32.6</td><td>4</td><td>05:09.5</td><td>3</td><td>01:20.3</td><td>4</td><td>01:34.7</td><td>4</td><td>01:59.4</td><td>4</td></tr>
                    <tr><td>12</td><td>8032615</td><td>Andrew Fernandez</td><td>&nbsp;</td><td>EL </td><td>JR Boys U17</td><td>19:50.7</td><td>4</td><td>+9:58.99</td><td>03:16.3</td><td>3</td><td>12:34.4</td><td>4</td><td>01:08.9</td><td>2</td><td>01:23.8</td><td>2</td><td>01:27.1</td><td>2</td></tr>
                </tbody></table>
                <CSVLink className="downloadLink" data={csvDataLostDog}>Download Results</CSVLink>   
            </div>
        </div>
        </div>
    )
}
