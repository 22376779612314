import React from 'react'
import './franklin.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvDataFranklin = [
    {
      RaceNumber: "41",
      Name: "Luis Encerrado ",
      Club: "Hansdke",
      CourseClass: "18-29 Men",
      RaceTime: "18:42.0",
      Position: "1",
      BehindTime: "",
      Stage1Time: "04:05.0",
      Stage1Pos: "1",
      Stage2Time: "01:42.5",
      Stage2Pos: "3",
      Stage3Time: "01:57.1",
      Stage3Pos: "2",
      Stage4Time: "03:16.4",
     Stage4Pos: "3",
      Stage5Time: "03:05.0",
      Stage5Pos: "1",
     Stage6Time: "04:35.9",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "36",
      Name: "Blas Meza",
      Club: "One Trail at a time ",
      CourseClass: "18-29 Men",
      RaceTime: "19:10.6",
      Position: "2",
      BehindTime: "+0:28.55",
      Stage1Time: "04:11.5",
      Stage1Pos: "3",
      Stage2Time: "01:41.8",
      Stage2Pos: "2",
      Stage3Time: "02:01.8",
      Stage3Pos: "3",
      Stage4Time: "03:15.0",
     Stage4Pos: "2",
      Stage5Time: "03:05.2",
      Stage5Pos: "2",
     Stage6Time: "04:55.0",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "10",
      Name: "Todge Cooper",
      Club: "Banshee Bikes, DVO, Deity",
      CourseClass: "18-29 Men",
      RaceTime: "19:55.0",
      Position: "3",
      BehindTime: "+1:12.93",
      Stage1Time: "04:05.6",
      Stage1Pos: "2",
      Stage2Time: "01:41.2",
      Stage2Pos: "1",
      Stage3Time: "01:56.7",
      Stage3Pos: "1",
      Stage4Time: "03:12.4",
     Stage4Pos: "1",
      Stage5Time: "03:16.8",
      Stage5Pos: "5",
     Stage6Time: "05:42.1",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "89",
      Name: "Ramon Gamez",
      Club: "",
      CourseClass: "18-29 Men",
      RaceTime: "20:21.9",
      Position: "4",
      BehindTime: "+1:39.90",
      Stage1Time: "04:19.6",
      Stage1Pos: "5",
      Stage2Time: "01:54.7",
      Stage2Pos: "5",
      Stage3Time: "02:12.9",
      Stage3Pos: "5",
      Stage4Time: "03:24.7",
     Stage4Pos: "4",
      Stage5Time: "03:16.3",
      Stage5Pos: "4",
     Stage6Time: "05:13.6",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "23",
      Name: "Luis Gallegos",
      Club: "",
      CourseClass: "18-29 Men",
      RaceTime: "20:35.5",
      Position: "5",
      BehindTime: "+1:53.41",
      Stage1Time: "04:18.1",
      Stage1Pos: "4",
      Stage2Time: "01:56.2",
      Stage2Pos: "6",
      Stage3Time: "02:04.1",
      Stage3Pos: "4",
      Stage4Time: "03:29.5",
     Stage4Pos: "5",
      Stage5Time: "03:26.6",
      Stage5Pos: "6",
     Stage6Time: "05:20.8",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "25",
      Name: "Efren Carranza",
      Club: "The Shock Lab",
      CourseClass: "18-29 Men",
      RaceTime: "20:49.6",
      Position: "6",
      BehindTime: "+2:07.60",
      Stage1Time: "04:24.2",
      Stage1Pos: "7",
      Stage2Time: "01:50.8",
      Stage2Pos: "4",
      Stage3Time: "02:17.7",
      Stage3Pos: "6",
      Stage4Time: "03:31.2",
     Stage4Pos: "6",
      Stage5Time: "03:16.1",
      Stage5Pos: "3",
     Stage6Time: "05:29.4",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "24",
      Name: "Ricardo Sanchez",
      Club: "Onin Staffing",
      CourseClass: "18-29 Men",
      RaceTime: "23:17.9",
      Position: "7",
      BehindTime: "+4:35.84",
      Stage1Time: "04:21.6",
      Stage1Pos: "6",
      Stage2Time: "02:14.0",
      Stage2Pos: "7",
      Stage3Time: "02:58.9",
      Stage3Pos: "7",
      Stage4Time: "03:41.0",
     Stage4Pos: "7",
      Stage5Time: "03:35.9",
      Stage5Pos: "7",
     Stage6Time: "06:26.4",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "28",
      Name: "David Chacon",
      Club: "Northcrew",
      CourseClass: "30-39 Men",
      RaceTime: "18:24.5",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:53.7",
      Stage1Pos: "1",
      Stage2Time: "01:33.8",
      Stage2Pos: "1",
      Stage3Time: "01:46.4",
      Stage3Pos: "1",
      Stage4Time: "03:04.6",
     Stage4Pos: "1",
      Stage5Time: "03:03.9",
      Stage5Pos: "2",
     Stage6Time: "05:01.8",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "33",
      Name: "Kevin Dunn",
      Club: "Ride It Out",
      CourseClass: "30-39 Men",
      RaceTime: "19:07.3",
      Position: "2",
      BehindTime: "+0:42.84",
      Stage1Time: "04:09.6",
      Stage1Pos: "4",
      Stage2Time: "01:38.5",
      Stage2Pos: "3",
      Stage3Time: "02:06.6",
      Stage3Pos: "6",
      Stage4Time: "03:21.8",
     Stage4Pos: "5",
      Stage5Time: "02:59.8",
      Stage5Pos: "1",
     Stage6Time: "04:50.8",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "67",
      Name: "Will Palafox",
      Club: "Podium Finish",
      CourseClass: "30-39 Men",
      RaceTime: "19:08.2",
      Position: "3",
      BehindTime: "+0:43.73",
      Stage1Time: "04:04.0",
      Stage1Pos: "2",
      Stage2Time: "01:42.5",
      Stage2Pos: "4",
      Stage3Time: "01:56.3",
      Stage3Pos: "3",
      Stage4Time: "03:18.5",
     Stage4Pos: "4",
      Stage5Time: "03:14.6",
      Stage5Pos: "5",
     Stage6Time: "04:52.2",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "82",
      Name: "Wayne Shaffer",
      Club: "Bicycle Experience",
      CourseClass: "30-39 Men",
      RaceTime: "19:16.1",
      Position: "4",
      BehindTime: "+0:51.62",
      Stage1Time: "04:07.0",
      Stage1Pos: "3",
      Stage2Time: "01:37.6",
      Stage2Pos: "2",
      Stage3Time: "01:51.3",
      Stage3Pos: "2",
      Stage4Time: "03:12.2",
     Stage4Pos: "2",
      Stage5Time: "03:12.7",
      Stage5Pos: "3",
     Stage6Time: "05:15.0",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "26",
      Name: "John Shredsalot",
      Club: "Merol Racing / EP ENDURO",
      CourseClass: "30-39 Men",
      RaceTime: "19:58.4",
      Position: "5",
      BehindTime: "+1:33.96",
      Stage1Time: "04:13.7",
      Stage1Pos: "6",
      Stage2Time: "01:42.8",
      Stage2Pos: "5",
      Stage3Time: "02:05.3",
      Stage3Pos: "5",
      Stage4Time: "03:22.9",
     Stage4Pos: "6",
      Stage5Time: "03:13.5",
      Stage5Pos: "4",
     Stage6Time: "05:20.1",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "50",
      Name: "Jesus Arellano",
      Club: "North Crew/Shock Lab/EP Enduro",
      CourseClass: "30-39 Men",
      RaceTime: "20:51.1",
      Position: "6",
      BehindTime: "+2:26.68",
      Stage1Time: "04:31.6",
      Stage1Pos: "9",
      Stage2Time: "01:51.6",
      Stage2Pos: "7",
      Stage3Time: "01:59.2",
      Stage3Pos: "4",
      Stage4Time: "03:18.4",
     Stage4Pos: "3",
      Stage5Time: "03:23.1",
      Stage5Pos: "6",
     Stage6Time: "05:47.1",
      Stage6Pos: "8"
    },
    {
      RaceNumber: "69",
      Name: "Eddie Sanchez",
      Club: "Northcrew/The Shock Lab/Merol Racing",
      CourseClass: "30-39 Men",
      RaceTime: "20:54.1",
      Position: "7",
      BehindTime: "+2:29.66",
      Stage1Time: "04:09.6",
      Stage1Pos: "5",
      Stage2Time: "01:45.0",
      Stage2Pos: "6",
      Stage3Time: "02:12.2",
      Stage3Pos: "8",
      Stage4Time: "03:23.0",
     Stage4Pos: "7",
      Stage5Time: "03:25.7",
      Stage5Pos: "7",
     Stage6Time: "05:58.5",
      Stage6Pos: "11"
    },
    {
      RaceNumber: "48",
      Name: "Justin Duez",
      Club: "Banshee Bikes",
      CourseClass: "30-39 Men",
      RaceTime: "21:17.3",
      Position: "8",
      BehindTime: "+2:52.84",
      Stage1Time: "04:28.4",
      Stage1Pos: "7",
      Stage2Time: "01:54.7",
      Stage2Pos: "8",
      Stage3Time: "02:08.9",
      Stage3Pos: "7",
      Stage4Time: "03:32.9",
     Stage4Pos: "8",
      Stage5Time: "03:29.2",
      Stage5Pos: "8",
     Stage6Time: "05:43.0",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "6",
      Name: "Andre Von Twistern",
      Club: "Honey Bear Racing/ OceAndre",
      CourseClass: "30-39 Men",
      RaceTime: "21:50.8",
      Position: "9",
      BehindTime: "+3:26.39",
      Stage1Time: "04:29.2",
      Stage1Pos: "8",
      Stage2Time: "02:09.9",
      Stage2Pos: "11",
      Stage3Time: "02:31.4",
      Stage3Pos: "11",
      Stage4Time: "03:38.8",
     Stage4Pos: "10",
      Stage5Time: "03:30.1",
      Stage5Pos: "9",
     Stage6Time: "05:31.2",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "51",
      Name: "Arturto Garcia",
      Club: "Ciclismo Juarez",
      CourseClass: "30-39 Men",
      RaceTime: "22:04.5",
      Position: "10",
      BehindTime: "+3:40.02",
      Stage1Time: "04:39.1",
      Stage1Pos: "11",
      Stage2Time: "01:55.7",
      Stage2Pos: "9",
      Stage3Time: "02:27.7",
      Stage3Pos: "10",
      Stage4Time: "03:37.2",
     Stage4Pos: "9",
      Stage5Time: "03:36.3",
      Stage5Pos: "11",
     Stage6Time: "05:48.2",
      Stage6Pos: "9"
    },
    {
      RaceNumber: "90",
      Name: "Moises Teliz",
      Club: "Smaz remodeling",
      CourseClass: "30-39 Men",
      RaceTime: "22:29.5",
      Position: "11",
      BehindTime: "+4:05.05",
      Stage1Time: "04:36.6",
      Stage1Pos: "10",
      Stage2Time: "02:06.8",
      Stage2Pos: "10",
      Stage3Time: "02:26.0",
      Stage3Pos: "9",
      Stage4Time: "03:52.7",
     Stage4Pos: "11",
      Stage5Time: "03:33.3",
      Stage5Pos: "10",
     Stage6Time: "05:54.0",
      Stage6Pos: "10"
    },
    {
      RaceNumber: "8",
      Name: "Sergio Acosta",
      Club: "Ciclismo Juarez",
      CourseClass: "40-49 Men",
      RaceTime: "18:20.9",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:52.4",
      Stage1Pos: "2",
      Stage2Time: "01:33.9",
      Stage2Pos: "1",
      Stage3Time: "01:49.1",
      Stage3Pos: "1",
      Stage4Time: "03:05.8",
     Stage4Pos: "2",
      Stage5Time: "03:01.0",
      Stage5Pos: "2",
     Stage6Time: "04:58.5",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "30",
      Name: "Luke Cutburth",
      Club: "Lost Dog Chain Breakers",
      CourseClass: "40-49 Men",
      RaceTime: "18:23.8",
      Position: "2",
      BehindTime: "+0:02.88",
      Stage1Time: "03:52.2",
      Stage1Pos: "1",
      Stage2Time: "01:44.2",
      Stage2Pos: "4",
      Stage3Time: "01:54.5",
      Stage3Pos: "2",
      Stage4Time: "03:02.4",
     Stage4Pos: "1",
      Stage5Time: "03:00.2",
      Stage5Pos: "1",
     Stage6Time: "04:50.1",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "81",
      Name: "Mark Nevarez",
      Club: "NorthCrew",
      CourseClass: "40-49 Men",
      RaceTime: "19:20.8",
      Position: "3",
      BehindTime: "+0:59.94",
      Stage1Time: "04:02.9",
      Stage1Pos: "3",
      Stage2Time: "01:41.7",
      Stage2Pos: "2",
      Stage3Time: "01:57.5",
      Stage3Pos: "3",
      Stage4Time: "03:14.6",
     Stage4Pos: "4",
      Stage5Time: "03:14.4",
      Stage5Pos: "5",
     Stage6Time: "05:09.5",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "55",
      Name: "Octavio Gonzalez",
      Club: "Embrace the Suck",
      CourseClass: "40-49 Men",
      RaceTime: "20:23.8",
      Position: "4",
      BehindTime: "+2:02.95",
      Stage1Time: "04:10.5",
      Stage1Pos: "4",
      Stage2Time: "01:45.8",
      Stage2Pos: "5",
      Stage3Time: "02:10.2",
      Stage3Pos: "5",
      Stage4Time: "03:22.4",
     Stage4Pos: "5",
      Stage5Time: "03:13.8",
      Stage5Pos: "4",
     Stage6Time: "05:40.9",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "20",
      Name: "Arnold Rodriguez",
      Club: "Chuco MTB",
      CourseClass: "40-49 Men",
      RaceTime: "21:09.6",
      Position: "5",
      BehindTime: "+2:48.74",
      Stage1Time: "06:03.0",
      Stage1Pos: "7",
      Stage2Time: "01:43.3",
      Stage2Pos: "3",
      Stage3Time: "01:58.3",
      Stage3Pos: "4",
      Stage4Time: "03:12.2",
     Stage4Pos: "3",
      Stage5Time: "03:03.7",
      Stage5Pos: "3",
     Stage6Time: "05:08.8",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "39",
      Name: "Jamie Mantle",
      Club: "",
      CourseClass: "40-49 Men",
      RaceTime: "24:05.5",
      Position: "6",
      BehindTime: "+5:44.59",
      Stage1Time: "04:50.4",
      Stage1Pos: "5",
      Stage2Time: "02:16.9",
      Stage2Pos: "6",
      Stage3Time: "02:55.8",
      Stage3Pos: "7",
      Stage4Time: "03:50.4",
     Stage4Pos: "6",
      Stage5Time: "04:10.7",
      Stage5Pos: "7",
     Stage6Time: "06:01.1",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "12",
      Name: "Fernando Garcia",
      Club: "",
      CourseClass: "40-49 Men",
      RaceTime: "24:50.6",
      Position: "7",
      BehindTime: "+6:29.73",
      Stage1Time: "04:53.7",
      Stage1Pos: "6",
      Stage2Time: "02:24.0",
      Stage2Pos: "7",
      Stage3Time: "02:54.3",
      Stage3Pos: "6",
      Stage4Time: "03:55.8",
     Stage4Pos: "7",
      Stage5Time: "03:51.2",
      Stage5Pos: "6",
     Stage6Time: "06:51.4",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "5",
      Name: "Clint Nelms",
      Club: "Podium Finish",
      CourseClass: "50+ Men",
      RaceTime: "18:34.7",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:52.8",
      Stage1Pos: "1",
      Stage2Time: "01:34.8",
      Stage2Pos: "1",
      Stage3Time: "01:52.5",
      Stage3Pos: "1",
      Stage4Time: "03:05.6",
     Stage4Pos: "1",
      Stage5Time: "03:03.6",
      Stage5Pos: "1",
     Stage6Time: "05:05.3",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "52",
      Name: "Sergio Pacheco",
      Club: "Zia Velo",
      CourseClass: "50+ Men",
      RaceTime: "20:18.2",
      Position: "2",
      BehindTime: "+1:43.45",
      Stage1Time: "04:17.0",
      Stage1Pos: "2",
      Stage2Time: "01:46.7",
      Stage2Pos: "2",
      Stage3Time: "02:22.1",
      Stage3Pos: "3",
      Stage4Time: "03:20.6",
     Stage4Pos: "2",
      Stage5Time: "03:18.3",
      Stage5Pos: "3",
     Stage6Time: "05:13.2",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "68",
      Name: "Mario Lopez",
      Club: "Podium Finish",
      CourseClass: "50+ Men",
      RaceTime: "20:24.2",
      Position: "3",
      BehindTime: "+1:49.43",
      Stage1Time: "04:19.3",
      Stage1Pos: "3",
      Stage2Time: "01:50.3",
      Stage2Pos: "3",
      Stage3Time: "02:22.0",
      Stage3Pos: "2",
      Stage4Time: "03:25.5",
     Stage4Pos: "3",
      Stage5Time: "03:10.4",
      Stage5Pos: "2",
     Stage6Time: "05:16.3",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "43",
      Name: "John Conrad",
      Club: "Merol Racing",
      CourseClass: "50+ Men",
      RaceTime: "23:07.7",
      Position: "4",
      BehindTime: "+4:32.96",
      Stage1Time: "04:31.8",
      Stage1Pos: "4",
      Stage2Time: "02:05.8",
      Stage2Pos: "5",
      Stage3Time: "02:46.1",
      Stage3Pos: "4",
      Stage4Time: "03:44.5",
     Stage4Pos: "4",
      Stage5Time: "03:42.7",
      Stage5Pos: "5",
     Stage6Time: "06:16.6",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "38",
      Name: "Martin Guillen",
      Club: "",
      CourseClass: "50+ Men",
      RaceTime: "23:09.0",
      Position: "5",
      BehindTime: "+4:34.29",
      Stage1Time: "04:47.1",
      Stage1Pos: "5",
      Stage2Time: "02:04.0",
      Stage2Pos: "4",
      Stage3Time: "03:12.8",
      Stage3Pos: "5",
      Stage4Time: "03:53.0",
     Stage4Pos: "5",
      Stage5Time: "03:40.8",
      Stage5Pos: "4",
     Stage6Time: "05:31.1",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "54",
      Name: "Miguel Rico",
      Club: "",
      CourseClass: "50+ Men",
      RaceTime: "26:47.5",
      Position: "6",
      BehindTime: "+8:12.76",
      Stage1Time: "05:19.9",
      Stage1Pos: "6",
      Stage2Time: "02:39.9",
      Stage2Pos: "6",
      Stage3Time: "03:18.4",
      Stage3Pos: "6",
      Stage4Time: "04:10.2",
     Stage4Pos: "6",
      Stage5Time: "04:53.1",
      Stage5Pos: "7",
     Stage6Time: "06:25.7",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "13",
      Name: "Tom Baumann",
      Club: "The Flying Cannoli",
      CourseClass: "50+ Men",
      RaceTime: "29:32.5",
      Position: "7",
      BehindTime: "+10:57.77",
      Stage1Time: "07:45.1",
      Stage1Pos: "7",
      Stage2Time: "02:41.3",
      Stage2Pos: "7",
      Stage3Time: "04:03.0",
      Stage3Pos: "7",
      Stage4Time: "04:35.3",
     Stage4Pos: "7",
      Stage5Time: "04:23.7",
      Stage5Pos: "6",
     Stage6Time: "06:03.9",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "27",
      Name: "Ricardo Lopez Iglesias",
      Club: "Middle of Nowhere",
      CourseClass: "Beginner Men",
      RaceTime: "21:20.8",
      Position: "1",
      BehindTime: "",
      Stage1Time: "04:31.3",
      Stage1Pos: "2",
      Stage2Time: "01:56.1",
      Stage2Pos: "4",
      Stage3Time: "02:19.8",
      Stage3Pos: "1",
      Stage4Time: "03:33.8",
     Stage4Pos: "1",
      Stage5Time: "03:27.3",
      Stage5Pos: "2",
     Stage6Time: "05:32.3",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "2",
      Name: "Luis Martinetti",
      Club: "Middle of Nowhere",
      CourseClass: "Beginner Men",
      RaceTime: "21:50.4",
      Position: "2",
      BehindTime: "+0:29.55",
      Stage1Time: "04:26.0",
      Stage1Pos: "1",
      Stage2Time: "01:53.4",
      Stage2Pos: "2",
      Stage3Time: "02:35.6",
      Stage3Pos: "2",
      Stage4Time: "03:43.4",
     Stage4Pos: "3",
      Stage5Time: "03:35.3",
      Stage5Pos: "5",
     Stage6Time: "05:36.4",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "74",
      Name: "Victor Cordero",
      Club: "",
      CourseClass: "Beginner Men",
      RaceTime: "21:52.4",
      Position: "3",
      BehindTime: "+0:31.55",
      Stage1Time: "04:38.5",
      Stage1Pos: "4",
      Stage2Time: "01:54.0",
      Stage2Pos: "3",
      Stage3Time: "02:37.9",
      Stage3Pos: "3",
      Stage4Time: "03:49.1",
     Stage4Pos: "4",
      Stage5Time: "03:26.1",
      Stage5Pos: "1",
     Stage6Time: "05:26.6",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "47",
      Name: "Jose Reyes",
      Club: "Merol Racing ",
      CourseClass: "Beginner Men",
      RaceTime: "21:54.2",
      Position: "4",
      BehindTime: "+0:33.42",
      Stage1Time: "04:31.5",
      Stage1Pos: "3",
      Stage2Time: "01:50.7",
      Stage2Pos: "1",
      Stage3Time: "02:52.4",
      Stage3Pos: "6",
      Stage4Time: "03:36.2",
     Stage4Pos: "2",
      Stage5Time: "03:28.6",
      Stage5Pos: "3",
     Stage6Time: "05:34.6",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "37",
      Name: "Cesar Gallegos",
      Club: "",
      CourseClass: "Beginner Men",
      RaceTime: "22:58.9",
      Position: "5",
      BehindTime: "+1:38.10",
      Stage1Time: "04:39.3",
      Stage1Pos: "5",
      Stage2Time: "02:03.9",
      Stage2Pos: "5",
      Stage3Time: "02:55.9",
      Stage3Pos: "7",
      Stage4Time: "03:52.4",
     Stage4Pos: "5",
      Stage5Time: "03:35.0",
      Stage5Pos: "4",
     Stage6Time: "05:52.2",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "1",
      Name: "Mario Holguin",
      Club: "Middle of nowhere",
      CourseClass: "Beginner Men",
      RaceTime: "23:22.2",
      Position: "6",
      BehindTime: "+2:01.37",
      Stage1Time: "04:56.8",
      Stage1Pos: "8",
      Stage2Time: "02:11.6",
      Stage2Pos: "6",
      Stage3Time: "02:52.0",
      Stage3Pos: "5",
      Stage4Time: "03:54.8",
     Stage4Pos: "7",
      Stage5Time: "03:49.3",
      Stage5Pos: "6",
     Stage6Time: "05:37.6",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "7",
      Name: "Roberto Estrada ",
      Club: "",
      CourseClass: "Beginner Men",
      RaceTime: "25:12.5",
      Position: "7",
      BehindTime: "+3:51.71",
      Stage1Time: "04:40.1",
      Stage1Pos: "6",
      Stage2Time: "03:15.9",
      Stage2Pos: "9",
      Stage3Time: "03:16.8",
      Stage3Pos: "8",
      Stage4Time: "03:52.9",
     Stage4Pos: "6",
      Stage5Time: "03:54.9",
      Stage5Pos: "7",
     Stage6Time: "06:11.7",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "34",
      Name: "Dago Nunez",
      Club: "",
      CourseClass: "Beginner Men",
      RaceTime: "26:04.1",
      Position: "8",
      BehindTime: "+4:43.32",
      Stage1Time: "05:06.0",
      Stage1Pos: "9",
      Stage2Time: "02:19.3",
      Stage2Pos: "7",
      Stage3Time: "02:51.7",
      Stage3Pos: "4",
      Stage4Time: "04:27.5",
     Stage4Pos: "8",
      Stage5Time: "04:06.7",
      Stage5Pos: "8",
     Stage6Time: "07:12.6",
      Stage6Pos: "9"
    },
    {
      RaceNumber: "35",
      Name: "Ruben Rivero",
      Club: "",
      CourseClass: "Beginner Men",
      RaceTime: "27:37.1",
      Position: "9",
      BehindTime: "+6:16.25",
      Stage1Time: "04:54.0",
      Stage1Pos: "7",
      Stage2Time: "02:23.6",
      Stage2Pos: "8",
      Stage3Time: "03:29.6",
      Stage3Pos: "9",
      Stage4Time: "05:11.2",
     Stage4Pos: "9",
      Stage5Time: "04:32.3",
      Stage5Pos: "9",
     Stage6Time: "07:06.2",
      Stage6Pos: "8"
    },
    {
      RaceNumber: "3",
      Name: "Mo Frias",
      Club: "Middle of nowhere",
      CourseClass: "Expert Men",
      RaceTime: "16:55.7",
      Position: "1",
      BehindTime: "",
      Stage1Time: "03:41.3",
      Stage1Pos: "2",
      Stage2Time: "01:26.8",
      Stage2Pos: "1",
      Stage3Time: "01:41.6",
      Stage3Pos: "1",
      Stage4Time: "02:54.8",
     Stage4Pos: "2",
      Stage5Time: "02:43.9",
      Stage5Pos: "1",
     Stage6Time: "04:26.9",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "22",
      Name: "Ivan Valdez",
      Club: "",
      CourseClass: "Expert Men",
      RaceTime: "17:19.6",
      Position: "2",
      BehindTime: "+0:23.98",
      Stage1Time: "03:39.9",
      Stage1Pos: "1",
      Stage2Time: "01:31.6",
      Stage2Pos: "5",
      Stage3Time: "01:46.7",
      Stage3Pos: "5",
      Stage4Time: "02:50.6",
      Stage4Pos: "1",
      Stage5Time: "02:50.9",
      Stage5Pos: "5",
     Stage6Time: "04:39.7",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "32",
      Name: "Humberto Arevalo",
      Club: "The Shock Lab",
      CourseClass: "Expert Men",
      RaceTime: "17:29.8",
      Position: "3",
      BehindTime: "+0:34.16",
      Stage1Time: "03:46.2",
      Stage1Pos: "3",
      Stage2Time: "01:28.5",
      Stage2Pos: "4",
      Stage3Time: "01:45.7",
      Stage3Pos: "4",
      Stage4Time: "02:55.5",
      Stage4Pos: "3",
      Stage5Time: "02:49.6",
      Stage5Pos: "3",
     Stage6Time: "04:44.2",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "77",
      Name: "Eric Sotomayor",
      Club: "The Shock Lab",
      CourseClass: "Expert Men",
      RaceTime: "17:32.7",
      Position: "4",
      BehindTime: "+0:37.05",
      Stage1Time: "03:46.6",
      Stage1Pos: "4",
      Stage2Time: "01:27.5",
      Stage2Pos: "2",
      Stage3Time: "01:44.4",
      Stage3Pos: "3",
      Stage4Time: "03:02.5",
      Stage4Pos: "5",
      Stage5Time: "02:48.3",
      Stage5Pos: "2",
     Stage6Time: "04:43.2",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "49",
      Name: "Edgar Ramirez",
      Club: "The Shock Lab",
      CourseClass: "Expert Men",
      RaceTime: "17:43.3",
      Position: "5",
      BehindTime: "+0:47.63",
      Stage1Time: "03:50.7",
      Stage1Pos: "6",
      Stage2Time: "01:28.4",
      Stage2Pos: "3",
      Stage3Time: "01:44.1",
      Stage3Pos: "2",
      Stage4Time: "02:56.6",
      Stage4Pos: "4",
      Stage5Time: "02:49.7",
      Stage5Pos: "4",
     Stage6Time: "04:53.5",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "31",
      Name: "Joel Lujan",
      Club: "The Shock Lab",
      CourseClass: "Expert Men",
      RaceTime: "18:59.8",
      Position: "6",
      BehindTime: "+2:04.19",
      Stage1Time: "03:48.9",
      Stage1Pos: "5",
      Stage2Time: "01:37.1",
      Stage2Pos: "6",
      Stage3Time: "02:17.9",
      Stage3Pos: "8",
      Stage4Time: "03:14.7",
      Stage4Pos: "6",
      Stage5Time: "03:04.6",
      Stage5Pos: "6",
     Stage6Time: "04:56.5",
      Stage6Pos: "6"
    },
    {
      RaceNumber: "17",
      Name: "Ricardo Granados",
      Club: "",
      CourseClass: "Expert Men",
      RaceTime: "19:57.2",
      Position: "7",
      BehindTime: "+3:01.51",
      Stage1Time: "04:12.5",
      Stage1Pos: "7",
      Stage2Time: "01:49.2",
      Stage2Pos: "8",
      Stage3Time: "02:07.0",
      Stage3Pos: "7",
      Stage4Time: "03:28.2",
      Stage4Pos: "8",
      Stage5Time: "03:11.9",
      Stage5Pos: "7",
     Stage6Time: "05:08.2",
      Stage6Pos: "7"
    },
    {
      RaceNumber: "66",
      Name: "Rene Oineza",
      Club: "Southern New Mexico MTB (SNMTB)",
      CourseClass: "Expert Men",
      RaceTime: "20:11.3",
      Position: "8",
      BehindTime: "+3:15.65",
      Stage1Time: "04:12.9",
      Stage1Pos: "8",
      Stage2Time: "01:40.7",
      Stage2Pos: "7",
      Stage3Time: "02:03.5",
      Stage3Pos: "6",
      Stage4Time: "03:22.2",
      Stage4Pos: "7",
      Stage5Time: "03:19.3",
      Stage5Pos: "8",
     Stage6Time: "05:32.6",
      Stage6Pos: "8"
    },
    {
      RaceNumber: "45",
      Name: "Alexander Gutierrez",
      Club: "",
      CourseClass: "JR Boys U17",
      RaceTime: "18:07.9",
      Position: "1",
      BehindTime: "",
      Stage1Time: "04:01.9",
      Stage1Pos: "1",
      Stage2Time: "01:34.9",
      Stage2Pos: "1",
      Stage3Time: "01:52.1",
      Stage3Pos: "1",
      Stage4Time: "02:58.3",
      Stage4Pos: "1",
      Stage5Time: "02:52.2",
      Stage5Pos: "1",
     Stage6Time: "04:48.2",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "88",
      Name: "Wyatt Ivey",
      Club: "",
      CourseClass: "JR Boys U17",
      RaceTime: "20:29.5",
      Position: "2",
      BehindTime: "+2:21.59",
      Stage1Time: "04:14.2",
      Stage1Pos: "2",
      Stage2Time: "01:56.9",
      Stage2Pos: "4",
      Stage3Time: "02:19.9",
      Stage3Pos: "4",
      Stage4Time: "03:18.4",
      Stage4Pos: "2",
      Stage5Time: "03:19.7",
      Stage5Pos: "2",
     Stage6Time: "05:20.1",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "21",
      Name: "Andrew Fernandez",
      Club: "",
      CourseClass: "JR Boys U17",
      RaceTime: "20:40.7",
      Position: "3",
      BehindTime: "+2:32.80",
      Stage1Time: "04:19.7",
      Stage1Pos: "4",
      Stage2Time: "01:54.1",
      Stage2Pos: "3",
      Stage3Time: "02:12.6",
      Stage3Pos: "2",
      Stage4Time: "03:25.9",
      Stage4Pos: "4",
      Stage5Time: "03:26.5",
      Stage5Pos: "3",
     Stage6Time: "05:21.6",
      Stage6Pos: "3"
    },
    {
      RaceNumber: "29",
      Name: "Alex Arellano",
      Club: "The Shock Lab",
      CourseClass: "JR Boys U17",
      RaceTime: "21:05.9",
      Position: "4",
      BehindTime: "+2:58.03",
      Stage1Time: "04:23.9",
      Stage1Pos: "5",
      Stage2Time: "01:48.4",
      Stage2Pos: "2",
      Stage3Time: "02:19.4",
      Stage3Pos: "3",
      Stage4Time: "03:22.2",
      Stage4Pos: "3",
      Stage5Time: "03:27.2",
      Stage5Pos: "4",
     Stage6Time: "05:44.7",
      Stage6Pos: "5"
    },
    {
      RaceNumber: "11",
      Name: "Max Guerra 2",
      Club: "",
      CourseClass: "JR Boys U17",
      RaceTime: "21:37.0",
      Position: "5",
      BehindTime: "+3:29.12",
      Stage1Time: "04:19.2",
      Stage1Pos: "3",
      Stage2Time: "02:02.2",
      Stage2Pos: "5",
      Stage3Time: "02:28.6",
      Stage3Pos: "5",
      Stage4Time: "03:38.1",
      Stage4Pos: "5",
      Stage5Time: "03:32.9",
      Stage5Pos: "5",
     Stage6Time: "05:35.7",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "44",
      Name: "Norma Rivera",
      Club: "Last Sundays Ride",
      CourseClass: "Women Open",
      RaceTime: "23:12.4",
      Position: "1",
      BehindTime: "",
      Stage1Time: "04:52.7",
      Stage1Pos: "1",
      Stage2Time: "02:07.8",
      Stage2Pos: "1",
      Stage3Time: "02:45.6",
      Stage3Pos: "1",
      Stage4Time: "04:04.9",
      Stage4Pos: "2",
      Stage5Time: "03:37.3",
      Stage5Pos: "1",
     Stage6Time: "05:43.9",
      Stage6Pos: "1"
    },
    {
      RaceNumber: "42",
      Name: "Genesis Garcia",
      Club: "Crazy Cat",
      CourseClass: "Women Open",
      RaceTime: "26:11.5",
      Position: "2",
      BehindTime: "+2:59.07",
      Stage1Time: "05:09.5",
      Stage1Pos: "3",
      Stage2Time: "02:30.3",
      Stage2Pos: "3",
      Stage3Time: "03:18.6",
      Stage3Pos: "3",
      Stage4Time: "04:25.0",
     Stage4Pos: "3",
      Stage5Time: "04:09.0",
      Stage5Pos: "2",
     Stage6Time: "06:38.9",
      Stage6Pos: "4"
    },
    {
      RaceNumber: "16",
      Name: "Jennifer Morrow",
      Club: "",
      CourseClass: "Women Open",
      RaceTime: "27:49.9",
      Position: "3",
      BehindTime: "+4:37.54",
      Stage1Time: "04:58.0",
      Stage1Pos: "2",
      Stage2Time: "02:16.4",
      Stage2Pos: "2",
      Stage3Time: "02:48.5",
      Stage3Pos: "2",
      Stage4Time: "03:54.3",
     Stage4Pos: "1",
      Stage5Time: "07:38.6",
      Stage5Pos: "4",
     Stage6Time: "06:13.9",
      Stage6Pos: "2"
    },
    {
      RaceNumber: "9",
      Name: "Oceana Nelson",
      Club: "",
      CourseClass: "Women Open",
      RaceTime: "30:10.6",
      Position: "4",
      BehindTime: "+6:58.24",
      Stage1Time: "06:02.6",
      Stage1Pos: "4",
      Stage2Time: "03:32.1",
      Stage2Pos: "4",
      Stage3Time: "04:08.6",
      Stage3Pos: "4",
      Stage4Time: "04:57.7",
     Stage4Pos: "4",
      Stage5Time: "05:13.9",
      Stage5Pos: "3",
     Stage6Time: "06:15.6",
      Stage6Pos: "3"
    }
  ]

export default function franklin() {
    return (
        <div className='background-franklin'>
        
        <div className="franklin">
              
            <div className='container'>
                <h1 className='gold-0'>Franklin Mountains</h1>

                <table class="tableizer-table">
            <thead><tr class="tableizer-firstrow"><th>RaceNumber</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>BehindTime</th><th>Stage 1 -  COYOTE  Time</th><th>Stage 1 -  COYOTE  Pos</th><th>Stage 2 -  CARDIAC DH Time</th><th>Stage 2 -  CARDIAC DH Pos</th><th>Stage 3 - CARDIAC 2 Time</th><th>Stage 3 - CARDIAC 2 Pos</th><th>Stage 4 - MAZE DH Time</th><th>Stage 4 - MAZE DH Pos</th><th>Stage 5 - MAD COW  Time</th><th>Stage 5 - MAD COW  Pos</th><th>Stage 6 - MAD COW 2 Time</th><th>Stage 6 - MAD COW 2 Pos</th></tr></thead><tbody>
                <tr><td>41</td><td>Luis Encerrado </td><td>Hansdke</td><td>18-29 Men</td><td>18:42.0</td><td>1</td><td>&nbsp;</td><td>04:05.0</td><td>1</td><td>01:42.5</td><td>3</td><td>01:57.1</td><td>2</td><td>03:16.4</td><td>3</td><td>03:05.0</td><td>1</td><td>04:35.9</td><td>1</td></tr>
                <tr><td>36</td><td>Blas Meza</td><td>One Trail at a time </td><td>18-29 Men</td><td>19:10.6</td><td>2</td><td>+0:28.55</td><td>04:11.5</td><td>3</td><td>01:41.8</td><td>2</td><td>02:01.8</td><td>3</td><td>03:15.0</td><td>2</td><td>03:05.2</td><td>2</td><td>04:55.0</td><td>2</td></tr>
                <tr><td>10</td><td>Todge Cooper</td><td>Banshee Bikes, DVO, Deity</td><td>18-29 Men</td><td>19:55.0</td><td>3</td><td>+1:12.93</td><td>04:05.6</td><td>2</td><td>01:41.2</td><td>1</td><td>01:56.7</td><td>1</td><td>03:12.4</td><td>1</td><td>03:16.8</td><td>5</td><td>05:42.1</td><td>6</td></tr>
                <tr><td>89</td><td>Ramon Gamez</td><td>&nbsp;</td><td>18-29 Men</td><td>20:21.9</td><td>4</td><td>+1:39.90</td><td>04:19.6</td><td>5</td><td>01:54.7</td><td>5</td><td>02:12.9</td><td>5</td><td>03:24.7</td><td>4</td><td>03:16.3</td><td>4</td><td>05:13.6</td><td>3</td></tr>
                <tr><td>23</td><td>Luis Gallegos</td><td>&nbsp;</td><td>18-29 Men</td><td>20:35.5</td><td>5</td><td>+1:53.41</td><td>04:18.1</td><td>4</td><td>01:56.2</td><td>6</td><td>02:04.1</td><td>4</td><td>03:29.5</td><td>5</td><td>03:26.6</td><td>6</td><td>05:20.8</td><td>4</td></tr>
                <tr><td>25</td><td>Efren Carranza</td><td>The Shock Lab</td><td>18-29 Men</td><td>20:49.6</td><td>6</td><td>+2:07.60</td><td>04:24.2</td><td>7</td><td>01:50.8</td><td>4</td><td>02:17.7</td><td>6</td><td>03:31.2</td><td>6</td><td>03:16.1</td><td>3</td><td>05:29.4</td><td>5</td></tr>
                <tr><td>24</td><td>Ricardo Sanchez</td><td>Onin Staffing</td><td>18-29 Men</td><td>23:17.9</td><td>7</td><td>+4:35.84</td><td>04:21.6</td><td>6</td><td>02:14.0</td><td>7</td><td>02:58.9</td><td>7</td><td>03:41.0</td><td>7</td><td>03:35.9</td><td>7</td><td>06:26.4</td><td>7</td></tr>
                <tr><td>28</td><td>David Chacon</td><td>Northcrew</td><td>30-39 Men</td><td>18:24.5</td><td>1</td><td>&nbsp;</td><td>03:53.7</td><td>1</td><td>01:33.8</td><td>1</td><td>01:46.4</td><td>1</td><td>03:04.6</td><td>1</td><td>03:03.9</td><td>2</td><td>05:01.8</td><td>3</td></tr>
                <tr><td>33</td><td>Kevin Dunn</td><td>Ride It Out</td><td>30-39 Men</td><td>19:07.3</td><td>2</td><td>+0:42.84</td><td>04:09.6</td><td>4</td><td>01:38.5</td><td>3</td><td>02:06.6</td><td>6</td><td>03:21.8</td><td>5</td><td>02:59.8</td><td>1</td><td>04:50.8</td><td>1</td></tr>
                <tr><td>67</td><td>Will Palafox</td><td>Podium Finish</td><td>30-39 Men</td><td>19:08.2</td><td>3</td><td>+0:43.73</td><td>04:04.0</td><td>2</td><td>01:42.5</td><td>4</td><td>01:56.3</td><td>3</td><td>03:18.5</td><td>4</td><td>03:14.6</td><td>5</td><td>04:52.2</td><td>2</td></tr>
                <tr><td>82</td><td>Wayne Shaffer</td><td>Bicycle Experience</td><td>30-39 Men</td><td>19:16.1</td><td>4</td><td>+0:51.62</td><td>04:07.0</td><td>3</td><td>01:37.6</td><td>2</td><td>01:51.3</td><td>2</td><td>03:12.2</td><td>2</td><td>03:12.7</td><td>3</td><td>05:15.0</td><td>4</td></tr>
                <tr><td>26</td><td>John Shredsalot</td><td>Merol Racing / EP ENDURO</td><td>30-39 Men</td><td>19:58.4</td><td>5</td><td>+1:33.96</td><td>04:13.7</td><td>6</td><td>01:42.8</td><td>5</td><td>02:05.3</td><td>5</td><td>03:22.9</td><td>6</td><td>03:13.5</td><td>4</td><td>05:20.1</td><td>5</td></tr>
                <tr><td>50</td><td>Jesus Arellano</td><td>North Crew/Shock Lab/EP Enduro</td><td>30-39 Men</td><td>20:51.1</td><td>6</td><td>+2:26.68</td><td>04:31.6</td><td>9</td><td>01:51.6</td><td>7</td><td>01:59.2</td><td>4</td><td>03:18.4</td><td>3</td><td>03:23.1</td><td>6</td><td>05:47.1</td><td>8</td></tr>
                <tr><td>69</td><td>Eddie Sanchez</td><td>Northcrew/The Shock Lab/Merol Racing</td><td>30-39 Men</td><td>20:54.1</td><td>7</td><td>+2:29.66</td><td>04:09.6</td><td>5</td><td>01:45.0</td><td>6</td><td>02:12.2</td><td>8</td><td>03:23.0</td><td>7</td><td>03:25.7</td><td>7</td><td>05:58.5</td><td>11</td></tr>
                <tr><td>48</td><td>Justin Duez</td><td>Banshee Bikes</td><td>30-39 Men</td><td>21:17.3</td><td>8</td><td>+2:52.84</td><td>04:28.4</td><td>7</td><td>01:54.7</td><td>8</td><td>02:08.9</td><td>7</td><td>03:32.9</td><td>8</td><td>03:29.2</td><td>8</td><td>05:43.0</td><td>7</td></tr>
                <tr><td>6</td><td>Andre Von Twistern</td><td>Honey Bear Racing/ OceAndre</td><td>30-39 Men</td><td>21:50.8</td><td>9</td><td>+3:26.39</td><td>04:29.2</td><td>8</td><td>02:09.9</td><td>11</td><td>02:31.4</td><td>11</td><td>03:38.8</td><td>10</td><td>03:30.1</td><td>9</td><td>05:31.2</td><td>6</td></tr>
                <tr><td>51</td><td>Arturto Garcia</td><td>Ciclismo Juarez</td><td>30-39 Men</td><td>22:04.5</td><td>10</td><td>+3:40.02</td><td>04:39.1</td><td>11</td><td>01:55.7</td><td>9</td><td>02:27.7</td><td>10</td><td>03:37.2</td><td>9</td><td>03:36.3</td><td>11</td><td>05:48.2</td><td>9</td></tr>
                <tr><td>90</td><td>Moises Teliz</td><td>Smaz remodeling</td><td>30-39 Men</td><td>22:29.5</td><td>11</td><td>+4:05.05</td><td>04:36.6</td><td>10</td><td>02:06.8</td><td>10</td><td>02:26.0</td><td>9</td><td>03:52.7</td><td>11</td><td>03:33.3</td><td>10</td><td>05:54.0</td><td>10</td></tr>
                <tr><td>8</td><td>Sergio Acosta</td><td>Ciclismo Juarez</td><td>40-49 Men</td><td>18:20.9</td><td>1</td><td>&nbsp;</td><td>03:52.4</td><td>2</td><td>01:33.9</td><td>1</td><td>01:49.1</td><td>1</td><td>03:05.8</td><td>2</td><td>03:01.0</td><td>2</td><td>04:58.5</td><td>2</td></tr>
                <tr><td>30</td><td>Luke Cutburth</td><td>Lost Dog Chain Breakers</td><td>40-49 Men</td><td>18:23.8</td><td>2</td><td>+0:02.88</td><td>03:52.2</td><td>1</td><td>01:44.2</td><td>4</td><td>01:54.5</td><td>2</td><td>03:02.4</td><td>1</td><td>03:00.2</td><td>1</td><td>04:50.1</td><td>1</td></tr>
                <tr><td>81</td><td>Mark Nevarez</td><td>NorthCrew</td><td>40-49 Men</td><td>19:20.8</td><td>3</td><td>+0:59.94</td><td>04:02.9</td><td>3</td><td>01:41.7</td><td>2</td><td>01:57.5</td><td>3</td><td>03:14.6</td><td>4</td><td>03:14.4</td><td>5</td><td>05:09.5</td><td>4</td></tr>
                <tr><td>55</td><td>Octavio Gonzalez</td><td>Embrace the Suck</td><td>40-49 Men</td><td>20:23.8</td><td>4</td><td>+2:02.95</td><td>04:10.5</td><td>4</td><td>01:45.8</td><td>5</td><td>02:10.2</td><td>5</td><td>03:22.4</td><td>5</td><td>03:13.8</td><td>4</td><td>05:40.9</td><td>5</td></tr>
                <tr><td>20</td><td>Arnold Rodriguez</td><td>Chuco MTB</td><td>40-49 Men</td><td>21:09.6</td><td>5</td><td>+2:48.74</td><td>06:03.0</td><td>7</td><td>01:43.3</td><td>3</td><td>01:58.3</td><td>4</td><td>03:12.2</td><td>3</td><td>03:03.7</td><td>3</td><td>05:08.8</td><td>3</td></tr>
                <tr><td>39</td><td>Jamie Mantle</td><td>&nbsp;</td><td>40-49 Men</td><td>24:05.5</td><td>6</td><td>+5:44.59</td><td>04:50.4</td><td>5</td><td>02:16.9</td><td>6</td><td>02:55.8</td><td>7</td><td>03:50.4</td><td>6</td><td>04:10.7</td><td>7</td><td>06:01.1</td><td>6</td></tr>
                <tr><td>12</td><td>Fernando Garcia</td><td>&nbsp;</td><td>40-49 Men</td><td>24:50.6</td><td>7</td><td>+6:29.73</td><td>04:53.7</td><td>6</td><td>02:24.0</td><td>7</td><td>02:54.3</td><td>6</td><td>03:55.8</td><td>7</td><td>03:51.2</td><td>6</td><td>06:51.4</td><td>7</td></tr>
                <tr><td>5</td><td>Clint Nelms</td><td>Podium Finish</td><td>50+ Men</td><td>18:34.7</td><td>1</td><td>&nbsp;</td><td>03:52.8</td><td>1</td><td>01:34.8</td><td>1</td><td>01:52.5</td><td>1</td><td>03:05.6</td><td>1</td><td>03:03.6</td><td>1</td><td>05:05.3</td><td>1</td></tr>
                <tr><td>52</td><td>Sergio Pacheco</td><td>Zia Velo</td><td>50+ Men</td><td>20:18.2</td><td>2</td><td>+1:43.45</td><td>04:17.0</td><td>2</td><td>01:46.7</td><td>2</td><td>02:22.1</td><td>3</td><td>03:20.6</td><td>2</td><td>03:18.3</td><td>3</td><td>05:13.2</td><td>2</td></tr>
                <tr><td>68</td><td>Mario Lopez</td><td>Podium Finish</td><td>50+ Men</td><td>20:24.2</td><td>3</td><td>+1:49.43</td><td>04:19.3</td><td>3</td><td>01:50.3</td><td>3</td><td>02:22.0</td><td>2</td><td>03:25.5</td><td>3</td><td>03:10.4</td><td>2</td><td>05:16.3</td><td>3</td></tr>
                <tr><td>43</td><td>John Conrad</td><td>Merol Racing</td><td>50+ Men</td><td>23:07.7</td><td>4</td><td>+4:32.96</td><td>04:31.8</td><td>4</td><td>02:05.8</td><td>5</td><td>02:46.1</td><td>4</td><td>03:44.5</td><td>4</td><td>03:42.7</td><td>5</td><td>06:16.6</td><td>6</td></tr>
                <tr><td>38</td><td>Martin Guillen</td><td>&nbsp;</td><td>50+ Men</td><td>23:09.0</td><td>5</td><td>+4:34.29</td><td>04:47.1</td><td>5</td><td>02:04.0</td><td>4</td><td>03:12.8</td><td>5</td><td>03:53.0</td><td>5</td><td>03:40.8</td><td>4</td><td>05:31.1</td><td>4</td></tr>
                <tr><td>54</td><td>Miguel Rico</td><td>&nbsp;</td><td>50+ Men</td><td>26:47.5</td><td>6</td><td>+8:12.76</td><td>05:19.9</td><td>6</td><td>02:39.9</td><td>6</td><td>03:18.4</td><td>6</td><td>04:10.2</td><td>6</td><td>04:53.1</td><td>7</td><td>06:25.7</td><td>7</td></tr>
                <tr><td>13</td><td>Tom Baumann</td><td>The Flying Cannoli</td><td>50+ Men</td><td>29:32.5</td><td>7</td><td>+10:57.77</td><td>07:45.1</td><td>7</td><td>02:41.3</td><td>7</td><td>04:03.0</td><td>7</td><td>04:35.3</td><td>7</td><td>04:23.7</td><td>6</td><td>06:03.9</td><td>5</td></tr>
                <tr><td>27</td><td>Ricardo Lopez Iglesias</td><td>Middle of Nowhere</td><td>Beginner Men</td><td>21:20.8</td><td>1</td><td>&nbsp;</td><td>04:31.3</td><td>2</td><td>01:56.1</td><td>4</td><td>02:19.8</td><td>1</td><td>03:33.8</td><td>1</td><td>03:27.3</td><td>2</td><td>05:32.3</td><td>2</td></tr>
                <tr><td>2</td><td>Luis Martinetti</td><td>Middle of Nowhere</td><td>Beginner Men</td><td>21:50.4</td><td>2</td><td>+0:29.55</td><td>04:26.0</td><td>1</td><td>01:53.4</td><td>2</td><td>02:35.6</td><td>2</td><td>03:43.4</td><td>3</td><td>03:35.3</td><td>5</td><td>05:36.4</td><td>4</td></tr>
                <tr><td>74</td><td>Victor Cordero</td><td>&nbsp;</td><td>Beginner Men</td><td>21:52.4</td><td>3</td><td>+0:31.55</td><td>04:38.5</td><td>4</td><td>01:54.0</td><td>3</td><td>02:37.9</td><td>3</td><td>03:49.1</td><td>4</td><td>03:26.1</td><td>1</td><td>05:26.6</td><td>1</td></tr>
                <tr><td>47</td><td>Jose Reyes</td><td>Merol Racing </td><td>Beginner Men</td><td>21:54.2</td><td>4</td><td>+0:33.42</td><td>04:31.5</td><td>3</td><td>01:50.7</td><td>1</td><td>02:52.4</td><td>6</td><td>03:36.2</td><td>2</td><td>03:28.6</td><td>3</td><td>05:34.6</td><td>3</td></tr>
                <tr><td>37</td><td>Cesar Gallegos</td><td>&nbsp;</td><td>Beginner Men</td><td>22:58.9</td><td>5</td><td>+1:38.10</td><td>04:39.3</td><td>5</td><td>02:03.9</td><td>5</td><td>02:55.9</td><td>7</td><td>03:52.4</td><td>5</td><td>03:35.0</td><td>4</td><td>05:52.2</td><td>6</td></tr>
                <tr><td>1</td><td>Mario Holguin</td><td>Middle of nowhere</td><td>Beginner Men</td><td>23:22.2</td><td>6</td><td>+2:01.37</td><td>04:56.8</td><td>8</td><td>02:11.6</td><td>6</td><td>02:52.0</td><td>5</td><td>03:54.8</td><td>7</td><td>03:49.3</td><td>6</td><td>05:37.6</td><td>5</td></tr>
                <tr><td>7</td><td>Roberto Estrada </td><td>&nbsp;</td><td>Beginner Men</td><td>25:12.5</td><td>7</td><td>+3:51.71</td><td>04:40.1</td><td>6</td><td>03:15.9</td><td>9</td><td>03:16.8</td><td>8</td><td>03:52.9</td><td>6</td><td>03:54.9</td><td>7</td><td>06:11.7</td><td>7</td></tr>
                <tr><td>34</td><td>Dago Nunez</td><td>&nbsp;</td><td>Beginner Men</td><td>26:04.1</td><td>8</td><td>+4:43.32</td><td>05:06.0</td><td>9</td><td>02:19.3</td><td>7</td><td>02:51.7</td><td>4</td><td>04:27.5</td><td>8</td><td>04:06.7</td><td>8</td><td>07:12.6</td><td>9</td></tr>
                <tr><td>35</td><td>Ruben Rivero</td><td>&nbsp;</td><td>Beginner Men</td><td>27:37.1</td><td>9</td><td>+6:16.25</td><td>04:54.0</td><td>7</td><td>02:23.6</td><td>8</td><td>03:29.6</td><td>9</td><td>05:11.2</td><td>9</td><td>04:32.3</td><td>9</td><td>07:06.2</td><td>8</td></tr>
                <tr><td>3</td><td>Mo Frias</td><td>Middle of nowhere</td><td>Expert Men</td><td>16:55.7</td><td>1</td><td>&nbsp;</td><td>03:41.3</td><td>2</td><td>01:26.8</td><td>1</td><td>01:41.6</td><td>1</td><td>02:54.8</td><td>2</td><td>02:43.9</td><td>1</td><td>04:26.9</td><td>1</td></tr>
                <tr><td>22</td><td>Ivan Valdez</td><td>&nbsp;</td><td>Expert Men</td><td>17:19.6</td><td>2</td><td>+0:23.98</td><td>03:39.9</td><td>1</td><td>01:31.6</td><td>5</td><td>01:46.7</td><td>5</td><td>02:50.6</td><td>1</td><td>02:50.9</td><td>5</td><td>04:39.7</td><td>2</td></tr>
                <tr><td>32</td><td>Humberto Arevalo</td><td>The Shock Lab</td><td>Expert Men</td><td>17:29.8</td><td>3</td><td>+0:34.16</td><td>03:46.2</td><td>3</td><td>01:28.5</td><td>4</td><td>01:45.7</td><td>4</td><td>02:55.5</td><td>3</td><td>02:49.6</td><td>3</td><td>04:44.2</td><td>4</td></tr>
                <tr><td>77</td><td>Eric Sotomayor</td><td>The Shock Lab</td><td>Expert Men</td><td>17:32.7</td><td>4</td><td>+0:37.05</td><td>03:46.6</td><td>4</td><td>01:27.5</td><td>2</td><td>01:44.4</td><td>3</td><td>03:02.5</td><td>5</td><td>02:48.3</td><td>2</td><td>04:43.2</td><td>3</td></tr>
                <tr><td>49</td><td>Edgar Ramirez</td><td>The Shock Lab</td><td>Expert Men</td><td>17:43.3</td><td>5</td><td>+0:47.63</td><td>03:50.7</td><td>6</td><td>01:28.4</td><td>3</td><td>01:44.1</td><td>2</td><td>02:56.6</td><td>4</td><td>02:49.7</td><td>4</td><td>04:53.5</td><td>5</td></tr>
                <tr><td>31</td><td>Joel Lujan</td><td>The Shock Lab</td><td>Expert Men</td><td>18:59.8</td><td>6</td><td>+2:04.19</td><td>03:48.9</td><td>5</td><td>01:37.1</td><td>6</td><td>02:17.9</td><td>8</td><td>03:14.7</td><td>6</td><td>03:04.6</td><td>6</td><td>04:56.5</td><td>6</td></tr>
                <tr><td>17</td><td>Ricardo Granados</td><td>&nbsp;</td><td>Expert Men</td><td>19:57.2</td><td>7</td><td>+3:01.51</td><td>04:12.5</td><td>7</td><td>01:49.2</td><td>8</td><td>02:07.0</td><td>7</td><td>03:28.2</td><td>8</td><td>03:11.9</td><td>7</td><td>05:08.2</td><td>7</td></tr>
                <tr><td>66</td><td>Rene Oineza</td><td>Southern New Mexico MTB (SNMTB)</td><td>Expert Men</td><td>20:11.3</td><td>8</td><td>+3:15.65</td><td>04:12.9</td><td>8</td><td>01:40.7</td><td>7</td><td>02:03.5</td><td>6</td><td>03:22.2</td><td>7</td><td>03:19.3</td><td>8</td><td>05:32.6</td><td>8</td></tr>
                <tr><td>45</td><td>Alexander Gutierrez</td><td>&nbsp;</td><td>JR Boys U17</td><td>18:07.9</td><td>1</td><td>&nbsp;</td><td>04:01.9</td><td>1</td><td>01:34.9</td><td>1</td><td>01:52.1</td><td>1</td><td>02:58.3</td><td>1</td><td>02:52.2</td><td>1</td><td>04:48.2</td><td>1</td></tr>
                <tr><td>88</td><td>Wyatt Ivey</td><td>&nbsp;</td><td>JR Boys U17</td><td>20:29.5</td><td>2</td><td>+2:21.59</td><td>04:14.2</td><td>2</td><td>01:56.9</td><td>4</td><td>02:19.9</td><td>4</td><td>03:18.4</td><td>2</td><td>03:19.7</td><td>2</td><td>05:20.1</td><td>2</td></tr>
                <tr><td>21</td><td>Andrew Fernandez</td><td>&nbsp;</td><td>JR Boys U17</td><td>20:40.7</td><td>3</td><td>+2:32.80</td><td>04:19.7</td><td>4</td><td>01:54.1</td><td>3</td><td>02:12.6</td><td>2</td><td>03:25.9</td><td>4</td><td>03:26.5</td><td>3</td><td>05:21.6</td><td>3</td></tr>
                <tr><td>29</td><td>Alex Arellano</td><td>The Shock Lab</td><td>JR Boys U17</td><td>21:05.9</td><td>4</td><td>+2:58.03</td><td>04:23.9</td><td>5</td><td>01:48.4</td><td>2</td><td>02:19.4</td><td>3</td><td>03:22.2</td><td>3</td><td>03:27.2</td><td>4</td><td>05:44.7</td><td>5</td></tr>
                <tr><td>11</td><td>Max Guerra 2</td><td>&nbsp;</td><td>JR Boys U17</td><td>21:37.0</td><td>5</td><td>+3:29.12</td><td>04:19.2</td><td>3</td><td>02:02.2</td><td>5</td><td>02:28.6</td><td>5</td><td>03:38.1</td><td>5</td><td>03:32.9</td><td>5</td><td>05:35.7</td><td>4</td></tr>
                <tr><td>44</td><td>Norma Rivera</td><td>Last Sundays Ride</td><td>Women Open</td><td>23:12.4</td><td>1</td><td>&nbsp;</td><td>04:52.7</td><td>1</td><td>02:07.8</td><td>1</td><td>02:45.6</td><td>1</td><td>04:04.9</td><td>2</td><td>03:37.3</td><td>1</td><td>05:43.9</td><td>1</td></tr>
                <tr><td>42</td><td>Genesis Garcia</td><td>Crazy Cat</td><td>Women Open</td><td>26:11.5</td><td>2</td><td>+2:59.07</td><td>05:09.5</td><td>3</td><td>02:30.3</td><td>3</td><td>03:18.6</td><td>3</td><td>04:25.0</td><td>3</td><td>04:09.0</td><td>2</td><td>06:38.9</td><td>4</td></tr>
                <tr><td>16</td><td>Jennifer Morrow</td><td>&nbsp;</td><td>Women Open</td><td>27:49.9</td><td>3</td><td>+4:37.54</td><td>04:58.0</td><td>2</td><td>02:16.4</td><td>2</td><td>02:48.5</td><td>2</td><td>03:54.3</td><td>1</td><td>07:38.6</td><td>4</td><td>06:13.9</td><td>2</td></tr>
                <tr><td>9</td><td>Oceana Nelson</td><td>&nbsp;</td><td>Women Open</td><td>30:10.6</td><td>4</td><td>+6:58.24</td><td>06:02.6</td><td>4</td><td>03:32.1</td><td>4</td><td>04:08.6</td><td>4</td><td>04:57.7</td><td>4</td><td>05:13.9</td><td>3</td><td>06:15.6</td><td>3</td></tr>
            </tbody></table>
            <CSVLink className="downloadLink" data={csvDataFranklin}>Download Results</CSVLink>;     
        </div>
        </div>
        </div>
    )
}
