import React from 'react'
import './ld_night_2023.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvDataLDNIGHT2023 =[
    {
      "RaceNumber": 1,
      "Name": "Clint Nelms",
      "Club": "Big Knobby Squad",
      "CourseClass": "Men 50+",
      "RaceTime": "06:11.5",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "01:47.6",
      "Stage 1 - Brujos Garden  Pos": 1,
      "Stage 2 - Brujos Blitz Time": "01:08.7",
      "Stage 2 - Brujos Blitz Pos": 1,
      "Stage 3 - Granola   Time": "01:06.0",
      "Stage 3 - Granola   Pos": 1,
      "Stage 4 - Spinal Tap Time": "02:09.2",
      "Stage 4 - Spinal Tap Pos": 1
    },
    {
      "RaceNumber": 13,
      "Name": "John Conrad",
      "Club": "OutLaw MTBer/Merol Racing",
      "CourseClass": "Men 50+",
      "RaceTime": "10:58.7",
      "Position": 2,
      "Status": "",
      "BehindTime": "+4:47.25",
      "Stage 1 - Brujos Garden  Time": "02:56.9",
      "Stage 1 - Brujos Garden  Pos": 2,
      "Stage 2 - Brujos Blitz Time": "01:48.4",
      "Stage 2 - Brujos Blitz Pos": 2,
      "Stage 3 - Granola   Time": "02:02.6",
      "Stage 3 - Granola   Pos": 2,
      "Stage 4 - Spinal Tap Time": "04:10.8",
      "Stage 4 - Spinal Tap Pos": 2
    },
    {
      "RaceNumber": 5,
      "Name": "Charlie Simpson",
      "Club": "Crazy Cat Cyclery",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:17.9",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "02:20.0",
      "Stage 1 - Brujos Garden  Pos": 2,
      "Stage 2 - Brujos Blitz Time": "01:17.5",
      "Stage 2 - Brujos Blitz Pos": 2,
      "Stage 3 - Granola   Time": "01:26.2",
      "Stage 3 - Granola   Pos": 1,
      "Stage 4 - Spinal Tap Time": "03:14.2",
      "Stage 4 - Spinal Tap Pos": 2
    },
    {
      "RaceNumber": 4,
      "Name": "Maks Bevins",
      "Club": "Dogspeed",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "08:24.8",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:06.86",
      "Stage 1 - Brujos Garden  Time": "02:16.2",
      "Stage 1 - Brujos Garden  Pos": 1,
      "Stage 2 - Brujos Blitz Time": "01:16.4",
      "Stage 2 - Brujos Blitz Pos": 1,
      "Stage 3 - Granola   Time": "01:43.8",
      "Stage 3 - Granola   Pos": 2,
      "Stage 4 - Spinal Tap Time": "03:08.4",
      "Stage 4 - Spinal Tap Pos": 1
    },
    {
      "RaceNumber": 2,
      "Name": "Jake Rath",
      "Club": "Trials of Rath",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "09:25.4",
      "Position": 3,
      "Status": "",
      "BehindTime": "+1:07.46",
      "Stage 1 - Brujos Garden  Time": "02:33.5",
      "Stage 1 - Brujos Garden  Pos": 3,
      "Stage 2 - Brujos Blitz Time": "01:40.1",
      "Stage 2 - Brujos Blitz Pos": 5,
      "Stage 3 - Granola   Time": "01:53.5",
      "Stage 3 - Granola   Pos": 4,
      "Stage 4 - Spinal Tap Time": "03:18.3",
      "Stage 4 - Spinal Tap Pos": 3
    },
    {
      "RaceNumber": 26,
      "Name": "Aziel Estrada",
      "Club": "Crazy Cat Cyclery",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "09:49.4",
      "Position": 4,
      "Status": "",
      "BehindTime": "+1:31.55",
      "Stage 1 - Brujos Garden  Time": "03:02.7",
      "Stage 1 - Brujos Garden  Pos": 4,
      "Stage 2 - Brujos Blitz Time": "01:27.2",
      "Stage 2 - Brujos Blitz Pos": 3,
      "Stage 3 - Granola   Time": "01:45.3",
      "Stage 3 - Granola   Pos": 3,
      "Stage 4 - Spinal Tap Time": "03:34.2",
      "Stage 4 - Spinal Tap Pos": 4
    },
    {
      "RaceNumber": 3,
      "Name": "Anthony Banderas",
      "Club": "",
      "CourseClass": "Men Beginner Open",
      "RaceTime": "",
      "Position": null,
      "Status": "DNF",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "03:02.9",
      "Stage 1 - Brujos Garden  Pos": 5,
      "Stage 2 - Brujos Blitz Time": "01:34.0",
      "Stage 2 - Brujos Blitz Pos": 4,
      "Stage 3 - Granola   Time": "",
      "Stage 3 - Granola   Pos": null,
      "Stage 4 - Spinal Tap Time": "",
      "Stage 4 - Spinal Tap Pos": null
    },
    {
      "RaceNumber": 14,
      "Name": "Fernando Estrada",
      "Club": "Faunos Racing Team",
      "CourseClass": "Men Expert Open",
      "RaceTime": "05:36.9",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "01:35.9",
      "Stage 1 - Brujos Garden  Pos": 1,
      "Stage 2 - Brujos Blitz Time": "00:57.3",
      "Stage 2 - Brujos Blitz Pos": 1,
      "Stage 3 - Granola   Time": "01:04.0",
      "Stage 3 - Granola   Pos": 4,
      "Stage 4 - Spinal Tap Time": "01:59.7",
      "Stage 4 - Spinal Tap Pos": 1
    },
    {
      "RaceNumber": 12,
      "Name": "Alexander Gutierrez",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "05:41.2",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:04.32",
      "Stage 1 - Brujos Garden  Time": "01:38.2",
      "Stage 1 - Brujos Garden  Pos": 2,
      "Stage 2 - Brujos Blitz Time": "01:00.6",
      "Stage 2 - Brujos Blitz Pos": 2,
      "Stage 3 - Granola   Time": "01:02.2",
      "Stage 3 - Granola   Pos": 2,
      "Stage 4 - Spinal Tap Time": "02:00.3",
      "Stage 4 - Spinal Tap Pos": 2
    },
    {
      "RaceNumber": 10,
      "Name": "Corey Jones",
      "Club": "",
      "CourseClass": "Men Expert Open",
      "RaceTime": "05:47.6",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:10.75",
      "Stage 1 - Brujos Garden  Time": "01:41.8",
      "Stage 1 - Brujos Garden  Pos": 3,
      "Stage 2 - Brujos Blitz Time": "01:00.9",
      "Stage 2 - Brujos Blitz Pos": 3,
      "Stage 3 - Granola   Time": "01:02.0",
      "Stage 3 - Granola   Pos": 1,
      "Stage 4 - Spinal Tap Time": "02:02.9",
      "Stage 4 - Spinal Tap Pos": 3
    },
    {
      "RaceNumber": 11,
      "Name": "Max Guerra",
      "Club": "The Shock Lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:03.6",
      "Position": 4,
      "Status": "",
      "BehindTime": "+0:26.71",
      "Stage 1 - Brujos Garden  Time": "01:50.6",
      "Stage 1 - Brujos Garden  Pos": 5,
      "Stage 2 - Brujos Blitz Time": "01:04.0",
      "Stage 2 - Brujos Blitz Pos": 5,
      "Stage 3 - Granola   Time": "01:03.4",
      "Stage 3 - Granola   Pos": 3,
      "Stage 4 - Spinal Tap Time": "02:05.6",
      "Stage 4 - Spinal Tap Pos": 4
    },
    {
      "RaceNumber": 31,
      "Name": "David Garcia",
      "Club": "Faunos Racing Team",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:13.1",
      "Position": 5,
      "Status": "",
      "BehindTime": "+0:36.28",
      "Stage 1 - Brujos Garden  Time": "01:45.0",
      "Stage 1 - Brujos Garden  Pos": 4,
      "Stage 2 - Brujos Blitz Time": "01:03.5",
      "Stage 2 - Brujos Blitz Pos": 4,
      "Stage 3 - Granola   Time": "01:09.9",
      "Stage 3 - Granola   Pos": 5,
      "Stage 4 - Spinal Tap Time": "02:14.7",
      "Stage 4 - Spinal Tap Pos": 5
    },
    {
      "RaceNumber": 40,
      "Name": "Hunt Burdick",
      "Club": "Squad",
      "CourseClass": "Men Expert Open",
      "RaceTime": "06:53.1",
      "Position": 6,
      "Status": "",
      "BehindTime": "+1:16.23",
      "Stage 1 - Brujos Garden  Time": "01:59.7",
      "Stage 1 - Brujos Garden  Pos": 7,
      "Stage 2 - Brujos Blitz Time": "01:13.3",
      "Stage 2 - Brujos Blitz Pos": 7,
      "Stage 3 - Granola   Time": "01:17.1",
      "Stage 3 - Granola   Pos": 7,
      "Stage 4 - Spinal Tap Time": "02:23.1",
      "Stage 4 - Spinal Tap Pos": 6
    },
    {
      "RaceNumber": 15,
      "Name": "Emilio Estrada",
      "Club": "Faunos Racing Team",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:17.2",
      "Position": 7,
      "Status": "",
      "BehindTime": "+1:40.37",
      "Stage 1 - Brujos Garden  Time": "02:10.0",
      "Stage 1 - Brujos Garden  Pos": 9,
      "Stage 2 - Brujos Blitz Time": "01:07.1",
      "Stage 2 - Brujos Blitz Pos": 6,
      "Stage 3 - Granola   Time": "01:12.3",
      "Stage 3 - Granola   Pos": 6,
      "Stage 4 - Spinal Tap Time": "02:47.7",
      "Stage 4 - Spinal Tap Pos": 8
    },
    {
      "RaceNumber": 8,
      "Name": "Ricardo Granados",
      "Club": "The Shock Lab",
      "CourseClass": "Men Expert Open",
      "RaceTime": "07:51.2",
      "Position": 8,
      "Status": "",
      "BehindTime": "+2:14.33",
      "Stage 1 - Brujos Garden  Time": "02:09.5",
      "Stage 1 - Brujos Garden  Pos": 8,
      "Stage 2 - Brujos Blitz Time": "01:38.4",
      "Stage 2 - Brujos Blitz Pos": 9,
      "Stage 3 - Granola   Time": "01:20.1",
      "Stage 3 - Granola   Pos": 8,
      "Stage 4 - Spinal Tap Time": "02:43.1",
      "Stage 4 - Spinal Tap Pos": 7
    },
    {
      "RaceNumber": 69,
      "Name": "Will Palafox",
      "Club": "Podium Finish",
      "CourseClass": "Men Expert Open",
      "RaceTime": "14:39.3",
      "Position": 9,
      "Status": "",
      "BehindTime": "+9:02.40",
      "Stage 1 - Brujos Garden  Time": "01:53.4",
      "Stage 1 - Brujos Garden  Pos": 6,
      "Stage 2 - Brujos Blitz Time": "01:16.8",
      "Stage 2 - Brujos Blitz Pos": 8,
      "Stage 3 - Granola   Time": "01:30.1",
      "Stage 3 - Granola   Pos": 9,
      "Stage 4 - Spinal Tap Time": "09:59.0",
      "Stage 4 - Spinal Tap Pos": 9
    },
    {
      "RaceNumber": 24,
      "Name": "Daniel  Urrutia",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "06:42.7",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "01:53.3",
      "Stage 1 - Brujos Garden  Pos": 1,
      "Stage 2 - Brujos Blitz Time": "01:11.4",
      "Stage 2 - Brujos Blitz Pos": 2,
      "Stage 3 - Granola   Time": "01:16.6",
      "Stage 3 - Granola   Pos": 4,
      "Stage 4 - Spinal Tap Time": "02:21.3",
      "Stage 4 - Spinal Tap Pos": 1
    },
    {
      "RaceNumber": 20,
      "Name": "Rene De La O",
      "Club": "Podium Finish / Mandalorian Elite",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "06:50.0",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:07.32",
      "Stage 1 - Brujos Garden  Time": "01:57.9",
      "Stage 1 - Brujos Garden  Pos": 3,
      "Stage 2 - Brujos Blitz Time": "01:10.4",
      "Stage 2 - Brujos Blitz Pos": 1,
      "Stage 3 - Granola   Time": "01:12.4",
      "Stage 3 - Granola   Pos": 1,
      "Stage 4 - Spinal Tap Time": "02:29.2",
      "Stage 4 - Spinal Tap Pos": 3
    },
    {
      "RaceNumber": 21,
      "Name": "Ryan Watkins",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "06:51.2",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:08.56",
      "Stage 1 - Brujos Garden  Time": "01:53.7",
      "Stage 1 - Brujos Garden  Pos": 2,
      "Stage 2 - Brujos Blitz Time": "01:15.3",
      "Stage 2 - Brujos Blitz Pos": 4,
      "Stage 3 - Granola   Time": "01:16.3",
      "Stage 3 - Granola   Pos": 3,
      "Stage 4 - Spinal Tap Time": "02:25.8",
      "Stage 4 - Spinal Tap Pos": 2
    },
    {
      "RaceNumber": 22,
      "Name": "Aidan Burke",
      "Club": "Wreckless sending",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "08:05.7",
      "Position": 4,
      "Status": "",
      "BehindTime": "+1:22.99",
      "Stage 1 - Brujos Garden  Time": "02:12.8",
      "Stage 1 - Brujos Garden  Pos": 4,
      "Stage 2 - Brujos Blitz Time": "01:15.2",
      "Stage 2 - Brujos Blitz Pos": 3,
      "Stage 3 - Granola   Time": "01:17.2",
      "Stage 3 - Granola   Pos": 5,
      "Stage 4 - Spinal Tap Time": "03:20.4",
      "Stage 4 - Spinal Tap Pos": 5
    },
    {
      "RaceNumber": 23,
      "Name": "Christian  Munoz",
      "Club": "Lavanderia Y Tintoreria Max",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "09:24.2",
      "Position": 5,
      "Status": "",
      "BehindTime": "+2:41.58",
      "Stage 1 - Brujos Garden  Time": "02:34.9",
      "Stage 1 - Brujos Garden  Pos": 5,
      "Stage 2 - Brujos Blitz Time": "01:35.8",
      "Stage 2 - Brujos Blitz Pos": 6,
      "Stage 3 - Granola   Time": "01:43.2",
      "Stage 3 - Granola   Pos": 6,
      "Stage 4 - Spinal Tap Time": "03:30.4",
      "Stage 4 - Spinal Tap Pos": 6
    },
    {
      "RaceNumber": 18,
      "Name": "Horacio Garcia",
      "Club": "Adrenaline Locos",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "11:54.7",
      "Position": 6,
      "Status": "",
      "BehindTime": "+5:12.00",
      "Stage 1 - Brujos Garden  Time": "02:45.0",
      "Stage 1 - Brujos Garden  Pos": 6,
      "Stage 2 - Brujos Blitz Time": "01:44.3",
      "Stage 2 - Brujos Blitz Pos": 7,
      "Stage 3 - Granola   Time": "02:05.8",
      "Stage 3 - Granola   Pos": 7,
      "Stage 4 - Spinal Tap Time": "05:19.6",
      "Stage 4 - Spinal Tap Pos": 8
    },
    {
      "RaceNumber": 16,
      "Name": "Jose Ramos",
      "Club": "",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "12:18.7",
      "Position": 7,
      "Status": "",
      "BehindTime": "+5:35.99",
      "Stage 1 - Brujos Garden  Time": "03:25.3",
      "Stage 1 - Brujos Garden  Pos": 7,
      "Stage 2 - Brujos Blitz Time": "01:52.5",
      "Stage 2 - Brujos Blitz Pos": 8,
      "Stage 3 - Granola   Time": "02:41.9",
      "Stage 3 - Granola   Pos": 9,
      "Stage 4 - Spinal Tap Time": "04:18.9",
      "Stage 4 - Spinal Tap Pos": 7
    },
    {
      "RaceNumber": 17,
      "Name": "Diego Gonzalez",
      "Club": "Faunos Racing Team",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "14:14.1",
      "Position": 8,
      "Status": "",
      "BehindTime": "+7:31.45",
      "Stage 1 - Brujos Garden  Time": "03:43.2",
      "Stage 1 - Brujos Garden  Pos": 8,
      "Stage 2 - Brujos Blitz Time": "02:17.3",
      "Stage 2 - Brujos Blitz Pos": 9,
      "Stage 3 - Granola   Time": "02:38.1",
      "Stage 3 - Granola   Pos": 8,
      "Stage 4 - Spinal Tap Time": "05:35.5",
      "Stage 4 - Spinal Tap Pos": 9
    },
    {
      "RaceNumber": 19,
      "Name": "Julio Reyes",
      "Club": "Mozketeroz",
      "CourseClass": "Men Intermediate Open",
      "RaceTime": "31:01.0",
      "Position": 9,
      "Status": "",
      "BehindTime": "+24:18.29",
      "Stage 1 - Brujos Garden  Time": "26:01.7",
      "Stage 1 - Brujos Garden  Pos": 9,
      "Stage 2 - Brujos Blitz Time": "01:15.4",
      "Stage 2 - Brujos Blitz Pos": 5,
      "Stage 3 - Granola   Time": "01:14.0",
      "Stage 3 - Granola   Pos": 2,
      "Stage 4 - Spinal Tap Time": "02:29.9",
      "Stage 4 - Spinal Tap Pos": 4
    },
    {
      "RaceNumber": 29,
      "Name": "Kara Samsel",
      "Club": "Podium Finish",
      "CourseClass": "Women Open",
      "RaceTime": "08:02.9",
      "Position": 1,
      "Status": "",
      "BehindTime": "",
      "Stage 1 - Brujos Garden  Time": "02:20.8",
      "Stage 1 - Brujos Garden  Pos": 1,
      "Stage 2 - Brujos Blitz Time": "01:19.7",
      "Stage 2 - Brujos Blitz Pos": 1,
      "Stage 3 - Granola   Time": "01:26.5",
      "Stage 3 - Granola   Pos": 1,
      "Stage 4 - Spinal Tap Time": "02:55.9",
      "Stage 4 - Spinal Tap Pos": 1
    },
    {
      "RaceNumber": 28,
      "Name": "Brissia Montalvo",
      "Club": "Rad Dog Bikes",
      "CourseClass": "Women Open",
      "RaceTime": "08:45.9",
      "Position": 2,
      "Status": "",
      "BehindTime": "+0:42.99",
      "Stage 1 - Brujos Garden  Time": "02:29.7",
      "Stage 1 - Brujos Garden  Pos": 2,
      "Stage 2 - Brujos Blitz Time": "01:26.3",
      "Stage 2 - Brujos Blitz Pos": 2,
      "Stage 3 - Granola   Time": "01:39.2",
      "Stage 3 - Granola   Pos": 3,
      "Stage 4 - Spinal Tap Time": "03:10.7",
      "Stage 4 - Spinal Tap Pos": 3
    },
    {
      "RaceNumber": 66,
      "Name": "Oceana Nelson",
      "Club": "The Dirt Therapy Project",
      "CourseClass": "Women Open",
      "RaceTime": "08:59.3",
      "Position": 3,
      "Status": "",
      "BehindTime": "+0:56.32",
      "Stage 1 - Brujos Garden  Time": "02:43.4",
      "Stage 1 - Brujos Garden  Pos": 3,
      "Stage 2 - Brujos Blitz Time": "01:27.7",
      "Stage 2 - Brujos Blitz Pos": 3,
      "Stage 3 - Granola   Time": "01:38.1",
      "Stage 3 - Granola   Pos": 2,
      "Stage 4 - Spinal Tap Time": "03:10.1",
      "Stage 4 - Spinal Tap Pos": 2
    },
    {
      "RaceNumber": 30,
      "Name": "Norma Rivera",
      "Club": "Last Sundays Ride",
      "CourseClass": "Women Open",
      "RaceTime": "10:43.2",
      "Position": 4,
      "Status": "",
      "BehindTime": "+2:40.21",
      "Stage 1 - Brujos Garden  Time": "02:55.5",
      "Stage 1 - Brujos Garden  Pos": 4,
      "Stage 2 - Brujos Blitz Time": "01:44.1",
      "Stage 2 - Brujos Blitz Pos": 4,
      "Stage 3 - Granola   Time": "02:12.2",
      "Stage 3 - Granola   Pos": 5,
      "Stage 4 - Spinal Tap Time": "03:51.4",
      "Stage 4 - Spinal Tap Pos": 5
    },
    {
      "RaceNumber": 25,
      "Name": "Karla Maciel",
      "Club": "Lavanderia Y Tintoreria Max",
      "CourseClass": "Women Open",
      "RaceTime": "10:45.9",
      "Position": 5,
      "Status": "",
      "BehindTime": "+2:42.99",
      "Stage 1 - Brujos Garden  Time": "03:09.4",
      "Stage 1 - Brujos Garden  Pos": 5,
      "Stage 2 - Brujos Blitz Time": "01:56.1",
      "Stage 2 - Brujos Blitz Pos": 5,
      "Stage 3 - Granola   Time": "01:56.4",
      "Stage 3 - Granola   Pos": 4,
      "Stage 4 - Spinal Tap Time": "03:44.2",
      "Stage 4 - Spinal Tap Pos": 4
    },
    {
      "RaceNumber": 6,
      "Name": "Ana Montoya",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "12:41.4",
      "Position": 6,
      "Status": "",
      "BehindTime": "+4:38.45",
      "Stage 1 - Brujos Garden  Time": "03:25.7",
      "Stage 1 - Brujos Garden  Pos": 6,
      "Stage 2 - Brujos Blitz Time": "02:15.4",
      "Stage 2 - Brujos Blitz Pos": 7,
      "Stage 3 - Granola   Time": "02:27.6",
      "Stage 3 - Granola   Pos": 6,
      "Stage 4 - Spinal Tap Time": "04:32.8",
      "Stage 4 - Spinal Tap Pos": 6
    },
    {
      "RaceNumber": 27,
      "Name": "Sarah Forrestal",
      "Club": "",
      "CourseClass": "Women Open",
      "RaceTime": "13:15.4",
      "Position": 7,
      "Status": "",
      "BehindTime": "+5:12.47",
      "Stage 1 - Brujos Garden  Time": "03:48.3",
      "Stage 1 - Brujos Garden  Pos": 7,
      "Stage 2 - Brujos Blitz Time": "01:58.8",
      "Stage 2 - Brujos Blitz Pos": 6,
      "Stage 3 - Granola   Time": "02:39.5",
      "Stage 3 - Granola   Pos": 7,
      "Stage 4 - Spinal Tap Time": "04:48.8",
      "Stage 4 - Spinal Tap Pos": 7
    }
   ]

export default function franklin() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>DARKDURO 2023</h1>

                <table class="tableizer-table">
                    <thead><tr class="tableizer-firstrow"><th>RaceNumber</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>Status</th><th>BehindTime</th><th>Stage 1 - Brujos Garden  Time</th><th>Stage 1 - Brujos Garden  Pos</th><th>Stage 2 - Brujos Blitz Time</th><th>Stage 2 - Brujos Blitz Pos</th><th>Stage 3 - Granola   Time</th><th>Stage 3 - Granola   Pos</th><th>Stage 4 - Spinal Tap Time</th><th>Stage 4 - Spinal Tap Pos</th></tr></thead><tbody>
                    <tr><td>1</td><td>Clint Nelms</td><td>Big Knobby Squad</td><td>Men 50+</td><td>06:11.5</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>01:47.6</td><td>1</td><td>01:08.7</td><td>1</td><td>01:06.0</td><td>1</td><td>02:09.2</td><td>1</td></tr>
                    <tr><td>13</td><td>John Conrad</td><td>OutLaw MTBer/Merol Racing</td><td>Men 50+</td><td>10:58.7</td><td>2</td><td>&nbsp;</td><td>+4:47.25</td><td>02:56.9</td><td>2</td><td>01:48.4</td><td>2</td><td>02:02.6</td><td>2</td><td>04:10.8</td><td>2</td></tr>
                    <tr><td>5</td><td>Charlie Simpson</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>08:17.9</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:20.0</td><td>2</td><td>01:17.5</td><td>2</td><td>01:26.2</td><td>1</td><td>03:14.2</td><td>2</td></tr>
                    <tr><td>4</td><td>Maks Bevins</td><td>Dogspeed</td><td>Men Beginner Open</td><td>08:24.8</td><td>2</td><td>&nbsp;</td><td>+0:06.86</td><td>02:16.2</td><td>1</td><td>01:16.4</td><td>1</td><td>01:43.8</td><td>2</td><td>03:08.4</td><td>1</td></tr>
                    <tr><td>2</td><td>Jake Rath</td><td>Trials of Rath</td><td>Men Beginner Open</td><td>09:25.4</td><td>3</td><td>&nbsp;</td><td>+1:07.46</td><td>02:33.5</td><td>3</td><td>01:40.1</td><td>5</td><td>01:53.5</td><td>4</td><td>03:18.3</td><td>3</td></tr>
                    <tr><td>26</td><td>Aziel Estrada</td><td>Crazy Cat Cyclery</td><td>Men Beginner Open</td><td>09:49.4</td><td>4</td><td>&nbsp;</td><td>+1:31.55</td><td>03:02.7</td><td>4</td><td>01:27.2</td><td>3</td><td>01:45.3</td><td>3</td><td>03:34.2</td><td>4</td></tr>
                    <tr><td>3</td><td>Anthony Banderas</td><td>&nbsp;</td><td>Men Beginner Open</td><td>&nbsp;</td><td>&nbsp;</td><td>DNF</td><td>&nbsp;</td><td>03:02.9</td><td>5</td><td>01:34.0</td><td>4</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                    <tr><td>14</td><td>Fernando Estrada</td><td>Faunos Racing Team</td><td>Men Expert Open</td><td>05:36.9</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>01:35.9</td><td>1</td><td>00:57.3</td><td>1</td><td>01:04.0</td><td>4</td><td>01:59.7</td><td>1</td></tr>
                    <tr><td>12</td><td>Alexander Gutierrez</td><td>&nbsp;</td><td>Men Expert Open</td><td>05:41.2</td><td>2</td><td>&nbsp;</td><td>+0:04.32</td><td>01:38.2</td><td>2</td><td>01:00.6</td><td>2</td><td>01:02.2</td><td>2</td><td>02:00.3</td><td>2</td></tr>
                    <tr><td>10</td><td>Corey Jones</td><td>&nbsp;</td><td>Men Expert Open</td><td>05:47.6</td><td>3</td><td>&nbsp;</td><td>+0:10.75</td><td>01:41.8</td><td>3</td><td>01:00.9</td><td>3</td><td>01:02.0</td><td>1</td><td>02:02.9</td><td>3</td></tr>
                    <tr><td>11</td><td>Max Guerra</td><td>The Shock Lab</td><td>Men Expert Open</td><td>06:03.6</td><td>4</td><td>&nbsp;</td><td>+0:26.71</td><td>01:50.6</td><td>5</td><td>01:04.0</td><td>5</td><td>01:03.4</td><td>3</td><td>02:05.6</td><td>4</td></tr>
                    <tr><td>31</td><td>David Garcia</td><td>Faunos Racing Team</td><td>Men Expert Open</td><td>06:13.1</td><td>5</td><td>&nbsp;</td><td>+0:36.28</td><td>01:45.0</td><td>4</td><td>01:03.5</td><td>4</td><td>01:09.9</td><td>5</td><td>02:14.7</td><td>5</td></tr>
                    <tr><td>40</td><td>Hunt Burdick</td><td>Squad</td><td>Men Expert Open</td><td>06:53.1</td><td>6</td><td>&nbsp;</td><td>+1:16.23</td><td>01:59.7</td><td>7</td><td>01:13.3</td><td>7</td><td>01:17.1</td><td>7</td><td>02:23.1</td><td>6</td></tr>
                    <tr><td>15</td><td>Emilio Estrada</td><td>Faunos Racing Team</td><td>Men Expert Open</td><td>07:17.2</td><td>7</td><td>&nbsp;</td><td>+1:40.37</td><td>02:10.0</td><td>9</td><td>01:07.1</td><td>6</td><td>01:12.3</td><td>6</td><td>02:47.7</td><td>8</td></tr>
                    <tr><td>8</td><td>Ricardo Granados</td><td>The Shock Lab</td><td>Men Expert Open</td><td>07:51.2</td><td>8</td><td>&nbsp;</td><td>+2:14.33</td><td>02:09.5</td><td>8</td><td>01:38.4</td><td>9</td><td>01:20.1</td><td>8</td><td>02:43.1</td><td>7</td></tr>
                    <tr><td>69</td><td>Will Palafox</td><td>Podium Finish</td><td>Men Expert Open</td><td>14:39.3</td><td>9</td><td>&nbsp;</td><td>+9:02.40</td><td>01:53.4</td><td>6</td><td>01:16.8</td><td>8</td><td>01:30.1</td><td>9</td><td>09:59.0</td><td>9</td></tr>
                    <tr><td>24</td><td>Daniel  Urrutia</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>06:42.7</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>01:53.3</td><td>1</td><td>01:11.4</td><td>2</td><td>01:16.6</td><td>4</td><td>02:21.3</td><td>1</td></tr>
                    <tr><td>20</td><td>Rene De La O</td><td>Podium Finish / Mandalorian Elite</td><td>Men Intermediate Open</td><td>06:50.0</td><td>2</td><td>&nbsp;</td><td>+0:07.32</td><td>01:57.9</td><td>3</td><td>01:10.4</td><td>1</td><td>01:12.4</td><td>1</td><td>02:29.2</td><td>3</td></tr>
                    <tr><td>21</td><td>Ryan Watkins</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>06:51.2</td><td>3</td><td>&nbsp;</td><td>+0:08.56</td><td>01:53.7</td><td>2</td><td>01:15.3</td><td>4</td><td>01:16.3</td><td>3</td><td>02:25.8</td><td>2</td></tr>
                    <tr><td>22</td><td>Aidan Burke</td><td>Wreckless sending</td><td>Men Intermediate Open</td><td>08:05.7</td><td>4</td><td>&nbsp;</td><td>+1:22.99</td><td>02:12.8</td><td>4</td><td>01:15.2</td><td>3</td><td>01:17.2</td><td>5</td><td>03:20.4</td><td>5</td></tr>
                    <tr><td>23</td><td>Christian  Munoz</td><td>Lavanderia Y Tintoreria Max</td><td>Men Intermediate Open</td><td>09:24.2</td><td>5</td><td>&nbsp;</td><td>+2:41.58</td><td>02:34.9</td><td>5</td><td>01:35.8</td><td>6</td><td>01:43.2</td><td>6</td><td>03:30.4</td><td>6</td></tr>
                    <tr><td>18</td><td>Horacio Garcia</td><td>Adrenaline Locos</td><td>Men Intermediate Open</td><td>11:54.7</td><td>6</td><td>&nbsp;</td><td>+5:12.00</td><td>02:45.0</td><td>6</td><td>01:44.3</td><td>7</td><td>02:05.8</td><td>7</td><td>05:19.6</td><td>8</td></tr>
                    <tr><td>16</td><td>Jose Ramos</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>12:18.7</td><td>7</td><td>&nbsp;</td><td>+5:35.99</td><td>03:25.3</td><td>7</td><td>01:52.5</td><td>8</td><td>02:41.9</td><td>9</td><td>04:18.9</td><td>7</td></tr>
                    <tr><td>17</td><td>Diego Gonzalez</td><td>Faunos Racing Team</td><td>Men Intermediate Open</td><td>14:14.1</td><td>8</td><td>&nbsp;</td><td>+7:31.45</td><td>03:43.2</td><td>8</td><td>02:17.3</td><td>9</td><td>02:38.1</td><td>8</td><td>05:35.5</td><td>9</td></tr>
                    <tr><td>19</td><td>Julio Reyes</td><td>Mozketeroz</td><td>Men Intermediate Open</td><td>31:01.0</td><td>9</td><td>&nbsp;</td><td>+24:18.29</td><td>26:01.7</td><td>9</td><td>01:15.4</td><td>5</td><td>01:14.0</td><td>2</td><td>02:29.9</td><td>4</td></tr>
                    <tr><td>29</td><td>Kara Samsel</td><td>Podium Finish</td><td>Women Open</td><td>08:02.9</td><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>02:20.8</td><td>1</td><td>01:19.7</td><td>1</td><td>01:26.5</td><td>1</td><td>02:55.9</td><td>1</td></tr>
                    <tr><td>28</td><td>Brissia Montalvo</td><td>Rad Dog Bikes</td><td>Women Open</td><td>08:45.9</td><td>2</td><td>&nbsp;</td><td>+0:42.99</td><td>02:29.7</td><td>2</td><td>01:26.3</td><td>2</td><td>01:39.2</td><td>3</td><td>03:10.7</td><td>3</td></tr>
                    <tr><td>66</td><td>Oceana Nelson</td><td>The Dirt Therapy Project</td><td>Women Open</td><td>08:59.3</td><td>3</td><td>&nbsp;</td><td>+0:56.32</td><td>02:43.4</td><td>3</td><td>01:27.7</td><td>3</td><td>01:38.1</td><td>2</td><td>03:10.1</td><td>2</td></tr>
                    <tr><td>30</td><td>Norma Rivera</td><td>Last Sundays Ride</td><td>Women Open</td><td>10:43.2</td><td>4</td><td>&nbsp;</td><td>+2:40.21</td><td>02:55.5</td><td>4</td><td>01:44.1</td><td>4</td><td>02:12.2</td><td>5</td><td>03:51.4</td><td>5</td></tr>
                    <tr><td>25</td><td>Karla Maciel</td><td>Lavanderia Y Tintoreria Max</td><td>Women Open</td><td>10:45.9</td><td>5</td><td>&nbsp;</td><td>+2:42.99</td><td>03:09.4</td><td>5</td><td>01:56.1</td><td>5</td><td>01:56.4</td><td>4</td><td>03:44.2</td><td>4</td></tr>
                    <tr><td>6</td><td>Ana Montoya</td><td>&nbsp;</td><td>Women Open</td><td>12:41.4</td><td>6</td><td>&nbsp;</td><td>+4:38.45</td><td>03:25.7</td><td>6</td><td>02:15.4</td><td>7</td><td>02:27.6</td><td>6</td><td>04:32.8</td><td>6</td></tr>
                    <tr><td>27</td><td>Sarah Forrestal</td><td>&nbsp;</td><td>Women Open</td><td>13:15.4</td><td>7</td><td>&nbsp;</td><td>+5:12.47</td><td>03:48.3</td><td>7</td><td>01:58.8</td><td>6</td><td>02:39.5</td><td>7</td><td>04:48.8</td><td>7</td></tr>
            </tbody></table>
            <CSVLink className="downloadLink" data={csvDataLDNIGHT2023}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
