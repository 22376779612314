import React from 'react'
import './ld_night.css'
import { CSVLink, CSVDownload } from "react-csv";

const csvData = [
    {
      "A": "RaceNumber",
      "B": "CardNumbers",
      "C": "MembershipNumbers",
      "D": "Name",
      "E": "Category",
      "F": "Club",
      "G": "Country",
      "H": "CourseClass",
      "I": "StartTime",
      "J": "FinishTime",
      "K": "RaceTime",
      "L": "NonCompetitive",
      "M": "Position",
      "N": "Status",
      "O": "Handicap",
      "P": "PenaltyScore",
      "Q": "ManualScoreAdjust",
      "R": "FinalScore",
      "S": "HandicapTime",
      "T": "HandicapScore",
      "U": "AwardLevel",
      "V": "SiEntriesIDs",
      "W": "Eligibility",
      "X": "JourneyTime",
      "Y": "ExcludedExcess",
      "Z": "BehindTime",
      "AA": "GenderDOB",
      "AB": "NotUsed",
      "AC": "NotUsed",
      "AD": "NotUsed",
      "AE": "NotUsed",
      "AF": "Stage 1 - Maze Time",
      "AG": "Stage 1 - Maze Pos",
      "AH": "Stage 2 - Polecat Time",
      "AI": "Stage 2 - Polecat Pos",
      "AJ": "Stage 3 - Muleshoe Time",
      "AK": "Stage 3 - Muleshoe Pos",
      "AL": "Stage 4 - Cardiac DH Time",
      "AM": "Stage 4 - Cardiac DH Pos",
      "AN": "Stage 5 - No Bueno Time",
      "AO": "Stage 5 - No Bueno Pos",
      "AP": "Stage 6 - Cardiac Lower  Time",
      "AQ": "Stage 6 - Cardiac Lower  Pos",
      "AR": "Penalty Stage 1 - Maze",
      "AS": "Penalty Stage 2 - Polecat",
      "AT": "Penalty Stage 3 - Muleshoe",
      "AU": "Penalty Stage 4 - Cardiac DH",
      "AV": "Penalty Stage 5 - No Bueno",
      "AW": "Penalty Stage 6 - Cardiac Lower ",
      "AX": "LiaisonExcess"
    },
    {
      "A": "51",
      "B": "8032631",
      "C": "",
      "D": "Octavio Gonzalez",
      "E": "",
      "F": "Embrace the Suck",
      "G": "",
      "H": "Men 50+",
      "I": "",
      "J": "",
      "K": "0.009997337962962963",
      "L": "N",
      "M": "1",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0024881944444444444",
      "AG": "2",
      "AH": "0.001524884259259259",
      "AI": "1",
      "AJ": "0.002297916666666667",
      "AK": "1",
      "AL": "0.001097800925925926",
      "AM": "1",
      "AN": "0.0016780092592592593",
      "AO": "1",
      "AP": "0.0009105324074074075",
      "AQ": "1",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "50",
      "B": "8032609",
      "C": "27704",
      "D": "David Peregrino",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men 50+",
      "I": "",
      "J": "",
      "K": "0.01085185185185185",
      "L": "N",
      "M": "2",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:13.83",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0023891203703703704",
      "AG": "1",
      "AH": "0.001601388888888889",
      "AI": "2",
      "AJ": "0.0026811342592592594",
      "AK": "2",
      "AL": "0.0012708333333333335",
      "AM": "2",
      "AN": "0.0018925925925925926",
      "AO": "2",
      "AP": "0.0010167824074074074",
      "AQ": "2",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "52",
      "B": "8143975",
      "C": "",
      "D": "Isaac Chavez",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.010188773148148148",
      "L": "N",
      "M": "1",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0023386574074074074",
      "AG": "1",
      "AH": "0.001577777777777778",
      "AI": "2",
      "AJ": "0.002490740740740741",
      "AK": "2",
      "AL": "0.0012593749999999999",
      "AM": "3",
      "AN": "0.0016371527777777775",
      "AO": "1",
      "AP": "0.0008850694444444444",
      "AQ": "1",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "80",
      "B": "8032622",
      "C": "",
      "D": "Max Guerra",
      "E": "",
      "F": "The Shock Lab / North Crew",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.010230208333333334",
      "L": "N",
      "M": "2",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+0:03.58",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002353935185185185",
      "AG": "2",
      "AH": "0.0015165509259259257",
      "AI": "1",
      "AJ": "0.0023931712962962965",
      "AK": "1",
      "AL": "0.0011809027777777777",
      "AM": "1",
      "AN": "0.0017762731481481481",
      "AO": "2",
      "AP": "0.001009375",
      "AQ": "3",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "53",
      "B": "8032638",
      "C": "",
      "D": "Andre von Twistern",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.010887268518518518",
      "L": "N",
      "M": "3",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:00.35",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0024677083333333335",
      "AG": "3",
      "AH": "0.001588888888888889",
      "AI": "3",
      "AJ": "0.002661111111111111",
      "AK": "3",
      "AL": "0.0012340277777777777",
      "AM": "2",
      "AN": "0.0019091435185185184",
      "AO": "5",
      "AP": "0.001026388888888889",
      "AQ": "5",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "57",
      "B": "8143954",
      "C": "",
      "D": "Moises Teliz",
      "E": "",
      "F": "Smaz remodeling",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.011008680555555555",
      "L": "N",
      "M": "4",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:10.84",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002511574074074074",
      "AG": "5",
      "AH": "0.001678935185185185",
      "AI": "5",
      "AJ": "0.0027114583333333335",
      "AK": "4",
      "AL": "0.0012903935185185186",
      "AM": "4",
      "AN": "0.0018034722222222222",
      "AO": "4",
      "AP": "0.001012847222222222",
      "AQ": "4",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "60",
      "B": "8032643",
      "C": "",
      "D": "Rene De La O",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.011070717592592592",
      "L": "N",
      "M": "5",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:16.20",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002470138888888889",
      "AG": "4",
      "AH": "0.001648148148148148",
      "AI": "4",
      "AJ": "0.002765393518518518",
      "AK": "5",
      "AL": "0.0013967592592592593",
      "AM": "6",
      "AN": "0.0017900462962962963",
      "AO": "3",
      "AP": "0.0010002314814814815",
      "AQ": "2",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "55",
      "B": "8032637",
      "C": "",
      "D": "Izzy Reyes",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.01184513888888889",
      "L": "N",
      "M": "6",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+2:23.11",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0025564814814814816",
      "AG": "6",
      "AH": "0.0018907407407407406",
      "AI": "7",
      "AJ": "0.0030060185185185185",
      "AK": "6",
      "AL": "0.0013298611111111113",
      "AM": "5",
      "AN": "0.0020244212962962963",
      "AO": "7",
      "AP": "0.0010376157407407406",
      "AQ": "6",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "61",
      "B": "8032630",
      "C": "",
      "D": "Lucas Durham",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.012806944444444445",
      "L": "N",
      "M": "7",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+3:46.21",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002951388888888889",
      "AG": "9",
      "AH": "0.0019085648148148145",
      "AI": "8",
      "AJ": "0.0032194444444444445",
      "AK": "7",
      "AL": "0.0014874999999999999",
      "AM": "7",
      "AN": "0.0021226851851851854",
      "AO": "8",
      "AP": "0.001117361111111111",
      "AQ": "8",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "56",
      "B": "8143978",
      "C": "",
      "D": "Efren Alvarado",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "0.012807870370370372",
      "L": "N",
      "M": "8",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+3:46.29",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0028241898148148145",
      "AG": "8",
      "AH": "0.0019539351851851853",
      "AI": "9",
      "AJ": "0.0032729166666666666",
      "AK": "8",
      "AL": "0.001661921296296296",
      "AM": "8",
      "AN": "0.0020243055555555557",
      "AO": "6",
      "AP": "0.0010706018518518519",
      "AQ": "7",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "54",
      "B": "8032648",
      "C": "",
      "D": "Lalo García",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Beginner Open",
      "I": "",
      "J": "",
      "K": "",
      "L": "N",
      "M": "DNF",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0026478009259259263",
      "AG": "7",
      "AH": "0.0017938657407407408",
      "AI": "6",
      "AJ": "",
      "AK": "",
      "AL": "",
      "AM": "",
      "AN": "",
      "AO": "",
      "AP": "",
      "AQ": "",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "69",
      "B": "8032665",
      "C": "",
      "D": "Edgar Rodriguez",
      "E": "",
      "F": "The Shock Lab",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008067592592592593",
      "L": "N",
      "M": "1",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0019386574074074072",
      "AG": "1",
      "AH": "0.0012905092592592593",
      "AI": "2",
      "AJ": "0.0017158564814814814",
      "AK": "2",
      "AL": "0.000922337962962963",
      "AM": "1",
      "AN": "0.001416087962962963",
      "AO": "1",
      "AP": "0.0007841435185185185",
      "AQ": "2",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "67",
      "B": "8032676",
      "C": "",
      "D": "Alfonso Medrano",
      "E": "",
      "F": "Sherpa Team",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008396296296296296",
      "L": "N",
      "M": "2",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+0:28.40",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.001979166666666667",
      "AG": "2",
      "AH": "0.0013200231481481483",
      "AI": "3",
      "AJ": "0.0019097222222222222",
      "AK": "5",
      "AL": "0.0009646990740740741",
      "AM": "3",
      "AN": "0.0014436342592592593",
      "AO": "2",
      "AP": "0.0007790509259259258",
      "AQ": "1",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "68",
      "B": "8143965",
      "C": "",
      "D": "Humberto Arevalo",
      "E": "",
      "F": "The shock lab",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008435416666666666",
      "L": "N",
      "M": "3",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+0:31.78",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.001994212962962963",
      "AG": "4",
      "AH": "0.001349074074074074",
      "AI": "5",
      "AJ": "0.0018287037037037037",
      "AK": "3",
      "AL": "0.0009878472222222222",
      "AM": "4",
      "AN": "0.0014456018518518518",
      "AO": "3",
      "AP": "0.0008299768518518518",
      "AQ": "3",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "62",
      "B": "8032642",
      "C": "",
      "D": "Corey Jones",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008701041666666668",
      "L": "N",
      "M": "4",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+0:54.73",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0020032407407407408",
      "AG": "5",
      "AH": "0.001375115740740741",
      "AI": "6",
      "AJ": "0.0019329861111111112",
      "AK": "6",
      "AL": "0.0009997685185185185",
      "AM": "5",
      "AN": "0.001550347222222222",
      "AO": "5",
      "AP": "0.0008395833333333333",
      "AQ": "6",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "64",
      "B": "8143955",
      "C": "575385",
      "D": "Joel lujan",
      "E": "",
      "F": "North Crew/ The shock lab",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008956018518518518",
      "L": "N",
      "M": "5",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:16.76",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0021180555555555553",
      "AG": "7",
      "AH": "0.0014118055555555557",
      "AI": "8",
      "AJ": "0.0019024305555555556",
      "AK": "4",
      "AL": "0.0010127314814814814",
      "AM": "6",
      "AN": "0.0016721064814814817",
      "AO": "8",
      "AP": "0.0008388888888888889",
      "AQ": "5",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "70",
      "B": "8032674",
      "C": "",
      "D": "Sergio Armando  Acosta Ortega",
      "E": "",
      "F": "Ciclismo Juarez",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.008956944444444444",
      "L": "N",
      "M": "6",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:16.84",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0020982638888888887",
      "AG": "6",
      "AH": "0.0014328703703703706",
      "AI": "9",
      "AJ": "0.0019777777777777775",
      "AK": "8",
      "AL": "0.0010422453703703705",
      "AM": "7",
      "AN": "0.0015406250000000001",
      "AO": "4",
      "AP": "0.0008651620370370371",
      "AQ": "9",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "65",
      "B": "8032612",
      "C": "",
      "D": "Arnold Rodriguez",
      "E": "",
      "F": "Chuco MTB/North Crew",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.009254976851851853",
      "L": "N",
      "M": "7",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:42.59",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002135416666666667",
      "AG": "8",
      "AH": "0.0014479166666666666",
      "AI": "10",
      "AJ": "0.0020642361111111113",
      "AK": "9",
      "AL": "0.0010466435185185184",
      "AM": "8",
      "AN": "0.0017089120370370372",
      "AO": "9",
      "AP": "0.0008518518518518518",
      "AQ": "7",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "77",
      "B": "8032653",
      "C": "",
      "D": "Luis Encerrado",
      "E": "",
      "F": "Handske ",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.00926400462962963",
      "L": "N",
      "M": "8",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:43.37",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0023243055555555556",
      "AG": "11",
      "AH": "0.0014094907407407407",
      "AI": "7",
      "AJ": "0.001972800925925926",
      "AK": "7",
      "AL": "0.001073148148148148",
      "AM": "9",
      "AN": "0.001624189814814815",
      "AO": "6",
      "AP": "0.0008600694444444444",
      "AQ": "8",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "66",
      "B": "8032670",
      "C": "",
      "D": "Eric Sotomayor",
      "E": "",
      "F": "The Shock Lab/ NorthCrew / Merol Racing",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.00936099537037037",
      "L": "N",
      "M": "9",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:51.75",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.001986689814814815",
      "AG": "3",
      "AH": "0.0012814814814814813",
      "AI": "1",
      "AJ": "0.0016790509259259258",
      "AK": "1",
      "AL": "0.0009336805555555555",
      "AM": "2",
      "AN": "0.002643055555555555",
      "AO": "11",
      "AP": "0.0008370370370370371",
      "AQ": "4",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "63",
      "B": "8143966",
      "C": "",
      "D": "Hunt Burdick",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.009721064814814814",
      "L": "N",
      "M": "10",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+2:22.86",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002278009259259259",
      "AG": "10",
      "AH": "0.0014814814814814814",
      "AI": "11",
      "AJ": "0.0021527777777777778",
      "AK": "10",
      "AL": "0.0011697916666666666",
      "AM": "11",
      "AN": "0.00167037037037037",
      "AO": "7",
      "AP": "0.0009686342592592593",
      "AQ": "11",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "88",
      "B": "8143971",
      "C": "",
      "D": "Ricardo Granados ",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "0.009792939814814815",
      "L": "N",
      "M": "11",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+2:29.07",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002217939814814815",
      "AG": "9",
      "AH": "0.001488425925925926",
      "AI": "12",
      "AJ": "0.0022886574074074072",
      "AK": "11",
      "AL": "0.001100462962962963",
      "AM": "10",
      "AN": "0.001751736111111111",
      "AO": "10",
      "AP": "0.0009457175925925925",
      "AQ": "10",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "67",
      "B": "8032659",
      "C": "",
      "D": "Alfonso Medrano",
      "E": "",
      "F": "Sherpa Team",
      "G": "",
      "H": "Men Expert Open",
      "I": "",
      "J": "",
      "K": "",
      "L": "N",
      "M": "",
      "N": "DNF",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "",
      "AG": "",
      "AH": "0.0013200231481481483",
      "AI": "3",
      "AJ": "",
      "AK": "",
      "AL": "",
      "AM": "",
      "AN": "",
      "AO": "",
      "AP": "",
      "AQ": "",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "75",
      "B": "8032619",
      "C": "",
      "D": "Luke Cutburth",
      "E": "",
      "F": "Lost Dog Chain Breakers",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.008803703703703704",
      "L": "N",
      "M": "1",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0020653935185185185",
      "AG": "1",
      "AH": "0.0013697916666666667",
      "AI": "1",
      "AJ": "0.0019515046296296294",
      "AK": "1",
      "AL": "0.0009858796296296297",
      "AM": "1",
      "AN": "0.001575810185185185",
      "AO": "1",
      "AP": "0.000855324074074074",
      "AQ": "1",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "78",
      "B": "8032654",
      "C": "",
      "D": "Tony Sanchez",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.009265046296296297",
      "L": "N",
      "M": "2",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+0:39.86",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0021677083333333336",
      "AG": "2",
      "AH": "0.0014620370370370369",
      "AI": "3",
      "AJ": "0.0020107638888888888",
      "AK": "2",
      "AL": "0.00111875",
      "AM": "4",
      "AN": "0.0016030092592592595",
      "AO": "2",
      "AP": "0.0009027777777777778",
      "AQ": "2",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "90",
      "B": "8032672",
      "C": "",
      "D": "Manny Arrellano",
      "E": "",
      "F": "NorthCrew / Merol Racing ",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.009548842592592593",
      "L": "N",
      "M": "3",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:04.38",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0022651620370370373",
      "AG": "4",
      "AH": "0.0014512731481481484",
      "AI": "2",
      "AJ": "0.0021907407407407405",
      "AK": "4",
      "AL": "0.001048611111111111",
      "AM": "2",
      "AN": "0.0016641203703703703",
      "AO": "5",
      "AP": "0.0009289351851851853",
      "AQ": "6",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "73",
      "B": "8032635",
      "C": "",
      "D": "Patrick New",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.009720486111111112",
      "L": "N",
      "M": "4",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:19.21",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002313078703703704",
      "AG": "7",
      "AH": "0.0015347222222222223",
      "AI": "6",
      "AJ": "0.002129398148148148",
      "AK": "3",
      "AL": "0.0011267361111111111",
      "AM": "6",
      "AN": "0.0016670138888888889",
      "AO": "6",
      "AP": "0.000949537037037037",
      "AQ": "9",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "76",
      "B": "8032652",
      "C": "",
      "D": "Efren Carranza",
      "E": "",
      "F": "The shock lab/NORTHCREW",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.009771412037037037",
      "L": "N",
      "M": "5",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:23.61",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002322222222222222",
      "AG": "8",
      "AH": "0.0015384259259259257",
      "AI": "7",
      "AJ": "0.0022062500000000003",
      "AK": "5",
      "AL": "0.0011082175925925925",
      "AM": "3",
      "AN": "0.0016851851851851852",
      "AO": "7",
      "AP": "0.0009111111111111111",
      "AQ": "3",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "72",
      "B": "8032616",
      "C": "",
      "D": "Julio Reyes",
      "E": "",
      "F": "Mozketeroz",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.00978599537037037",
      "L": "N",
      "M": "6",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:24.87",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0022696759259259263",
      "AG": "5",
      "AH": "0.0015460648148148146",
      "AI": "9",
      "AJ": "0.002290277777777778",
      "AK": "6",
      "AL": "0.0011258101851851852",
      "AM": "5",
      "AN": "0.0016265046296296297",
      "AO": "3",
      "AP": "0.0009276620370370372",
      "AQ": "5",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "87",
      "B": "8143982",
      "C": "",
      "D": "Anthony Pearson",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.00995960648148148",
      "L": "N",
      "M": "7",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:39.87",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002355787037037037",
      "AG": "9",
      "AH": "0.0015099537037037037",
      "AI": "5",
      "AJ": "0.0023358796296296296",
      "AK": "7",
      "AL": "0.0011520833333333333",
      "AM": "7",
      "AN": "0.001692476851851852",
      "AO": "9",
      "AP": "0.0009134259259259259",
      "AQ": "4",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "79",
      "B": "8032657",
      "C": "",
      "D": "Adam Becerra",
      "E": "",
      "F": "Dog Speed",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.010006712962962962",
      "L": "N",
      "M": "8",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:43.94",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002367361111111111",
      "AG": "10",
      "AH": "0.0014996527777777777",
      "AI": "4",
      "AJ": "0.002346064814814815",
      "AK": "8",
      "AL": "0.001193287037037037",
      "AM": "9",
      "AN": "0.001662847222222222",
      "AO": "4",
      "AP": "0.0009375000000000001",
      "AQ": "7",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "74",
      "B": "8143985",
      "C": "",
      "D": "Orlando Mendoza",
      "E": "",
      "F": "SNMTB / The Shock Lab / North Crew",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.010062615740740742",
      "L": "N",
      "M": "9",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:48.77",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002270717592592593",
      "AG": "6",
      "AH": "0.0015385416666666666",
      "AI": "8",
      "AJ": "0.0024342592592592593",
      "AK": "10",
      "AL": "0.0011771990740740742",
      "AM": "8",
      "AN": "0.001690740740740741",
      "AO": "8",
      "AP": "0.0009511574074074074",
      "AQ": "10",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "59",
      "B": "8032632",
      "C": "",
      "D": "Kyle Stevens",
      "E": "",
      "F": "Gnarly B's Bikes",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.010124189814814815",
      "L": "N",
      "M": "10",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+1:54.09",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0022466435185185185",
      "AG": "3",
      "AH": "0.0015688657407407407",
      "AI": "10",
      "AJ": "0.0024221064814814817",
      "AK": "9",
      "AL": "0.0012439814814814815",
      "AM": "11",
      "AN": "0.0016944444444444444",
      "AO": "10",
      "AP": "0.000948148148148148",
      "AQ": "8",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "71",
      "B": "8032666",
      "C": "",
      "D": "Arturo  Garcia",
      "E": "",
      "F": "Ciclismo Juarez",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.011371527777777777",
      "L": "N",
      "M": "11",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+3:41.86",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002510648148148148",
      "AG": "11",
      "AH": "0.001707638888888889",
      "AI": "11",
      "AJ": "0.0029484953703703704",
      "AK": "12",
      "AL": "0.0012369212962962963",
      "AM": "10",
      "AN": "0.001954513888888889",
      "AO": "11",
      "AP": "0.0010133101851851852",
      "AQ": "11",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "58",
      "B": "8032611",
      "C": "",
      "D": "David Benitez",
      "E": "",
      "F": "",
      "G": "",
      "H": "Men Intermediate Open",
      "I": "",
      "J": "",
      "K": "0.013059027777777779",
      "L": "N",
      "M": "12",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+6:07.66",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0028848379629629627",
      "AG": "12",
      "AH": "0.002305787037037037",
      "AI": "12",
      "AJ": "0.002940046296296296",
      "AK": "11",
      "AL": "0.001489699074074074",
      "AM": "12",
      "AN": "0.002289351851851852",
      "AO": "12",
      "AP": "0.0011493055555555555",
      "AQ": "12",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "85",
      "B": "8032662",
      "C": "",
      "D": "Kara Samsel",
      "E": "",
      "F": "Podium Finish",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.010553356481481481",
      "L": "N",
      "M": "1",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.002441435185185185",
      "AG": "1",
      "AH": "0.001617013888888889",
      "AI": "2",
      "AJ": "0.002422800925925926",
      "AK": "1",
      "AL": "0.0012129629629629628",
      "AM": "2",
      "AN": "0.0018373842592592593",
      "AO": "2",
      "AP": "0.0010217592592592594",
      "AQ": "2",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "82",
      "B": "8032615",
      "C": "",
      "D": "Sarah Forrestal",
      "E": "",
      "F": "",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.014391550925925924",
      "L": "N",
      "M": "2",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+5:31.62",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0030908564814814813",
      "AG": "2",
      "AH": "0.0019621527777777775",
      "AI": "3",
      "AJ": "0.0037027777777777775",
      "AK": "4",
      "AL": "0.00160625",
      "AM": "3",
      "AN": "0.002852546296296296",
      "AO": "3",
      "AP": "0.0011769675925925925",
      "AQ": "3",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "83",
      "B": "8032640",
      "C": "",
      "D": "Karla Maciel",
      "E": "",
      "F": "",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.014994328703703702",
      "L": "N",
      "M": "3",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+6:23.70",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.003299537037037037",
      "AG": "4",
      "AH": "0.0021226851851851854",
      "AI": "5",
      "AJ": "0.0034557870370370367",
      "AK": "3",
      "AL": "0.0017721064814814813",
      "AM": "4",
      "AN": "0.0029420138888888894",
      "AO": "5",
      "AP": "0.001402199074074074",
      "AQ": "4",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "84",
      "B": "8143956",
      "C": "",
      "D": "Eliana Jordan",
      "E": "",
      "F": "",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.015564351851851852",
      "L": "N",
      "M": "4",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+7:12.95",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.0032362268518518517",
      "AG": "3",
      "AH": "0.002076388888888889",
      "AI": "4",
      "AJ": "0.004001273148148149",
      "AK": "5",
      "AL": "0.001798726851851852",
      "AM": "5",
      "AN": "0.0029378472222222223",
      "AO": "4",
      "AP": "0.001513888888888889",
      "AQ": "5",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "89",
      "B": "8032610",
      "C": "",
      "D": "Mariana Arras",
      "E": "",
      "F": "Sherpa Team",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.017229398148148146",
      "L": "N",
      "M": "5",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+9:36.81",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.009498148148148148",
      "AG": "6",
      "AH": "0.0014585648148148147",
      "AI": "1",
      "AJ": "0.002508101851851852",
      "AK": "2",
      "AL": "0.001150810185185185",
      "AM": "1",
      "AN": "0.0016732638888888888",
      "AO": "1",
      "AP": "0.0009405092592592593",
      "AQ": "1",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    },
    {
      "A": "81",
      "B": "8143980",
      "C": "",
      "D": "Sophia Garcia",
      "E": "",
      "F": "Smaz remodeling",
      "G": "",
      "H": "Women Open",
      "I": "",
      "J": "",
      "K": "0.02314895833333333",
      "L": "N",
      "M": "6",
      "N": "",
      "O": "",
      "P": "",
      "Q": "",
      "R": "",
      "S": "",
      "T": "",
      "U": "",
      "V": "",
      "W": "",
      "X": "",
      "Y": "",
      "Z": "+18:08.26",
      "AA": "",
      "AB": "",
      "AC": "",
      "AD": "",
      "AE": "",
      "AF": "0.004375462962962963",
      "AG": "5",
      "AH": "0.002480787037037037",
      "AI": "6",
      "AJ": "0.00655787037037037",
      "AK": "6",
      "AL": "0.0032599537037037037",
      "AM": "6",
      "AN": "0.0047350694444444445",
      "AO": "6",
      "AP": "0.0017398148148148147",
      "AQ": "6",
      "AR": "",
      "AS": "",
      "AT": "",
      "AU": "",
      "AV": "",
      "AW": "",
      "AX": ""
    }
   ]

export default function franklin_2022() {
    return (
        <div className='background-race'>
        
        <div className="race">
              
            <div className='container'>
                <h1 className='gold-0'>Franklin mtns 2022</h1>
                <table class="tableizer-table">
            <thead><tr className="tableizer-firstrow"><th>RaceNumber</th><th>Name</th><th>Club</th><th>CourseClass</th><th>RaceTime</th><th>Position</th><th>BehindTime</th><th>Stage 1 - Maze Time</th><th>Stage 1 - Maze Pos</th><th>Stage 2 - Polecat Time</th><th>Stage 2 - Polecat Pos</th><th>Stage 3 - Muleshoe Time</th><th>Stage 3 - Muleshoe Pos</th><th>Stage 4 - Cardiac DH Time</th><th>Stage 4 - Cardiac DH Pos</th><th>Stage 5 - No Bueno Time</th><th>Stage 5 - No Bueno Pos</th><th>Stage 6 - Cardiac Lower  Time</th><th>Stage 6 - Cardiac Lower  Pos</th></tr></thead><tbody>
                <tr><td>51</td><td>Octavio Gonzalez</td><td>Embrace the Suck</td><td>Men 50+</td><td>14:23.8</td><td>1</td><td>&nbsp;</td><td>03:35.0</td><td>2</td><td>02:11.7</td><td>1</td><td>03:18.5</td><td>1</td><td>01:34.9</td><td>1</td><td>02:25.0</td><td>1</td><td>01:18.7</td><td>1</td></tr>
                <tr><td>50</td><td>David Peregrino</td><td>&nbsp;</td><td>Men 50+</td><td>15:37.6</td><td>2</td><td>+1:13.83</td><td>03:26.4</td><td>1</td><td>02:18.4</td><td>2</td><td>03:51.7</td><td>2</td><td>01:49.8</td><td>2</td><td>02:43.5</td><td>2</td><td>01:27.9</td><td>2</td></tr>
                <tr><td>52</td><td>Isaac Chavez</td><td>&nbsp;</td><td>Men Beginner Open</td><td>14:40.3</td><td>1</td><td>&nbsp;</td><td>03:22.1</td><td>1</td><td>02:16.3</td><td>2</td><td>03:35.2</td><td>2</td><td>01:48.8</td><td>3</td><td>02:21.4</td><td>1</td><td>01:16.5</td><td>1</td></tr>
                <tr><td>80</td><td>Max Guerra</td><td>The Shock Lab / North Crew</td><td>Men Beginner Open</td><td>14:43.9</td><td>2</td><td>+0:03.58</td><td>03:23.4</td><td>2</td><td>02:11.0</td><td>1</td><td>03:26.8</td><td>1</td><td>01:42.0</td><td>1</td><td>02:33.5</td><td>2</td><td>01:27.2</td><td>3</td></tr>
                <tr><td>53</td><td>Andre von Twistern</td><td>&nbsp;</td><td>Men Beginner Open</td><td>15:40.7</td><td>3</td><td>+1:00.35</td><td>03:33.2</td><td>3</td><td>02:17.3</td><td>3</td><td>03:49.9</td><td>3</td><td>01:46.6</td><td>2</td><td>02:45.0</td><td>5</td><td>01:28.7</td><td>5</td></tr>
                <tr><td>57</td><td>Moises Teliz</td><td>Smaz remodeling</td><td>Men Beginner Open</td><td>15:51.1</td><td>4</td><td>+1:10.84</td><td>03:37.0</td><td>5</td><td>02:25.1</td><td>5</td><td>03:54.3</td><td>4</td><td>01:51.5</td><td>4</td><td>02:35.8</td><td>4</td><td>01:27.5</td><td>4</td></tr>
                <tr><td>60</td><td>Rene De La O</td><td>&nbsp;</td><td>Men Beginner Open</td><td>15:56.5</td><td>5</td><td>+1:16.20</td><td>03:33.4</td><td>4</td><td>02:22.4</td><td>4</td><td>03:58.9</td><td>5</td><td>02:00.7</td><td>6</td><td>02:34.7</td><td>3</td><td>01:26.4</td><td>2</td></tr>
                <tr><td>55</td><td>Izzy Reyes</td><td>&nbsp;</td><td>Men Beginner Open</td><td>17:03.4</td><td>6</td><td>+2:23.11</td><td>03:40.9</td><td>6</td><td>02:43.4</td><td>7</td><td>04:19.7</td><td>6</td><td>01:54.9</td><td>5</td><td>02:54.9</td><td>7</td><td>01:29.7</td><td>6</td></tr>
                <tr><td>61</td><td>Lucas Durham</td><td>&nbsp;</td><td>Men Beginner Open</td><td>18:26.5</td><td>7</td><td>+3:46.21</td><td>04:15.0</td><td>9</td><td>02:44.9</td><td>8</td><td>04:38.2</td><td>7</td><td>02:08.5</td><td>7</td><td>03:03.4</td><td>8</td><td>01:36.5</td><td>8</td></tr>
                <tr><td>56</td><td>Efren Alvarado</td><td>&nbsp;</td><td>Men Beginner Open</td><td>18:26.6</td><td>8</td><td>+3:46.29</td><td>04:04.0</td><td>8</td><td>02:48.8</td><td>9</td><td>04:42.8</td><td>8</td><td>02:23.6</td><td>8</td><td>02:54.9</td><td>6</td><td>01:32.5</td><td>7</td></tr>
                <tr><td>54</td><td>Lalo García</td><td>&nbsp;</td><td>Men Beginner Open</td><td>&nbsp;</td><td>DNF</td><td>&nbsp;</td><td>03:48.8</td><td>7</td><td>02:35.0</td><td>6</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td>69</td><td>Edgar Rodriguez</td><td>The Shock Lab</td><td>Men Expert Open</td><td>11:37.0</td><td>1</td><td>&nbsp;</td><td>02:47.5</td><td>1</td><td>01:51.5</td><td>2</td><td>02:28.3</td><td>2</td><td>01:19.7</td><td>1</td><td>02:02.4</td><td>1</td><td>01:07.8</td><td>2</td></tr>
                <tr><td>67</td><td>Alfonso Medrano</td><td>Sherpa Team</td><td>Men Expert Open</td><td>12:05.4</td><td>2</td><td>+0:28.40</td><td>02:51.0</td><td>2</td><td>01:54.1</td><td>3</td><td>02:45.0</td><td>5</td><td>01:23.4</td><td>3</td><td>02:04.7</td><td>2</td><td>01:07.3</td><td>1</td></tr>
                <tr><td>68</td><td>Humberto Arevalo</td><td>The shock lab</td><td>Men Expert Open</td><td>12:08.8</td><td>3</td><td>+0:31.78</td><td>02:52.3</td><td>4</td><td>01:56.6</td><td>5</td><td>02:38.0</td><td>3</td><td>01:25.4</td><td>4</td><td>02:04.9</td><td>3</td><td>01:11.7</td><td>3</td></tr>
                <tr><td>62</td><td>Corey Jones</td><td>&nbsp;</td><td>Men Expert Open</td><td>12:31.8</td><td>4</td><td>+0:54.73</td><td>02:53.1</td><td>5</td><td>01:58.8</td><td>6</td><td>02:47.0</td><td>6</td><td>01:26.4</td><td>5</td><td>02:13.9</td><td>5</td><td>01:12.5</td><td>6</td></tr>
                <tr><td>64</td><td>Joel Lujan</td><td>North Crew/ The shock lab</td><td>Men Expert Open</td><td>12:53.8</td><td>5</td><td>+1:16.76</td><td>03:03.0</td><td>7</td><td>02:02.0</td><td>8</td><td>02:44.4</td><td>4</td><td>01:27.5</td><td>6</td><td>02:24.5</td><td>8</td><td>01:12.5</td><td>5</td></tr>
                <tr><td>70</td><td>Sergio Armando  Acosta Ortega</td><td>Ciclismo Juarez</td><td>Men Expert Open</td><td>12:53.9</td><td>6</td><td>+1:16.84</td><td>03:01.3</td><td>6</td><td>02:03.8</td><td>9</td><td>02:50.9</td><td>8</td><td>01:30.1</td><td>7</td><td>02:13.1</td><td>4</td><td>01:14.8</td><td>9</td></tr>
                <tr><td>65</td><td>Arnold Rodriguez</td><td>Chuco MTB/North Crew</td><td>Men Expert Open</td><td>13:19.6</td><td>7</td><td>+1:42.59</td><td>03:04.5</td><td>8</td><td>02:05.1</td><td>10</td><td>02:58.4</td><td>9</td><td>01:30.4</td><td>8</td><td>02:27.7</td><td>9</td><td>01:13.6</td><td>7</td></tr>
                <tr><td>77</td><td>Luis Encerrado</td><td>Handske </td><td>Men Expert Open</td><td>13:20.4</td><td>8</td><td>+1:43.37</td><td>03:20.8</td><td>11</td><td>02:01.8</td><td>7</td><td>02:50.5</td><td>7</td><td>01:32.7</td><td>9</td><td>02:20.3</td><td>6</td><td>01:14.3</td><td>8</td></tr>
                <tr><td>66</td><td>Eric Sotomayor</td><td>The Shock Lab/ NorthCrew / Merol Racing</td><td>Men Expert Open</td><td>13:28.8</td><td>9</td><td>+1:51.75</td><td>02:51.6</td><td>3</td><td>01:50.7</td><td>1</td><td>02:25.1</td><td>1</td><td>01:20.7</td><td>2</td><td>03:48.4</td><td>11</td><td>01:12.3</td><td>4</td></tr>
                <tr><td>63</td><td>Hunt Burdick</td><td>&nbsp;</td><td>Men Expert Open</td><td>13:59.9</td><td>10</td><td>+2:22.86</td><td>03:16.8</td><td>10</td><td>02:08.0</td><td>11</td><td>03:06.0</td><td>10</td><td>01:41.1</td><td>11</td><td>02:24.3</td><td>7</td><td>01:23.7</td><td>11</td></tr>
                <tr><td>88</td><td>Ricardo Granados </td><td>&nbsp;</td><td>Men Expert Open</td><td>14:06.1</td><td>11</td><td>+2:29.07</td><td>03:11.6</td><td>9</td><td>02:08.6</td><td>12</td><td>03:17.7</td><td>11</td><td>01:35.1</td><td>10</td><td>02:31.4</td><td>10</td><td>01:21.7</td><td>10</td></tr>
                <tr><td>75</td><td>Luke Cutburth</td><td>Lost Dog Chain Breakers</td><td>Men Intermediate Open</td><td>12:40.6</td><td>1</td><td>&nbsp;</td><td>02:58.4</td><td>1</td><td>01:58.4</td><td>1</td><td>02:48.6</td><td>1</td><td>01:25.2</td><td>1</td><td>02:16.1</td><td>1</td><td>01:13.9</td><td>1</td></tr>
                <tr><td>78</td><td>Tony Sanchez</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>13:20.5</td><td>2</td><td>+0:39.86</td><td>03:07.3</td><td>2</td><td>02:06.3</td><td>3</td><td>02:53.7</td><td>2</td><td>01:36.7</td><td>4</td><td>02:18.5</td><td>2</td><td>01:18.0</td><td>2</td></tr>
                <tr><td>90</td><td>Manny Arrellano</td><td>NorthCrew / Merol Racing </td><td>Men Intermediate Open</td><td>13:45.0</td><td>3</td><td>+1:04.38</td><td>03:15.7</td><td>4</td><td>02:05.4</td><td>2</td><td>03:09.3</td><td>4</td><td>01:30.6</td><td>2</td><td>02:23.8</td><td>5</td><td>01:20.3</td><td>6</td></tr>
                <tr><td>73</td><td>Patrick New</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>13:59.9</td><td>4</td><td>+1:19.21</td><td>03:19.9</td><td>7</td><td>02:12.6</td><td>6</td><td>03:04.0</td><td>3</td><td>01:37.4</td><td>6</td><td>02:24.0</td><td>6</td><td>01:22.0</td><td>9</td></tr>
                <tr><td>76</td><td>Efren Carranza</td><td>The shock lab/NORTHCREW</td><td>Men Intermediate Open</td><td>14:04.3</td><td>5</td><td>+1:23.61</td><td>03:20.6</td><td>8</td><td>02:12.9</td><td>7</td><td>03:10.6</td><td>5</td><td>01:35.7</td><td>3</td><td>02:25.6</td><td>7</td><td>01:18.7</td><td>3</td></tr>
                <tr><td>72</td><td>Julio Reyes</td><td>Mozketeroz</td><td>Men Intermediate Open</td><td>14:05.5</td><td>6</td><td>+1:24.87</td><td>03:16.1</td><td>5</td><td>02:13.6</td><td>9</td><td>03:17.9</td><td>6</td><td>01:37.3</td><td>5</td><td>02:20.5</td><td>3</td><td>01:20.2</td><td>5</td></tr>
                <tr><td>87</td><td>Anthony Pearson</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>14:20.5</td><td>7</td><td>+1:39.87</td><td>03:23.5</td><td>9</td><td>02:10.5</td><td>5</td><td>03:21.8</td><td>7</td><td>01:39.5</td><td>7</td><td>02:26.2</td><td>9</td><td>01:18.9</td><td>4</td></tr>
                <tr><td>79</td><td>Adam Becerra</td><td>Dog Speed</td><td>Men Intermediate Open</td><td>14:24.6</td><td>8</td><td>+1:43.94</td><td>03:24.5</td><td>10</td><td>02:09.6</td><td>4</td><td>03:22.7</td><td>8</td><td>01:43.1</td><td>9</td><td>02:23.7</td><td>4</td><td>01:21.0</td><td>7</td></tr>
                <tr><td>74</td><td>Orlando Mendoza</td><td>SNMTB / The Shock Lab / North Crew</td><td>Men Intermediate Open</td><td>14:29.4</td><td>9</td><td>+1:48.77</td><td>03:16.2</td><td>6</td><td>02:12.9</td><td>8</td><td>03:30.3</td><td>10</td><td>01:41.7</td><td>8</td><td>02:26.1</td><td>8</td><td>01:22.2</td><td>10</td></tr>
                <tr><td>59</td><td>Kyle Stevens</td><td>Gnarly B's Bikes</td><td>Men Intermediate Open</td><td>14:34.7</td><td>10</td><td>+1:54.09</td><td>03:14.1</td><td>3</td><td>02:15.6</td><td>10</td><td>03:29.3</td><td>9</td><td>01:47.5</td><td>11</td><td>02:26.4</td><td>10</td><td>01:21.9</td><td>8</td></tr>
                <tr><td>71</td><td>Arturo  Garcia</td><td>Ciclismo Juarez</td><td>Men Intermediate Open</td><td>16:22.5</td><td>11</td><td>+3:41.86</td><td>03:36.9</td><td>11</td><td>02:27.5</td><td>11</td><td>04:14.7</td><td>12</td><td>01:46.9</td><td>10</td><td>02:48.9</td><td>11</td><td>01:27.6</td><td>11</td></tr>
                <tr><td>58</td><td>David Benitez</td><td>&nbsp;</td><td>Men Intermediate Open</td><td>18:48.3</td><td>12</td><td>+6:07.66</td><td>04:09.2</td><td>12</td><td>03:19.2</td><td>12</td><td>04:14.0</td><td>11</td><td>02:08.7</td><td>12</td><td>03:17.8</td><td>12</td><td>01:39.3</td><td>12</td></tr>
                <tr><td>85</td><td>Kara Samsel</td><td>Podium Finish</td><td>Women Open</td><td>15:11.8</td><td>1</td><td>&nbsp;</td><td>03:30.9</td><td>1</td><td>02:19.7</td><td>2</td><td>03:29.3</td><td>1</td><td>01:44.8</td><td>2</td><td>02:38.8</td><td>2</td><td>01:28.3</td><td>2</td></tr>
                <tr><td>82</td><td>Sarah Forrestal</td><td>&nbsp;</td><td>Women Open</td><td>20:43.4</td><td>2</td><td>+5:31.62</td><td>04:27.0</td><td>2</td><td>02:49.5</td><td>3</td><td>05:19.9</td><td>4</td><td>02:18.8</td><td>3</td><td>04:06.5</td><td>3</td><td>01:41.7</td><td>3</td></tr>
                <tr><td>83</td><td>Karla Maciel</td><td>&nbsp;</td><td>Women Open</td><td>21:35.5</td><td>3</td><td>+6:23.70</td><td>04:45.1</td><td>4</td><td>03:03.4</td><td>5</td><td>04:58.6</td><td>3</td><td>02:33.1</td><td>4</td><td>04:14.2</td><td>5</td><td>02:01.2</td><td>4</td></tr>
                <tr><td>84</td><td>Eliana Jordan</td><td>&nbsp;</td><td>Women Open</td><td>22:24.8</td><td>4</td><td>+7:12.95</td><td>04:39.6</td><td>3</td><td>02:59.4</td><td>4</td><td>05:45.7</td><td>5</td><td>02:35.4</td><td>5</td><td>04:13.8</td><td>4</td><td>02:10.8</td><td>5</td></tr>
                <tr><td>89</td><td>Mariana Arras</td><td>Sherpa Team</td><td>Women Open</td><td>24:48.6</td><td>5</td><td>+9:36.81</td><td>13:40.6</td><td>6</td><td>02:06.0</td><td>1</td><td>03:36.7</td><td>2</td><td>01:39.4</td><td>1</td><td>02:24.6</td><td>1</td><td>01:21.3</td><td>1</td></tr>
                <tr><td>81</td><td>Sophia Garcia</td><td>Smaz remodeling</td><td>Women Open</td><td>33:20.1</td><td>6</td><td>+18:08.26</td><td>06:18.0</td><td>5</td><td>03:34.3</td><td>6</td><td>09:26.6</td><td>6</td><td>04:41.7</td><td>6</td><td>06:49.1</td><td>6</td><td>02:30.3</td><td>6</td></tr>
        </tbody></table>
            
            <CSVLink className="downloadLink" data={csvData}>Download Results</CSVLink>  
        </div>
        </div>
        </div>
    )
}
